import React from 'react';
import { Box, icons } from 'tl-storybook';
import { classes } from './MerlinStatusRenderer.styles';

export default function MerlinDeliveryStatusRenderer(props:any) {
  const { data, colDef } = props;
  const statuses: {
    [key: string]: {
        icon: typeof icons.PanoramaFishEye | typeof icons.Loop | typeof icons.Error | typeof icons.CheckCircle,
        color: 'disabled' | 'action' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
        displayText?: string
     }
  } = {
    'No Attempt Made': { icon: icons.PanoramaFishEye, color: 'disabled', displayText: 'No Attempt Made' },
    NOT_ATTEMPTED: { icon: icons.PanoramaFishEye, color: 'disabled', displayText: 'No Attempt Made' },
    'In Progress': { icon: icons.Loop, color: 'info', displayText: 'In Progress' },
    DELIVERY_REQUESTED: { icon: icons.Loop, color: 'info', displayText: 'In Progress' },
    Failed: { icon: icons.Error, color: 'error', displayText: 'Failed' },
    DELIVERY_FAILED: { icon: icons.Error, color: 'error', displayText: 'Failed' },
    Success: { icon: icons.CheckCircle, color: 'success', displayText: 'Success' },
    DELIVERED: { icon: icons.CheckCircle, color: 'success', displayText: 'Success' },
  };
  let { merlinDeliveryStatus }: { merlinDeliveryStatus: string } = data.pmam || {};
  if (!merlinDeliveryStatus) {
    merlinDeliveryStatus = 'No Attempt Made';
  }

  const Icon = statuses[merlinDeliveryStatus].icon;
  const color = statuses[merlinDeliveryStatus].color;
  const textToDisplay = statuses[merlinDeliveryStatus].displayText;

  return (
    <Box
      data-testid="merlin-delivery-progress-renderer"
      sx={{
        ...classes.statusWrapper,
        ...(colDef?.context === 'comfortable' ? { height: '100px' } : { height: '52px' }),
      }}
    >
      <Icon color={color} sx={{ ...classes.statusIcon }} />
      {textToDisplay}
    </Box>
  );
}
