export const colors = {
  tealBlue: 'rgba(102, 206, 219, 1)',
  tealBlueBackground: 'rgba(102, 206, 219, 0.18)',
  tealGreen: 'rgba(53, 128, 137, 1)',
  error: 'rgba(239, 21, 64, 1)',
  warning: 'rgba(251, 178, 52, 1)',
  success: 'rgba(6, 172, 62, 1)',
  white: 'rgba(255, 255, 255, 0.9)',
  black: 'rgba(0, 0, 0, 0.9)',
  grayDark: 'rgba(0, 0, 0, 0.53)',
  grayMedium: 'rgba(0, 0, 0, 0.1)',
  grayLight: 'rgba(0, 0, 0, 0.04)',
  grayMediumDark: 'rgba(0, 0, 0, 0.18)',
  grayCaption: 'rgba(25, 25, 25, 1)',
  lavenderLight: 'rgba(64, 0, 149, 0.04)',
  transparent: 'rgba(0, 0, 0, 0)',
  labelBg: 'rgba(246, 246, 247, 1)',
  purple: 'rgba(64, 0, 149, 1)',
};

export const primary = colors.tealBlue;
export const secondary = colors.tealGreen;

export const systemError = colors.error;
export const systemWarning = colors.warning;
export const systemSuccess = colors.success;
export const systemPending = colors.grayDark;

export const shadePrimary = colors.black;
export const shadeSecondary = colors.grayDark;

export const grayDisabled = colors.grayDark;
export const disabled = colors.grayDark;

export const decoPrimary = colors.grayLight;
export const decoSecondary = colors.lavenderLight;
export const decoTertiary = colors.grayMedium;

export const captionPrimary = colors.tealGreen;
export const captionSecondary = colors.black;
export const captionError = colors.error;
export const captionWarning = colors.warning;
export const captionDisabled = colors.grayDark;

export const bgcPrimary = colors.white;
export const bgcSecondary = colors.black;
export const bgcTertiary = colors.tealBlueBackground;

export const tablePrimary = colors.transparent;
export const tableSecondary = colors.grayLight;

export const fontPrimary = colors.black;
export const fontDisabled = disabled;
export const fontIcon = colors.tealGreen;
export const fontCaption = colors.grayCaption;

export const buttonPrimary = colors.black;
export const buttonDisabled = colors.grayDark;
export const buttonPlainHover = colors.grayMediumDark;
