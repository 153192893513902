import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { colors, captionDisabled } from '../../../../../styles/base/colors';

interface LogTabStyles {
  root: CSSObject;
}

export const classes: LogTabStyles = {
  root: {
    backgroundColor: colors.grayLight,
    padding: '25px 16px',
    height: '100%',
    width: 'calc(100% - 32px)',
    overflowY: 'scroll',
    '.comment-add': {
      marginTop: '16px',
      '.MuiInputBase-input': {
        backgroundColor: colors.white,
      },
      '.MuiTextField-root': {
        width: '100%',
      },
      '.Mui-disabled': {
        backgroundColor: colors.grayMedium,
      },
    },
    '.MuiStepper-root, .MuiStepLabel-root, .MuiStepLabel-labelContainer, .MuiStepContent-root, .MuiCollapse-wrapperInner, .MuiGrid-root, .text-stepper-icon': {
      fontFamily: '"Roboto classic", sans-serif !important',
    },
    '.MuiPaper-root': {
      background: 'none',
    },
    '.MuiStepLabel-iconContainer': {
      backgroundColor: colors.grayMedium,
      borderRadius: '50%',
      textAlign: 'center',
      height: '30px',
      width: '30px',
      padding: '0',
      fontSize: '16px',
      '.MuiSvgIcon-root': {
        margin: '3px 0 3px 3px',
      },
      '.text-stepper-icon': {
        marginTop: '6px',
        height: '30px',
        width: '30px',
        textAlign: 'center',
      },
    },
    '.MuiStepLabel-labelContainer': {
      paddingLeft: '16px',
    },
    '.MuiStepLabel-label': {
      fontWeight: 'bold',
      fontSize: '16px',
    },
    '.MuiStepContent-root': {
      paddingLeft: '33px',
    },
    '.MuiCollapse-wrapperInner': {
      fontWeight: 'normal',
      fontSize: '12px',
    },
    '.log-desc': {
      fontSize: '14px',
      overflowWrap: 'break-word',
    },
    '.created-info': {
      marginTop: '16px',
      fontWeight: 'bold',
      color: captionDisabled,
    },
    '.error': {
      color: colors.error,
    },
  },
};
