import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { colors } from '../../../../../styles/base/colors';

interface LeftNavStyles {
  root: CSSObject;
}

export const classes: LeftNavStyles = {
  root: {
    position: 'absolute',
    left: '0',
    top: '56px',
    width: '72px',
    height: 'calc(100vh - 56px)',
    backgroundColor: colors.white,
    boxShadow: '0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2)',
    zIndex: '1400',

    '[data-testid="NbcuSidenav_NavLink"], [data-testid="NbcuSidenav_DisabledItem"]': {
      span: {
        fontSize: '12px !important',
      },
    },

    '.left-link': {
      fontSize: '24px',
      padding: '15px 0',
      borderBottom: '1px solid $gray-medium',
      color: colors.black,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(102, 206, 219, 0.18)',
      },
      '&.disabled': {
        color: 'rgba(0, 0, 0, 0.53)',
        cursor: 'default',
        '&:hover': {
          backgroundColor: colors.white,
        },
      },
      '&.active': {
        backgroundColor: 'rgba(102, 206, 219, 0.18)',
        color: colors.tealGreen,
      },
      '.left-link-icon': {
        padding: '0 26px',
      },
      '.left-link-label': {
        fontSize: '10px',
        textAlign: 'center',
        marginTop: '-1px',
      },
    },
  },
};
