import React, { useEffect, useState } from 'react';
import { mui } from 'tl-storybook';
import LeftDrawerInsert from './LeftDrawerInsert';
import RightDrawerInsert from './RightDrawerInsert';
import { classes } from './DrawerConfigurationContent.style';

interface Props<T = any> {
  items: {[group: string]: T[]};
  generateFields: (items: T[], category: string) => JSX.Element;
}

export default function DrawerConfigurationContent<T>(props: Props<T>) {
  const {
    items,
    generateFields,
  } = props;

  const [selectedOption, updateSelectedOption] = useState<string | null>(null);
  const [categories, updateCategories] = useState<string[]>([]);

  useEffect(() => {
    const curCategories = Object.keys(items).sort();
    updateCategories(curCategories);
    updateSelectedOption(curCategories[0]);
  }, [items]);

  return (
    <mui.Grid container sx={{ ...classes.root }} data-testid="drawer-config-content">
      <LeftDrawerInsert categories={categories} selected={selectedOption} selectOption={updateSelectedOption} />
      <mui.Grid item xs={1} />
      <RightDrawerInsert
        items={items}
        generateFields={generateFields}
        selected={selectedOption}
      />
    </mui.Grid>
  );
}
