import React, { useEffect, useState } from 'react';
import { Box, mui } from 'tl-storybook';
import cloneDeep from 'lodash.clonedeep';
import { useAppSelector } from '../../../../interfaces/hooks';
import { makePropertiesDrawerFields, addAudioTracksToAsset } from '../../../../core/utils-tsx';
import { Asset } from '../../../../interfaces/asset';
import {
  PROPERTIES_REQUIRED_FIELDS_SECTION_ONE,
  PROPERTIES_REQUIRED_FIELDS_SECTION_TWO_LEFT,
  PROPERTIES_REQUIRED_FIELDS_SECTION_TWO_RIGHT,
  PROPERTIES_DESCRIPTIVE_DATA_LEFT,
  PROPERTIES_TECHNICAL_SPECS_LEFT,
  PROPERTIES_TECHNICAL_SPECS_RIGHT,
  PROPERTIES_TECHNICAL_AUDIO_DETAILS,
  PROPERTIES_REFERENCE_IDS_LEFT,
  PROPERTIES_AIRTABLE_LEFT,
  PROPERTIES_ADMINISTRATIVE_LEFT,
  PROPERTIES_DATES,
  PROPERTIES_COMMENTS,
} from '../../../consts/PropertiesForms';

import { classes } from './AssetsPropertiesTab.styles';

interface Props {
  item: Asset;
  pmam?: any;
}

export default function AssetsPropertiesTab(props: Props) {
  const { item, pmam } = props;

  const { pmamAssetLoading } = useAppSelector((state:any) => state.propertiesDrawer);
  const [asset, setAsset] = useState(item);
  const editingMode = false;

  useEffect(() => {
    let selectedItem = cloneDeep(item);

    // If selectedItem.audioDetails is a string then convert it to an array
    if (typeof selectedItem.audioDetails === 'string') {
      selectedItem = addAudioTracksToAsset(selectedItem);
    }
    setAsset(selectedItem);
  }, [item]);

  useEffect(() => {
    if (!pmam) return;
    const selected = cloneDeep(item);
    selected.pmam = { ...item.pmam, ...pmam };
    setAsset(selected);
  }, [pmam]);

  return item ? (
    <Box sx={{ ...classes.root }} className="properties-tab" data-testid="assets-properties-panel-properties-tab">
      {pmamAssetLoading && (
        <Box className="loading-overlay" data-testid="filter-drawer-loading-spinner">
          <mui.CircularProgress />
        </Box>
      )}
      <Box className="bordered-section" data-testid="assets-properties-tab-required-data-header">
        <h3>Required Data</h3>
      </Box>
      <Box className="section" data-testid="assets-properties-tab-required-data-section-one">
        {makePropertiesDrawerFields(PROPERTIES_REQUIRED_FIELDS_SECTION_ONE, asset, !editingMode)}
      </Box>
      <Box className="bordered-section" data-testid="assets-properties-tab-secondary-data-header">
        <h3>Secondary Data</h3>
      </Box>
      <Box className="section" data-testid="assets-properties-tab-required-data-section-two">
        <mui.Grid container>
          <mui.Grid item xs={6}>
            {makePropertiesDrawerFields(PROPERTIES_REQUIRED_FIELDS_SECTION_TWO_LEFT, asset, !editingMode)}
          </mui.Grid>
          <mui.Grid item xs={6}>
            {makePropertiesDrawerFields(PROPERTIES_REQUIRED_FIELDS_SECTION_TWO_RIGHT, asset, !editingMode)}
          </mui.Grid>
        </mui.Grid>
      </Box>
      <Box className="bordered-section" data-testid="assets-properties-tab-descriptive-data-header">
        <h3>Descriptive Data</h3>
      </Box>
      <Box className="section" data-testid="assets-descriptive-specs">
        <mui.Grid container>
          <mui.Grid item xs={6}>
            {makePropertiesDrawerFields(PROPERTIES_DESCRIPTIVE_DATA_LEFT, asset, !editingMode)}
          </mui.Grid>
        </mui.Grid>
      </Box>
      <Box className="bordered-section" data-testid="assets-properties-tab-technical-specs-header">
        <h3>Technical Specs</h3>
      </Box>
      <Box className="section" data-testid="assets-properties-tab-technical-specs">
        <mui.Grid container>
          <mui.Grid item xs={6}>
            {makePropertiesDrawerFields(PROPERTIES_TECHNICAL_SPECS_LEFT, asset, !editingMode)}
          </mui.Grid>
          <mui.Grid item xs={6}>
            {makePropertiesDrawerFields(PROPERTIES_TECHNICAL_SPECS_RIGHT, asset, !editingMode)}
          </mui.Grid>
          {makePropertiesDrawerFields(PROPERTIES_TECHNICAL_AUDIO_DETAILS, asset, !editingMode, 'properties')}
        </mui.Grid>
      </Box>
      <Box className="bordered-section" data-testid="assets-properties-tab-reference-ids-header">
        <h3>Reference IDs</h3>
      </Box>
      <Box className="section" data-testid="assets-reference-ids">
        <mui.Grid container>
          <mui.Grid item xs={6}>
            {makePropertiesDrawerFields(PROPERTIES_REFERENCE_IDS_LEFT, asset, !editingMode)}
          </mui.Grid>
        </mui.Grid>
      </Box>

      <Box className="bordered-section" data-testid="assets-properties-tab-airtable-header">
        <h3>Airtable</h3>
      </Box>
      <Box className="section" data-testid="assets-airtable">
        <mui.Grid container>
          <mui.Grid item xs={6}>
            {makePropertiesDrawerFields(PROPERTIES_AIRTABLE_LEFT, asset, !editingMode)}
          </mui.Grid>
        </mui.Grid>
      </Box>

      <Box className="bordered-section" data-testid="assets-properties-tab-administrative-header">
        <h3>Administrative </h3>
      </Box>
      <Box className="section" data-testid="assets-administrative">
        <mui.Grid container>
          <mui.Grid item xs={6}>
            {makePropertiesDrawerFields(PROPERTIES_ADMINISTRATIVE_LEFT, asset, !editingMode)}
          </mui.Grid>
        </mui.Grid>
      </Box>

      <Box className="bordered-section" data-testid="assets-properties-tab-dates-header">
        <h3>Dates </h3>
      </Box>
      <Box className="section" data-testid="assets-dates">
        <mui.Grid container>
          <mui.Grid item xs={6}>
            {makePropertiesDrawerFields(PROPERTIES_DATES, asset, !editingMode)}
          </mui.Grid>
        </mui.Grid>
      </Box>

      <Box className="bordered-section" data-testid="assets-properties-tab-comments-header">
        <h3>Comments</h3>
      </Box>
      <Box className="section" data-testid="assets-comments">
        <mui.Grid container>
          <mui.Grid item xs={6}>
            {makePropertiesDrawerFields(PROPERTIES_COMMENTS, asset, !editingMode)}
          </mui.Grid>
        </mui.Grid>
      </Box>

    </Box>
  ) : null;
}
