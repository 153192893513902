import React from 'react';
import { mui, icons } from 'tl-storybook';

import { classes } from './ErrorIconAvatar.styles';

interface Props {
  message: string;
  icon: icons.SvgIconComponent;
}

export default function ErrorIconAvatar(props: Props) {
  const { message, icon } = props;

  return (
    <mui.Avatar sx={{ ...classes.root }} data-testid="error-icon-badge">
      <mui.SvgIcon className="error-icon-svg" component={icon} />
      {message}
    </mui.Avatar>
  );
}
