import { createSlice } from '@reduxjs/toolkit';

const PreviewSlice = createSlice({
  name: 'preview',
  initialState: {
    previewOpen: false,
    selectedPreview: undefined,
    isPreRelease: null,
    watermarkedUrl: null,
  },
  reducers: {
    updatePreviewOpen(state, action) {
      state.previewOpen = action.payload;
    },
    updateSelectedPreview(state, action) {
      state.selectedPreview = action.payload;
    },
    setIsPreRelease(state, action) {
      state.isPreRelease = action.payload;
    },
    setWatermarkedUrl(state, action) {
      state.watermarkedUrl = action.payload;
    },
  },
});

const { actions, reducer } = PreviewSlice;

export const {
  updatePreviewOpen,
  updateSelectedPreview,
  setIsPreRelease,
  setWatermarkedUrl,
} = actions;

export default reducer;
