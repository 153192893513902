import {
  CSSObject,
  // constants,
} from 'tl-storybook';

interface CategorySelectorButtonsStyles {
  root: CSSObject;
}

export const classes: CategorySelectorButtonsStyles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    '.MuiButton-root': {
      fontSize: '12px',
    },
  },
};
