import { mui } from 'tl-storybook';

const theme = mui.createTheme({
  components: {
    // Name of the component
    MuiPopover: {
      styleOverrides: {
        root: {
          '&.collections-brands-select': {
            zIndex: 20000,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // note: These various styles are used in the BulkEditMetaData form,
          // they hide the "various" option in the menu dropdowns.
          '&[data-value=VARIOUS]': {
            visibility: 'collapse',
            height: '0',
            padding: '0',
          },
          '&[data-value="-- Various --"]': {
            visibility: 'collapse',
            height: '0',
            padding: '0',
          },
        },
      },
    },
  },
});

export default theme;
