import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../../store';
import { API_ENDPOINTS } from '../consts/API_ENDPOINTS';

const AppUserSlice = createSlice({
  name: 'options',
  initialState: {
    networks: null,
    versionTypeOptions: [],
  },
  reducers: {
    setNetworks(state, action) {
      state.networks = action.payload;
    },
    setVersionTypeOptions(state, action) {
      state.versionTypeOptions = action.payload;
    },
  },
});

const { actions, reducer } = AppUserSlice;

export const {
  setNetworks,
  setVersionTypeOptions,
} = actions;

export const getNetworks = (() => (dispatch: AppDispatch) => axios.get(`${API_ENDPOINTS.NETWORKS}`).then((res) => {
  dispatch(setNetworks(res.data));
}).catch((err) => {
  console.log(err);
}));

export const getVersionTypeOptions = () => (dispatch: AppDispatch) => axios.get(API_ENDPOINTS.VERSION_TYPES)
  .then((res) => {
    dispatch(setVersionTypeOptions(res.data));
  }).catch((err) => {
    console.log(err);
  });

export default reducer;
