import {
  CSSObject,
  // constants,
} from 'tl-storybook';

interface FilterIconStyles {
  root: CSSObject;
}

export const classes: FilterIconStyles = {
  root: {
    '.MuiButton-root': {
      borderRadius: '50%',
      padding: '6px',
      minWidth: '0',
    },
  },
};
