import React from 'react';
import { icons } from 'tl-storybook';
import { TableButtonProps } from '../../interfaces/table-button';

// All functions are placeholders
export const STUDIO_SUPPLY_ORDERS_BUTTONS: TableButtonProps[] = [
  {
    text: 'retry',
    icon: <icons.Redo />,
    isDisabledFunction: (user: any = null, selectedOrders = []) => selectedOrders.every((o: any) => o.frOrderStatus !== 'ERRORED') || selectedOrders.length === 0 || !user,
    getTooltipTitle: (user: any = null, selectedOrders = []) => {
      if (!user) return 'Not authorized';
      let title = '';
      const noneErroredItems = selectedOrders.every((o: any) => o.frOrderStatus !== 'ERRORED');
      if (selectedOrders.length && noneErroredItems) {
        title = 'Only content in ERRORED state can be retried';
      }
      return title;
    },
  },
];
