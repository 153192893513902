import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../../interfaces/hooks';
import { AppDispatch } from '../../../../../store';
import { getNetworks, getVersionTypeOptions } from '../../../../slices/OptionsSlice';

const AppOptions = ():any => {
  const dispatch: AppDispatch = useAppDispatch();

  const location = useLocation();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!location.pathname.includes('login') && !loaded) {
      setLoaded(true);
      dispatch(getNetworks());
      dispatch(getVersionTypeOptions());
    }
  }, [location.pathname]);

  return null;
};

export default AppOptions;
