import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { createSlice } from '@reduxjs/toolkit';
import { PmamAsset, GtmLevel } from '../../../interfaces/pmam-asset';
import { AppDispatch } from '../../../store';
import { API_ENDPOINTS } from '../../../core/consts/API_ENDPOINTS';

interface ErrorType {
  airtableId: any;
  fileName: string;
  mediaType: string;
  materialType: string;
  productionType: string;
  gtmId: string;
  gtmUnifiedVersionId: string;
  gtmMdvUnifiedId: string;
  linkTo: string;
  featureTitle: string;
  episodeTitle: string;
  seriesTitle: string;
  seasonNo: string;
  linkedToBrand: string;
  shootDate: string;
  compassTitleNumber: string;
  gtmVersionUnifiedId: string;
  episodeNo: string;
}

interface RequiredFieldType {
  registerMetadata: string | null;
  editMetaData: string | null;
  bulkEditMetaData: string | null;
}

const EditAssetSlice = createSlice({
  name: 'edit-asset',
  initialState: {
    formType: 'editMetaData',
    initialFormState: null,
    formHasBeenSaved: false,
    formState: null,
    updatedFormState: null,
    errors: <ErrorType> {
      airtableId: '',
      fileName: '',
      mediaType: '',
      materialType: '',
      productionType: '',
      gtmId: '',
      gtmUnifiedVersionId: '',
      gtmMdvUnifiedId: '',
      linkTo: '',
      featureTitle: '',
      episodeTitle: '',
      seriesTitle: '',
      seasonNo: '',
      linkedToBrand: '',
      shootDate: '',
      compassTitleNumber: '',
      gtmVersionUnifiedId: '',
      episodeNo: '',
    },
    requiredFields: {
      current: ['fileName', 'mediaType', 'materialType', 'productionType', 'linkTo'],
      registerMetadata: ['fileName', 'mediaType', 'materialType', 'productionType', 'linkTo'],
      editMetaData: ['fileName', 'mediaType', 'materialType', 'productionType', 'linkTo'],
      bulkEditMetaData: ['fileName', 'mediaType', 'materialType', 'productionType', 'linkTo'],
      feature: ['featureTitle'],
      episode: ['seriesTitle', 'episodeTitle', 'episodeNo'],
      series: ['seriesTitle'],
      season: ['seriesTitle', 'seasonNo'],
      brand: ['linkedToBrand'],
      // TODO: make sure various[] is being used?
      various: [],
    },
    savedAssets: [],
  },
  reducers: {
    setInitialFormState(state, action) {
      state.initialFormState = action.payload;
    },
    setFormState(state, action) {
      state.formState = action.payload;
    },
    setFormHasBeenSaved(state, action) {
      state.formHasBeenSaved = action.payload;
    },
    setFormType(state, action) {
      state.formType = action.payload;
    },
    clearFormState(state) {
      state.initialFormState = null;
      state.formState = null;
      state.formHasBeenSaved = false;
      state.updatedFormState = null;
      state.errors = {
        airtableId: '',
        fileName: '',
        mediaType: '',
        materialType: '',
        productionType: '',
        gtmId: '',
        gtmUnifiedVersionId: '',
        gtmMdvUnifiedId: '',
        linkTo: '',
        featureTitle: '',
        episodeTitle: '',
        seriesTitle: '',
        seasonNo: '',
        linkedToBrand: '',
        shootDate: '',
        compassTitleNumber: '',
        gtmVersionUnifiedId: '',
        episodeNo: '',
      };
    },
    updateFormState(state, action) {
      state.updatedFormState = { ...state.updatedFormState, ...action.payload };
    },
    setRequiredFields(state, action) {
      const linkToRequired = {
        linkTo: '',
        gtmId: '',
        featureTitle: '',
        episodeTitle: '',
        episodeNo: '',
        seriesTitle: '',
        seasonNo: '',
        linkedToBrand: '',
      };
      state.errors = { ...state.errors, ...linkToRequired };
      const requiredFields = [
        ...state.requiredFields[state.formType as keyof RequiredFieldType],
        ...(!!action.payload && state.requiredFields[action.payload as keyof RequiredFieldType]),
      ];
      state.requiredFields.current = requiredFields;
    },
    setError(state, action) {
      state.errors = { ...state.errors, ...action.payload };
    },
    setSavedAssets(state, action) {
      state.savedAssets = [...action.payload];
    },
  },
});

const { actions, reducer } = EditAssetSlice;

export const {
  setInitialFormState,
  setFormState,
  setFormHasBeenSaved,
  setFormType,
  clearFormState,
  updateFormState,
  setRequiredFields,
  setError,
  setSavedAssets,
} = actions;

const getPmamAssetRequest = (id: string) => axios.get<PmamAsset>(`${API_ENDPOINTS.PMAM_ASSET}/${id}`).then((resp: any) => {
  const data = cloneDeep(resp.data);
  switch (true) {
    case data?.gtm?.level.includes('feature'):
      data.linkTo = GtmLevel.FEATURE;
      data.featureTitle = data.gtm.featureTitle;
      data.linkedToBrand = null;
      break;
    case data?.gtm?.level.includes('episode'):
      data.linkTo = GtmLevel.EPISODE;
      data.linkedToBrand = null;
      /**
       * episodeTitle and seriesTitle are already present on the DTO
       * There is no need to add them
       */
      data.episodeNo = data.gtm.episodeNo;
      break;
    case data?.gtm?.level.includes('series'):
      data.linkTo = GtmLevel.SERIES;
      data.linkedToBrand = null;
      /**
       * seriesTitle is already present on the DTO
       */
      break;
    case data?.gtm?.level.includes('season'):
      data.linkTo = GtmLevel.SEASON;
      data.linkedToBrand = null;
      /**
       * seriesTitle and seasonNo are already present on the DTO
       */
      break;
    case !!data.linkedToBrand:
      data.linkTo = 'brand';
      break;
    default:
      // do nothing
  }
  return data;
});

export const getPmamAssetById = (id: string) => (dispatch: AppDispatch) => axios
  .get<PmamAsset>(`${API_ENDPOINTS.PMAM_ASSET}/${id}`)
  .then((resp: any) => {
    const data = cloneDeep(resp.data);
    const init = cloneDeep(data);
    dispatch(setInitialFormState(init));

    /**
     * The linkTo, featureTitle, episodeNo...
     * keys are not a part of the original DTO
     * they must be added so their data gets loaded!
     * These fields will need to removed on Save...
     *
     * The Data is linked the Assets gtmID and its gtmUnifiedVersionId
     * and doesn't gets saved via these ids
     */
    const feature = data?.gtm?.level?.includes('feature') || data?.gtmLevel?.includes('FEATURE');
    const episode = data?.gtm?.level?.includes('episode') || data?.gtmLevel?.includes('EPISODE');
    const series = data?.gtm?.level?.includes('series') || data?.gtmLevel?.includes('SERIES');
    const season = data?.gtm?.level?.includes('season') || data?.gtmLevel?.includes('SEASON');
    switch (true) {
      case feature:
        data.linkTo = GtmLevel.FEATURE;
        data.featureTitle = data.gtm.featureTitle;
        data.linkedToBrand = null;
        break;
      case episode:
        data.linkTo = GtmLevel.EPISODE;
        data.linkedToBrand = null;
        /**
         * episodeTitle and seriesTitle are already present on the DTO
         * There is no need to add them
         */
        if (data.gtm) {
          data.episodeNo = data.gtm.episodeNo;
        }
        break;
      case series:
        data.linkTo = GtmLevel.SERIES;
        data.linkedToBrand = null;
        /**
         * seriesTitle is already present on the DTO
         */
        break;
      case season:
        data.linkTo = GtmLevel.SEASON;
        data.linkedToBrand = null;
        /**
         * seriesTitle and seasonNo are already present on the DTO
         */
        break;
      case !!data.linkedToBrand:
        data.linkTo = 'brand';
        break;
      default:
        // do nothing
    }

    dispatch(setFormState(data));
    return data;
  }).catch((err) => {
    console.log(err);
  });

export const getPmamAssetsByIds = (ids: string[]) => (dispatch: AppDispatch) => {
  const requests = ids.map((id) => getPmamAssetRequest(id));
  return Promise.all(requests).then((data) => {
    const bulkFormState = cloneDeep(data[0]);
    const keys = [
      // Required Data Form
      { key: 'fileName', type: 'string' },
      { key: 'mediaType', type: 'optionWithCode' },
      { key: 'materialType', type: 'optionWithCode' },
      { key: 'productionType', type: 'optionWithCode' },
      { key: 'assetType', type: 'option' },
      { key: 'contentType', type: 'option' },
      // Link To GTM Form
      { key: 'linkTo', type: 'string' },
      { key: 'gtmLevel', type: 'string' },
      { key: 'gtmId', type: 'number' },
      { key: 'gtm.compassTitleNumber', type: 'string' },
      { key: 'tmsId', type: 'string' },
      { key: 'gtmVersionUnifiedId', type: 'string' },
      { key: 'gtmMdvUnifiedId', type: 'string' },
      { key: 'seriesTitle', type: 'string' },
      { key: 'featureTitle', type: 'string' },
      { key: 'episodeTitle', type: 'string' },
      { key: 'seasonNo', type: 'string' },
      { key: 'episodeNo', type: 'string' },
      { key: 'linkTo', type: 'string' },
      { key: 'linkedToBrand', type: 'optionWithCode' },
      // Secondary Data Form
      { key: 'airtableId', type: 'string' },
      { key: 'textType', type: 'optionWithCode' },
      { key: 'maskType', type: 'optionWithCode' },
      { key: 'aspectRatio', type: 'optionWithCode' },
      { key: 'fileTitle', type: 'string' },
      { key: 'tagged', type: 'string' },
      { key: 'gtm.network', type: 'string' },
      { key: 'language', type: 'optionWithCode' },
      { key: 'transformation', type: 'optionWithCode' },
      { key: 'versionType', type: 'option' },
      { key: 'copyrightOwner', type: 'string' },
      // Descriptive Data Form
      { key: 'gtm.featureSynopsis', type: 'string' },
      { key: 'gtm.episodeSynopsis', type: 'string' },
      { key: 'gtm.seriesSynopsis', type: 'string' },
      { key: 'gtm.seasonSynopsis', type: 'string' },
      { key: 'userDescription', type: 'string' },
      { key: 'actorPeople', type: 'string' },
      { key: 'keywords', type: 'string' },
      // Technical Specs
      { key: 'technicalSpecs.duration', type: 'string' },
      { key: 'technicalSpecs.audioBitRateLong', type: 'string' },
      { key: 'technicalSpecs.audioBitDepth', type: 'string' },
      { key: 'technicalSpecs.size', type: 'string' },
      { key: 'technicalSpecs.audioCodec', type: 'string' },
      { key: 'technicalSpecs.numAudioChannel', type: 'string' },
      { key: 'technicalSpecs.audioLayout', type: 'string' },
      { key: 'technicalSpecs.audioLanguage', type: 'string' },
      { key: 'technicalSpecs.samplingRate', type: 'string' },
      { key: 'technicalSpecs.parentPath', type: 'string' },
      // Reference Ids
      { key: 'mamId', type: 'string' },
      { key: 'houseNumber', type: 'string' },
      { key: 'promoCode', type: 'string' },
      { key: 'oldReferenceIds', type: 'string' },
      { key: 'deliverableNumber', type: 'string' },
      // Administrative form
      { key: 'userNetwork', type: 'optionWithCode' },
      { key: 'team', type: 'string' },
      { key: 'producer', type: 'string' },
      { key: 'physicalStorageLocation', type: 'string' },
      { key: 'storageLocation', type: 'string' },
      { key: 'acquisitionSource', type: 'string' },
      { key: 'createdBy', type: 'string' },
      { key: 'copyright', type: 'string' },
      { key: 'restrictions', type: 'string' },
      { key: 'uploader', type: 'string' },
      { key: 'airtable.status', type: 'string' },
      // Dates form
      { key: 'shootDate', type: 'string' },
      // Comments form
      { key: 'comments', type: 'string' },
      { key: 'freeform', type: 'string' },
    ];
    const objectPathValue = (object: any, path: any) => {
      const pathArray = path.split('.');
      let value = null;
      if (pathArray.length === 1) {
        value = object[pathArray[0]];
      }
      if (pathArray.length === 2) {
        value = object[pathArray[0]]?.[pathArray[1]];
      }
      return value;
    };
    const objectPathAssign = (object: any, path: any, value: any) => {
      const pathArray = path.split('.');
      if (pathArray.length === 1) {
        object[pathArray[0]] = value;
      }
      // if the first key is null...
      if (!object[pathArray[0]]) {
        object[pathArray[0]] = {
          [pathArray[1]]: value,
        };
      }
      if (pathArray.length === 2) {
        object[pathArray[0]][pathArray[1]] = value;
      }
    };
    keys.forEach((key: any) => {
      let firstValue = null;
      if (key.type === 'string') {
        firstValue = objectPathValue(data[0], key.key);
      }
      if (key.type === 'number') {
        firstValue = objectPathValue(data[0], key.key);
      }
      if (key.type === 'optionWithCode' || key.type === 'option') {
        firstValue = objectPathValue(data[0], key.key)?.description || null;
      }
      for (let i = 1; i < data.length; i += 1) {
        if (key.type === 'string' && firstValue !== objectPathValue(data[i], key.key)) {
          objectPathAssign(bulkFormState, key.key, '-- Various --');
        }
        if (key.type === 'number' && firstValue !== objectPathValue(data[i], key.key)) {
          objectPathAssign(bulkFormState, key.key, '-- Various --');
        }
        if (key.type === 'optionWithCode' && firstValue !== (objectPathValue(data[i], key.key)?.description || null)) {
          objectPathAssign(bulkFormState, key.key, { code: 'VARIOUS', description: '-- Various --', id: 1000 });
        }
        if (key.type === 'option' && firstValue !== (objectPathValue(data[i], key.key)?.description || null)) {
          objectPathAssign(bulkFormState, key.key, { description: '-- Various --', id: 1000 });
        }
      }
    });
    dispatch(setFormState(bulkFormState));
  });
};

export const updatePmamAsset = (id: any, body: any) => () => axios
  .put(`${API_ENDPOINTS.UPDATE_PMAM_ASSET}/${id}`, body)
  .then((resp: any) => resp.data).catch((err) => err);

export const createPmamAsset = (body: any) => () => axios
  .post(API_ENDPOINTS.CREATE_PMAM_ASSET, body)
  .then((resp: any) => resp.data).catch((err) => err);

export const bulkUpdatePmamAsset = (body: any) => () => axios
  .patch(API_ENDPOINTS.BULK_UPDATE_PMAM_ASSET, body)
  .then((resp: any) => resp.data).catch((err) => err);

export default reducer;
