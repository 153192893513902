import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { colors } from '../../../../../styles/base/colors';

interface SavedFilterItemStyles {
  root: CSSObject;
}

export const classes: SavedFilterItemStyles = {
  root: {
    borderBottom: `1px solid ${colors.grayMedium}`,
    padding: '10px 0',
    '.saved-filter-title': {
      width: '100%',
      fontSize: '14px',
      paddingTop: '5px',
    },
  },
};
