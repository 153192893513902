import React from 'react';
import { Box, mui, icons } from 'tl-storybook';
import { classes } from './FilterIcon.styles';

interface Props {
  filterClicked: () => void;
  loadingData: boolean;
}

export default function FilterIcon(props: Props) {
  const { filterClicked, loadingData } = props;

  return (
    <Box sx={{ ...classes.root }}>
      <mui.Button
        variant="contained"
        color="primary"
        onClick={() => filterClicked()}
        disabled={loadingData}
        data-automation-id="open-filter-drawer-button"
        data-testid="open-filter-drawer-button"
      >
        <icons.Sort />
      </mui.Button>
    </Box>
  );
}
