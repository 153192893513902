import React from 'react';
import { icons } from 'tl-storybook';
import { TableButtonProps } from '../../interfaces/table-button';

// All functions are placeholders
export const WORK_ORDERS_BUTTONS: TableButtonProps[] = [
  {
    text: 'cancel',
    icon: <icons.RemoveShoppingCart />,
    isDisabledFunction: (user: any = null, selectedOrders = []) => selectedOrders.length === 0 || !user,
    getTooltipTitle: () => '',
  },
];
