import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { mui } from 'tl-storybook';
import AppWrapper from './core/components/app/AppWrapper';
import { setupStore } from './store';

import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-material.min.css';

import theme from '../styles/Theme';

const store = setupStore();

export default function App() {
  return (
    <mui.ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <AppWrapper />
        </BrowserRouter>
      </Provider>
    </mui.ThemeProvider>
  );
}
