import React from 'react';
import { Box, icons } from 'tl-storybook';
import { classes } from './styles';

export default function OrderStatusRenderer(props:any) {
  const statusStyle = { marginLeft: '5px' };
  const { data } = props;

  const getIcon = (order: any) => {
    if (order.frOrderStatus === 'INVENTORY_CHECK_COMPLETED') {
      return <><icons.Check color="success" aria-label="Inventory Check Completed" /><span style={statusStyle}>Inventory Check Completed</span></>;
    }
    if (order.frOrderStatus === 'ARCHIVE_RESTORE_COMPLETED') {
      return <><icons.Check color="success" aria-label="Archive Restore Completed" /><span style={statusStyle}>Archive Restore Completed</span></>;
    }
    if (order.frOrderStatus === 'COMPLETED') {
      return <><icons.Check color="success" aria-label="Completed" /><span style={statusStyle}>Completed</span></>;
    }
    if (order.frOrderStatus === 'ERRORED') {
      return <><icons.GppBad color="error" aria-label="Errored" /><span style={statusStyle}>Errored</span></>;
    }
    if (order.frOrderStatus === 'INVENTORY_CHECK_ERROR') {
      return <><icons.GppBad color="error" aria-label="Inventory Check Error" /><span style={statusStyle}>Inventory Check Error</span></>;
    }
    if (order.frOrderStatus === 'ARCHIVE_RESTORE_ERROR') {
      return <><icons.GppBad color="error" aria-label="Archive Restore Error" /><span style={statusStyle}>Archive Restore Error</span></>;
    }
    if (order.frOrderStatus === 'VALIDATION_ERROR') {
      return <><icons.GppBad color="error" aria-label="Validation Error" /><span style={statusStyle}>Validation Error</span></>;
    }
    if (order.frOrderStatus === 'INSTRUCTION_ERROR') {
      return <><icons.GppBad color="error" aria-label="Instruction Error" /><span style={statusStyle}>Instruction Error</span></>;
    }
    if (order.frOrderStatus === 'TRANSFER_ERROR') {
      return <><icons.GppBad color="error" aria-label="Transfer Error" /><span style={statusStyle}>Transfer Error</span></>;
    }
    if (order.frOrderStatus === 'PACKAGING_ERROR') {
      return <><icons.GppBad color="error" aria-label="Packaging Error" /><span style={statusStyle}>Packaging Error</span></>;
    }
    if (order.frOrderStatus === 'TRANSCODE_ERROR') {
      return <><icons.GppBad color="error" aria-label="Transcode Error" /><span style={statusStyle}>Transcode Error</span></>;
    }
    if (order.frOrderStatus === 'DELIVERY_ERROR') {
      return <><icons.GppBad color="error" aria-label="Delivery Error" /><span style={statusStyle}>Delivery Error</span></>;
    }
    if (order.frOrderStatus === 'IN_PROGRESS') {
      return <><icons.Pending color="primary" aria-label="In Progress" /><span style={statusStyle}>In Progress</span></>;
    }
    if (order.frOrderStatus === 'PENDING') {
      return <><icons.Pending color="primary" aria-label="Pending" /><span style={statusStyle}>Pending</span></>;
    }
    if (order.frOrderStatus === 'UNKNOWN') {
      return <><icons.Pending color="primary" aria-label="Unknown" /><span style={statusStyle}>Unknown</span></>;
    }
    if (order.frOrderStatus === 'INVENTORY_CHECK_IN_PROGRESS') {
      return <><icons.Pending color="primary" aria-label="Inventory Check In Progress" /><span style={statusStyle}>Inventory Check In Progress</span></>;
    }
    if (order.frOrderStatus === 'ARCHIVE_RESTORE_IN_PROGRESS') {
      return <><icons.Pending color="primary" aria-label="Archive Restore In Progress" /><span style={statusStyle}>Archive Restore In Progress</span></>;
    }
    if (order.frOrderStatus === 'WORK_ORDER_IN_PROGRESS') {
      return <><icons.Pending color="primary" aria-label="Work Order In Progress" /><span style={statusStyle}>Work Order In Progress</span></>;
    }
    if (order.frOrderStatus === 'TRANSFERRING') {
      return <><icons.Pending color="primary" aria-label="Transferring" /><span style={statusStyle}>Transferring</span></>;
    }
    if (order.frOrderStatus === 'TRANSCODING') {
      return <><icons.Pending color="primary" aria-label="Transcoding" /><span style={statusStyle}>Transcoding</span></>;
    }
    if (order.frOrderStatus === 'PACKAGING') {
      return <><icons.Pending color="primary" aria-label="Packaging" /><span style={statusStyle}>Packaging</span></>;
    }
    if (order.frOrderStatus === 'DELIVERY_IN_PROGRESS') {
      return <><icons.Pending color="primary" aria-label="Delivery In Progress" /><span style={statusStyle}>Delivery In Progress</span></>;
    }
    if (order.frOrderStatus === 'CANCELLED') {
      return (
        <><icons.Warning color="warning" aria-label="Cancelled" /><span style={statusStyle}>Cancelled</span></>
      );
    }
    if (order.frOrderStatus === 'NOT_NEEDED') {
      return (
        <><icons.Warning color="warning" aria-label="Not Needed" /><span style={statusStyle}>Not Needed</span></>
      );
    }
  };

  return (
    <Box sx={{ ...classes.statusWrapper }}>
      {getIcon(data)}
    </Box>
  );
}
