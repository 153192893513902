import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppGetState } from '../../../store';
import { API_ENDPOINTS } from '../../../core/consts/API_ENDPOINTS';
import { formatTableData, optimisticallyUpdateData } from '../../../core/utils';

const AssetsLandingSlice = createSlice({
  name: 'assetsLanding',
  initialState: {
    tableData: null,
    tableCount: 0,
  },
  reducers: {
    setTableData(state, action) {
      state.tableData = action.payload;
    },
    setTableCount(state, action) {
      state.tableCount = action.payload;
    },
  },
});

const { actions, reducer } = AssetsLandingSlice;

export const {
  setTableData,
  setTableCount,
} = actions;

export const getAssetsTableData = (
  (search:string) => (
    dispatch:AppDispatch,
    getState:AppGetState,
  ) => axios.get(`${API_ENDPOINTS.ASSETS}${search || ''}`)
    .then((res) => {
      const state = getState();

      const updatedData = optimisticallyUpdateData(state.editAsset.savedAssets, res.data.dslSearch.data);
      const formattedData = formatTableData(updatedData);

      dispatch(setTableData(formattedData));
      dispatch(setTableCount(res.data.dslSearch.total));

      return res.data.dslSearch;
    }).catch((err) => console.log(err)));

export default reducer;
