import * as React from 'react';
import { mui } from 'tl-storybook';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmationDialogRaw from './ConfirmationDialogRaw';
import { useAppDispatch, useAppSelector } from '../../../../interfaces/hooks';
import { setMediator8Error } from '../../../slices/UserFeedBackSlice';
import { setVideoJsOptions, setVideoMetaData } from '../../../slices/ProxySlice';
import { updatePreviewOpen, setWatermarkedUrl } from '../../../slices/PreviewSlice';
import { updateItems } from '../../../slices/SelectedSlice';

function DialogMediator8Error() {
  const dispatch: any = useAppDispatch();
  const location = useLocation();

  const mediator8Error = useAppSelector((state) => state.userFeedBack.mediator8Error);
  const { items } = useAppSelector((state) => state.selected);
  const navigate = useNavigate();

  const navigateBackToRoute = () => {
    if (window.history.length > 1) { // More than one item in history
      navigate(-1); // Go back to the previous page
    } else {
      navigate(mediator8Error.reRouteTo); // Navigate to home if there's no previous page
    }
  };

  const retryPlay = () => {
    setTimeout(() => {
      dispatch(updateItems([{ ...items[0] }]));
    }, 1000);
  };

  const handleConfirm = () => {
    // Close the video player.
    dispatch(setVideoJsOptions(null));
    dispatch(setVideoMetaData(null));
    dispatch(updatePreviewOpen(false));
    dispatch(setWatermarkedUrl(null));

    // reset the selected items to the original state
    if (location.pathname.includes('subclip')) {
      mediator8Error.reRouteTo ? navigateBackToRoute() : retryPlay();
    }

    // Reset the Dialog.
    dispatch(setMediator8Error({ open: false, title: '', confirmLabel: '' }));
  };

  return (
    <mui.Box>
      {mediator8Error.open && (
      <ConfirmationDialogRaw
        keepMounted
        open={mediator8Error.open}
        title={mediator8Error.title}
        confirmLabel={mediator8Error.confirmLabel}
        onConfirm={handleConfirm}
      >
        <mui.Box
          dangerouslySetInnerHTML={{ __html: mediator8Error.errorMsgTemplate }}
        />
      </ConfirmationDialogRaw>
      )}
    </mui.Box>
  );
}

export default DialogMediator8Error;
