import moment from 'moment';

export const snakeCase = (str: string) => str.toLowerCase().replace(/ /g, '-');

export const createDataTestId = (strArr: string[]) => strArr.filter((str: string | undefined) => str)
  .reduce((res: string, str: string, index: number) => {
    let result = res;
    result += (index ? '-' : '') + str.toLowerCase();
    return result;
  }, '');

export const formatDuration = (duration: string) => {
  if (!duration) {
    return '00:00:00:00';
  }

  const match = duration.match(/^PT(?<hours>\d+)H(?<minutes>\d+)M(?<seconds>\d+)\.(?<milliseconds>\d+)S$/);
  if (!match) {
    return duration;
  }

  const {
    hours, minutes, seconds, milliseconds,
  } = match.groups;
  return [hours, minutes, seconds, milliseconds]
    .map((part) => parseInt(part, 10).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }))
    .join(':');
};

export function formatBoolean(data: string) {
  let yesNo;
  switch (data) {
    case 'false':
    case '':
      yesNo = 'No';
      break;
    default:
      yesNo = 'Yes';
  }
  return yesNo;
}

const pad = (number: any) => number.toString().padStart(2, '0');

export const formatTime = (time: any) => {
  const duration = moment.duration(time, 'seconds');
  return `${pad(duration.hours())}:${pad(duration.minutes())}:${pad(duration.seconds())}`;
};

export const formatDate = (date: string) => {
  if (!date) return '';
  const options : any = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  };
  return new Date(date).toLocaleDateString('en-US', options);
};

export const formatAssetDamPublished = (asset: any) => {
  // THIS IS FOR WHEN WE FIGURE OUT HOW TO PASS WHOLE ASSET INTO FORMATTER. NOW WE ONLY PASS A STRING
  const isPeacock = asset.userNetwork?.description === 'Peacock';
  let damAssetPublishValue = asset.damAssetPublish;
  if (isPeacock && damAssetPublishValue !== false) {
    damAssetPublishValue = true;
  }
  return damAssetPublishValue;
};

export const getExternalLoginUserPoolData = () => {
  const isDev = window.location.hostname.includes('-dev');
  const isQA = window.location.hostname.includes('-qa') || window.location.hostname.includes('localhost');
  const isStage = window.location.hostname.includes('-stage');

  // TODO: get ids/secrets from environment variable set by secrets manager
  if (isDev) {
    return {
      unpUserPoolId: 'us-east-1_qONRuEiY2',
      unpClientId: '2skus5qp307chh0co86ef2pdbu',
    };
  }
  if (isQA) {
    return {
      unpUserPoolId: 'us-east-1_sny1Yy5On',
      unpClientId: '6m1d87haer2vmun4kml4lpbtgq',
    };
  }
  if (isStage) {
    return {
      unpUserPoolId: 'us-east-1_85dmuHrzo',
      unpClientId: '7t3uvi6cadkmp7vl2g7ifno8tp',
    };
  }
  return {
    unpUserPoolId: 'us-east-1_FjuhtGAmA',
    unpClientId: '1pkos7jlst69lfvkgqsd25tsb8',
  };
};

export const formatMasterSubmaster = (type: any) => {
  let convertedType;
  switch (type) {
    case 'MASTER_TYPE':
      convertedType = 'Master';
      break;
    case 'SUB_MASTER':
      convertedType = 'Submaster';
      break;
    case 'N_A':
      convertedType = 'N/A';
      break;
    default:
        // do nothing;
  }

  return convertedType;
};
