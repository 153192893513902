import axios from 'axios';
import { AppGetState, AppDispatch } from 'src/app/store';
import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';
import { API_ENDPOINTS } from '../../../../core/consts/API_ENDPOINTS';
import { setFormState, updateFormState } from '../EditAssetsSlice';
import { setGtmUnifiedVersionIdOptions, getTitleWithGtmId } from '../RequiredDataForm/LinkToForm/LinkToFormSlice';
import { getTitleVersion } from '../RequiredDataForm/LinkToForm/utils';
import { setLoadingSpinner } from '../../../../core/slices/UserFeedBackSlice';
import { setAlert } from '../../../../core/slices/ErrorSlice';

const SecondaryDataFormSlice = createSlice({
  name: 'secondary-data-form',
  initialState: {
    airtableId: '',
    airtableIds: null,
    masterSubmasterAssets: [],
    gtmVersionName: '',
    gtmVersionType: '',
    gtmVersionNameInput: '',
    gtmVersionTypeInput: '',
    gtmVersionNameOptions: null,
    gtmVersionTypeOptions: null,
    language: '',
    languageOptions: [],
    aspectRatio: '',
    aspectRatioOptions: [],
  },
  reducers: {
    setAirtableId(state, action) {
      state.airtableId = action.payload;
    },
    setAirtableIds(state, action) {
      state.airtableIds = action.payload;
    },
    setMasterSubmasterAssets(state, action) {
      state.masterSubmasterAssets = action.payload;
    },
    setGtmVersionName(state, action) {
      state.gtmVersionName = action.payload;
    },
    setGtmVersionType(state, action) {
      state.gtmVersionType = action.payload;
    },
    setGtmVersionNameInput(state, action) {
      state.gtmVersionNameInput = action.payload;
    },
    setGtmVersionTypeInput(state, action) {
      state.gtmVersionTypeInput = action.payload;
    },
    setGtmVersionNameOptions(state, action) {
      state.gtmVersionNameOptions = action.payload;
    },
    setGtmVersionTypeOptions(state, action) {
      state.gtmVersionTypeOptions = action.payload;
    },
    resetSecondaryDataForm(state) {
      state.airtableId = '';
      state.airtableIds = null;
      state.masterSubmasterAssets = [];
      state.gtmVersionName = '';
      state.gtmVersionType = '';
      state.gtmVersionNameInput = '';
      state.gtmVersionTypeInput = '';
      state.gtmVersionNameOptions = null;
      state.gtmVersionTypeOptions = null;
      state.language = '';
      state.aspectRatio = '';
    },
    setLanguage(state, action) {
      state.language = action.payload;
    },
    setLanguageOptions(state, action) {
      state.languageOptions = action.payload;
    },
    setAspectRatio(state, action) {
      state.aspectRatio = action.payload;
    },
    setAspectRatioOptions(state, action) {
      state.aspectRatioOptions = action.payload;
    },
  },
});

const { actions, reducer } = SecondaryDataFormSlice;

export const {
  setAirtableId,
  setAirtableIds,
  setMasterSubmasterAssets,
  setGtmVersionName,
  setGtmVersionType,
  setGtmVersionNameInput,
  setGtmVersionTypeInput,
  setGtmVersionNameOptions,
  setGtmVersionTypeOptions,
  resetSecondaryDataForm,
  setLanguage,
  setLanguageOptions,
  setAspectRatio,
  setAspectRatioOptions,
} = actions;

export const getAirtableRecord = (params: any) => (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const airtableId = params.airtableIdPattern || '';
  const fileName = params.fileName || '';
  const paired = params.paired || false; // Setting this to false restricts pairing to multiple assets
  return axios.get(`${API_ENDPOINTS.AIRTABLE_RECORDS}?airtableIdPattern=${airtableId}&fileName=${fileName}&paired=${paired}`)
    .then((res) => {
      if (res.data.length === 0) {
        dispatch(setAlert({
          severity: 'error',
          message: 'No airtable records found for this file name.',
        }));
        dispatch(setLoadingSpinner(false));
      }

      switch (state.editAsset.formType) {
        case 'editMetaData': {
          const formState = { ...state.editAsset.formState };
          // If there is more then one airtable record always take the first???
          const airtableRecord = cloneDeep(res.data[0]);
          // formState.airtableStatus and formState.prom must be deleted before asset gets saved
          // These keys are not in the PmamAssetPutRequestBodyInput schema
          formState.airtableStatus = airtableRecord.status;
          formState.prom = { promoCode: airtableRecord.promoCode };
          formState.houseNumber = airtableRecord.houseNumber;
          formState.tagged = !!airtableRecord.tag;
          // The GTM Unified Version ID is called gtmVersionUnifiedId in the putV1Asset request object(PmamAssetPutRequestBodyInput)
          // its also referenced this way in the form
          airtableRecord.gtmVersionUnifiedId = airtableRecord.gtmUnifiedVersionId;
          // The following airtable keys are not on the putV1Asset request object(PmamAssetPutRequestBodyInput)
          // and are not represented in the form
          delete airtableRecord.associatedGtmTitleIds;
          delete airtableRecord.autoPublishToDam;
          delete airtableRecord.campaign;
          delete airtableRecord.campaignId;
          delete airtableRecord.compassId;
          delete airtableRecord.deliverableName;
          delete airtableRecord.dueDate;
          delete airtableRecord.fileName;
          delete airtableRecord.lengthInSeconds;
          delete airtableRecord.gtmUnifiedVersionId;
          delete airtableRecord.houseNumber;
          delete airtableRecord.project;
          delete airtableRecord.promoCode;
          delete airtableRecord.revisionNumber;
          delete airtableRecord.showTitle;
          delete airtableRecord.startAir;
          delete airtableRecord.status;
          delete airtableRecord.tag;
          delete airtableRecord.timeReacted;
          // TODO: there are airtable records that have compassIds
          // but no gtmId, how do we handle this situation???
          // It doesn't look like there is a API in PMAM1.0 that will
          // get gtm data with a compassId alone. The compassId requires
          // knowing the gmtLevel...
          // if airtableRecord has gtmId update the form
          if (airtableRecord.gtmId) {
            getTitleWithGtmId(airtableRecord.gtmId).then((resp) => {
              const gtmLevel = getTitleVersion(resp.data);
              formState.gtm = {};
              formState.gtm.compassTitleNumber = airtableRecord.compassId;
              formState.linkTo = gtmLevel;
              formState.gtmId = airtableRecord.gtmId;
              formState.gtmVersionUnifiedId = airtableRecord.gtmVersionUnifiedId;
              formState.linkedToBrand = null;
              formState.gtmLevel = gtmLevel;
              if (airtableRecord.gtmVersionUnifiedId) {
                airtableRecord.gtmLevel = `${gtmLevel}VERSION`;
              }
              const title = resp.data.titleName;
              const seriesGtmId = resp.data?.seriesTitleID;
              const unifiedVersionIdOptions = resp.data.version;
              dispatch(setGtmUnifiedVersionIdOptions(unifiedVersionIdOptions));
              switch (gtmLevel) {
                case 'FEATURE':
                  formState.featureTitle = title;
                  dispatch(setFormState({ ...formState, ...airtableRecord }));
                  dispatch(updateFormState(airtableRecord));
                  break;
                case 'EPISODE': {
                  const episodeNo = resp.data?.seasonEpisodeNumber || '';
                  formState.episodeTitle = title;
                  formState.episodeNo = episodeNo;
                  const { parentTitleID: seasonId } = resp.data;
                  getTitleWithGtmId(seasonId).then((response) => {
                    const seriesTitleId = response.data.seriesTitleID;
                    formState.seasonNo = response.data.seasonEpisodeNumber;
                    getTitleWithGtmId(seriesTitleId).then((re) => {
                      const seriesTitle = re.data.titleName;
                      formState.seriesTitle = seriesTitle;
                      dispatch(setFormState({ ...formState, ...airtableRecord }));
                      dispatch(updateFormState(airtableRecord));
                    });
                  });
                  break;
                }
                case 'SERIES':
                  formState.seriesTitle = title;
                  dispatch(setFormState({ ...formState, ...airtableRecord }));
                  dispatch(updateFormState(airtableRecord));
                  break;
                case 'SEASON': {
                  const seasonNumber = resp.data?.seasonEpisodeNumber || '';
                  getTitleWithGtmId(seriesGtmId).then((r) => {
                    formState.seriesTitle = r.data.titleName;
                    formState.seasonNo = seasonNumber;
                    dispatch(setFormState({ ...formState, ...airtableRecord }));
                    dispatch(updateFormState(airtableRecord));
                  });
                  break;
                }
                default:
                  // do nothing
              }
              dispatch(setLoadingSpinner(false));
            }).catch((err: any) => err);
            // Make sure non gtm code doesn't run
            // TODO: refactor this code to use an async await function...
            dispatch(setLoadingSpinner(false));
            break;
          }
          // NOTE: Overwriting the Asset userNetwork with the airtable userNetwork.
          // However, this is not what internal is doing, internal is using the assets userNetwork.
          // airtable is the source of truth for now and therefore this is best...
          // Question: Should the airtable userNetwork over write the asset userNetwork?
          // There is another issue with language. The form defaults to English if the asset data
          // doesn't have a language. However, the airtable record will overwrite language even
          // if its language is null...
          formState.gtmId = null;
          formState.gtmLevel = null;
          formState.gtmVersionUnifiedId = null;
          if (airtableRecord.userNetwork) {
            formState.linkTo = 'brand';
            formState.linkedToBrand = airtableRecord.userNetwork;
          }
          // There is a question around whether or not the putV1AssetId can up date the userNetwork?
          // If airtableRecord.userNetwork is not set use the assets linkedToBrand
          dispatch(setFormState({ ...formState, ...airtableRecord }));
          // TODO: check if we really need to update updateFormState here... we really only need to make sure
          // the airtable id is updating... this should be enough to activate the save button... everything else
          // will be updated by the airtable record in the database...
          dispatch(updateFormState(airtableRecord));
          dispatch(setLoadingSpinner(false));
          break;
        }
        case 'bulkEditData': {
          dispatch(setLoadingSpinner(false));
          break;
        }
        default: {
          dispatch(setLoadingSpinner(false));
          // register data
        }
      }
    }).catch((err) => err);
};

export const suggestAirtableRecords = (params: any) => (dispatch: AppDispatch) => {
  const airtableId = params.airtableIdPattern || '';
  const fileName = params.fileName || '';
  const paired = params.paired || false; // Setting this to false restricts pairing to multiple assets
  return axios.get(`${API_ENDPOINTS.AIRTABLE_RECORDS}?airtableIdPattern=${airtableId}&fileName=${fileName}&paired=${paired}`)
    .then((res) => {
      if (res.data.length === 0) {
        dispatch(setAlert({
          severity: 'error',
          message: `No airtable records found for "${airtableId}".`,
        }));
      }
      if (res.data.length > 0) {
        dispatch(setAirtableIds(res.data));
        return res.data;
      }
    }).catch((err) => err);
};

export const getLanguageOptions = () => (dispatch: AppDispatch) => axios.get(API_ENDPOINTS.LANGUAGE_OPTIONS)
  .then((res) => {
    dispatch(setLanguageOptions(res.data));
  }).catch((err) => err);

export const getAspectRatioOptions = () => (dispatch: AppDispatch) => axios.get(API_ENDPOINTS.ASPECT_RATIO_OPTIONS)
  .then((res) => {
    dispatch(setAspectRatioOptions(res.data));
  }).catch((err) => err);

export default reducer;
