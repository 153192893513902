import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import {
  fontDisabled, shadePrimary, bgcPrimary, decoTertiary, colors,
} from '../../../../styles/base/colors';

interface TableButtonsStyles {
  root: CSSObject;
  tableButtons: CSSObject;
  cardViewContainer: CSSObject;
  assetMenuButton: CSSObject;
}

export const classes: TableButtonsStyles | any = {
  root: {
    width: '100%',
    backgroundColor: bgcPrimary,
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: '50px',
    '.ag-cell.ag-cell-auto-height': {
      display: 'flex',
      flexDirection: 'column',
      '.ag-cell-wrapper': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '--ag-odd-row-background-color': 'rgb(0, 0, 0, .025)',
    '.reject-assets-container': {
      position: 'fixed',
      top: '0px',
      left: '0px',
      zIndex: '1300',
      width: '100vw',
      height: '100vh',
      background: colors.grayMedium,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '.reject-assets-card': {
        position: 'relative',
        padding: '10px',
        width: '700px',
        height: '240px',
        label: {
          position: 'absolute',
          '&.unselected': {
            top: '12px',
            left: '12px',
          },
          '&.selected': {
            color: colors.black,
            top: '-7px',
            zIndex: '2',
            backgroundColor: colors.white,
            padding: '0 2px 0 2px',
            left: '12px',
            fontSize: '12px',
          },
        },
        '.close-icon': {
          cursor: 'pointer',
        },
        '.form-input': {
          height: '40px',
          width: '100%',
        },
        '.MuiInputBase-root': {
          height: '40px',
        },
        '.Mui-error': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'red !important',
          },
        },
      },
    },
    '&.table-wrapper-assets': {
      height: 'calc(100vh - 175px)',
    },
    img: {
      width: '100%',
    },
    '.table-top': {
      height: '70px',
      borderBottom: `1px solid ${decoTertiary}`,
    },
    '.paginator': {
      position: 'relative',
      backgroundColor: bgcPrimary,
    },
    '.page-number': {
      border: '0',
      position: 'absolute',
      bottom: '16px',
      right: '32px',
      textAlign: 'center',
      width: '50px',
      margin: '0 !important',
      outline: '0 !important',
    },
    h3: {
      '.select-all-text': {
        fontSize: '14px',
        position: 'relative',
        right: '10px',
      },
    },
    '.MuiTablePagination-actions': {
      width: '110px',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  tableButtons: {
    display: 'flex',
    alignItems: 'center',
    '.MuiButton-outlined': {
      color: shadePrimary,
      borderColor: shadePrimary,
    },
    'span .table-button': {
      textDecoration: 'none',
      svg: {
        marginRight: '5px',
        color: shadePrimary,
      },
    },
    '.MuiButton-root.Mui-disabled, .MuiButton-outlined.Mui-disabled': {
      borderColor: fontDisabled,
      color: fontDisabled,
      svg: {
        color: fontDisabled,
      },
    },
    '.button-menu': {
      padding: '0',
      '&:hover': {
        background: 'none',
      },
    },
  },
  cardViewContainer: {
    position: 'relative',
    height: '100%',
    width: '98%',
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    '&::after': {
      content: '""',
      flex: 'auto',
    },
    '[data-testid="NbcuMediaCard"]': {
      width: '18%',
      minWidth: '250px',
      maxWidth: '650px',
      margin: '12px 0 12px 20px',
      '@media (max-width: 886px)': {
        width: '45%',
      },
      '@media (max-width: 634px)': {
        marginLeft: '0',
        width: '95%',
      },
    },
  },
  assetMenuButton: {
    minWidth: '40px',
    maxWidth: '40px',
    padding: '0',
    left: '-15px',
    backgroundColor: 'transparent',
    '.MuiButton-startIcon': {
      margin: '8px',
    },
    '.MuiTypography-root': {
      margin: 0,
    },
  },
};
