export const getTitleVersion = (data: any) => {
  switch (true) {
    case data.productType === 'Feature':
      return 'FEATURE';
    case data.levelTagDesc === 'Title (Non-Hierarchical)':
      return 'FEATURE';
    case (data.levelTagDesc === 'Episode' && !!data.seasonEpisodeNumber):
      return 'EPISODE';
    case (data.levelTagDesc === 'Season' && !!data.seasonEpisodeNumber):
      return 'SEASON';
    case data.levelTagDesc === 'Series':
      return 'SERIES';
    default:
      return null;
  }
};
