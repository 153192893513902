import React, { useEffect, useState } from 'react';
import { Box, mui } from 'tl-storybook';
import { useLocation } from 'react-router-dom';

import { FilterDrawerValue } from 'src/app/interfaces/drawer-item';
import { LookupResponse, SearchableField } from 'src/app/interfaces/searchable-field';
import { MEDIA_TYPES } from '../../../consts/MEDIA_TYPES';
import { useAppSelector } from '../../../../interfaces/hooks';
import { classes } from './MediaTypeFilter.styles';
import { formatOptions } from '../../../../work-orders/consts/dropdown-options';

const MEDIA_TYPE_VALUES = [
  {
    displayName: 'Video',
    code: 'VIDEO',
    position: 1,
  },
  {
    displayName: 'Audio',
    code: 'AUDIO',
    position: 2,
  },
  {
    displayName: 'Image',
    code: 'IMAGE',
    position: 3,
  },
  {
    displayName: 'Document',
    code: 'DOCUMENT',
    position: 4,
  },
  {
    displayName: 'Project',
    code: 'PROJECT',
    position: 5,
  },
  {
    displayName: 'Voice Over',
    code: 'VOICE_OVER',
    position: 6,
  },
  {
    displayName: 'Promo',
    code: 'PROMO',
    position: 7,
  },
  {
    displayName: 'Footage',
    code: 'FOOTAGE',
    position: 8,
  },
  {
    displayName: 'Closed Caption',
    code: 'CLOSED_CAPTION',
    position: 9,
  },
  {
    displayName: 'Offline Edit',
    code: 'OFFLINE_EDIT',
    position: 10,
  },
  {
    displayName: 'Transcript',
    code: 'TRANSCRIPT',
    position: 11,
  },
  {
    displayName: 'Packages',
    code: 'PACKAGES',
    position: 12,
  },
  {
    displayName: 'Undefined',
    code: 'UNDEFINED',
    position: 13,
  },
  {
    displayName: 'Unknown',
    code: 'UNKNOWN',
    position: 14,
  },
];
interface Props {
changeFilter: (field: SearchableField, value: FilterDrawerValue) => void;
}

export default function MediaTypeFilter(props: Props) {
  const { changeFilter } = props;
  const assetsFilters = useAppSelector((state) => state.filter.assetsFilters);
  const workOrdersFilters = useAppSelector((state) => state.filter.workOrdersFilters);
  const studioSupplyOrdersFilters = useAppSelector((state) => state.filter.studioSupplyOrdersFilters);

  const [mediaTypeSelected, updateMediaType] = useState<string[]>([]);
  const location = useLocation();

  const MEDIA_TYPE_FIELD = {
    name: location.pathname.includes('/work-orders') ? 'asset_mediaType-code' : 'mediaType',
    displayName: 'Media Type',
    type: 'LOOKUP',
    sortable: true,
    group: 'DESCRIPTIVE',
    comparators: ['IS', 'IS_NOT'],
    values: location.pathname.includes('/work-orders') ? formatOptions(MEDIA_TYPE_VALUES) : MEDIA_TYPE_VALUES,
  };

  useEffect(() => {
    let selectedFilters = [];
    switch (location.pathname) {
      case '/work-orders':
        selectedFilters = workOrdersFilters;
        break;
      case '/studio-supply-orders':
        selectedFilters = studioSupplyOrdersFilters;
        break;
      default:
        selectedFilters = assetsFilters;
    }

    const mediaItems = (selectedFilters.find((item: SearchableField) => item.displayName === 'Media Type')
      ?.value as LookupResponse[])?.map((val: LookupResponse) => val.displayName);
    updateMediaType(mediaItems || []);
  }, [assetsFilters, workOrdersFilters, studioSupplyOrdersFilters]);

  const handleSelection = (event: React.MouseEvent<HTMLElement>, selection: string[]) => {
    const mediaObjects: LookupResponse[] = [];
    MEDIA_TYPE_FIELD.values.forEach((val: LookupResponse) => {
      if (selection.includes(val.displayName)) {
        mediaObjects.push(val);
      }
    });
    changeFilter(MEDIA_TYPE_FIELD as SearchableField, mediaObjects);
  };

  return (
    <Box sx={{ ...classes.root }}>
      <mui.ToggleButtonGroup
        value={mediaTypeSelected}
        onChange={handleSelection}
        aria-label="media type selector"
      >
        {MEDIA_TYPES.map((mediaType) => {
          const MediaTypeIcon = mediaType.icon;
          return (
            <mui.ToggleButton
              value={mediaType.displayName}
              aria-label={mediaType.displayName}
              key={`media-type-filter-${mediaType.displayName}`}
              data-automation-id={`media-type-filter-${mediaType.displayName}`}
              data-testid={`media-type-filter-${mediaType.displayName}`}
            >
              <MediaTypeIcon />
            </mui.ToggleButton>
          );
        })}
      </mui.ToggleButtonGroup>
    </Box>
  );
}
