import React from 'react';
import { Box, mui, icons } from 'tl-storybook';
import { SavedFilter } from '../../../../interfaces/saved-filter';
import { useAppDispatch } from '../../../../interfaces/hooks';

import { classes } from './SavedFilterItem.styles';

interface Props {
  rename: (filter: SavedFilter) => void;
  remove: (filter: SavedFilter) => void;
  loadFilter: (filter: SavedFilter) => void;
  filter: SavedFilter;
  children: React.ReactChild;
}

export default function SavedFilterItem(props: Props) {
  const {
    rename, remove, loadFilter, filter, children,
  } = props;

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <mui.Grid container sx={{ ...classes.root }}>
      <mui.Grid item xs={2} className="text-center">
        {
          // Drag handle needs to live out in the parent for drag and drop
        }
        {children}
      </mui.Grid>
      <mui.Grid item xs={8}>
        <Box
          className="saved-filter-title cursor-pointer"
          onClick={() => loadFilter(filter)}
          data-automation-id={`load-saved-filter-${filter.title}`}
          data-testid="saved-filter-title"
        >
          {filter.title}
        </Box>
      </mui.Grid>
      <mui.Grid item xs={2} className="text-center">
        <Box
          className="cursor-pointer"
          aria-controls="filter-menu"
          aria-haspopup="true"
          onClick={handleClick}
          data-testid="filter-menu"
          data-automation-id={`saved-filter-context-menu-${filter.title}`}
        >
          <mui.ClickAwayListener onClickAway={handleClose}>
            <icons.MoreHoriz />
          </mui.ClickAwayListener>
          <mui.Menu id="filter-menu" keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <mui.MenuItem onClick={() => rename(filter)} data-automation-id={`saved-filter-rename-${filter.title}`}>
              Rename
            </mui.MenuItem>
            <mui.MenuItem onClick={() => dispatch(remove(filter) as any)} data-automation-id={`saved-filter-delete-${filter.title}`}>
              Delete
            </mui.MenuItem>
          </mui.Menu>
        </Box>
      </mui.Grid>
    </mui.Grid>
  );
}
