import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { AppRoute } from '../interfaces/route';
import { makeRoute } from '../shared/route-maker';

const routes: AppRoute[] = [
  makeRoute(
    '/work-orders',
    lazy(() => import('./components/WorkOrdersLanding')),
  ),
];

export default routes.map((route) => (
  <Route key={route.path} path={route.path} element={<route.component />} />
));
