import React from 'react';
import { mui } from 'tl-storybook';
import { formatFieldNames } from '../../../utils';

interface Props {
  categories: string[];
  selected: string;
  selectOption: (category: string) => void;
}
export default function LeftDrawerInsert(props: Props) {
  const { categories, selected, selectOption } = props;
  const baseClass = 'category-item_left';
  const activeClass = (index: number, cat: string): string => ((!index && !selected) || selected?.toLowerCase() === cat?.toLowerCase() ? 'active' : '');

  return (
    <mui.Grid item xs={4} className={baseClass}>
      {categories.map((cat: string, index: number) => (
        <button
          type="button"
          key={cat}
          className={`${baseClass}_category roboto-font caption ${activeClass(index, cat)}`}
          style={{ border: 'none', background: 'inherit' }}
          onClick={() => selectOption(cat)}
          data-testid={`left-drawer-category-${cat?.replace(' ', '').toUpperCase()}`}
        >
          {formatFieldNames(cat.toLowerCase())}
        </button>
      ))}
    </mui.Grid>
  );
}
