import React from 'react';
import { icons } from 'tl-storybook';
import { TableButtonProps } from '../../interfaces/table-button';

// All functions are placeholders
export const COLLECTION_DETAILS_BUTTONS: TableButtonProps[] = [
  {
    text: 'order',
    icon: <icons.Add />,
    isDisabledFunction: () => true,
    getTooltipTitle: () => '',
  },
  {
    text: 'edit',
    icon: <icons.Add />,
    isDisabledFunction: () => true,
    getTooltipTitle: () => '',
  },
  {
    text: 'share',
    icon: <icons.Share />,
    isDisabledFunction: () => true,
    getTooltipTitle: () => '',
  },
  {
    text: 'register',
    icon: <icons.Add />,
    isDisabledFunction: () => true,
    getTooltipTitle: () => '',
  },
];
