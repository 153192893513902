import React, { useEffect, useState } from 'react';
import { Box, mui, icons } from 'tl-storybook';
import { useLocation } from 'react-router-dom';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { BUTTON_STATUSES } from '../../../constants/button_statuses';
import { ConfigureDrawerItem, ConfigureItem } from '../../../interfaces/configure-drawer-item';
import { DrawerFieldResponse } from '../../../interfaces/drawer-item';
import { updateSelectedColumns, updateConfigureDrawerOpen } from '../../slices/ConfigureDrawerSlice';
import { formatFieldNames } from '../../utils';
import DrawerTitle from '../app/DrawerTitle';
import CategorySelectorButtons from '../CategorySelectorButtons';
import DragDropColumn from '../DragDropColumn/DragDropColumn';
import DrawerConfigurationContent from '../DrawerConfigurationContent/DrawerConfigurationContent';
import ConfigureSearch from './ConfigureSearch';
import DrawerSection from './DrawerSection';
import { classes } from './ConfigureColumnsDrawer.styles';
import { useAppSelector, useAppDispatch } from '../../../interfaces/hooks';

interface Props {
  pageRef: React.RefObject<any>;
  defaultColumns: ConfigureItem[];
  sortedColumns: DrawerFieldResponse<ConfigureDrawerItem>;
  columnsNumber: number;
}

export default function ConfigureColumnDrawer(props: Props) {
  const dispatch: any = useAppDispatch();
  const {
    pageRef: ref, defaultColumns, sortedColumns, columnsNumber,
  } = props;

  const { selectedColumns, configureDrawerOpen } = useAppSelector((state) => state.configureDrawer);
  const [buttonStatus, updateButtonStatus] = useState<string>(BUTTON_STATUSES.CAT);
  const [localColumns, updateLocalColumns] = useState<ConfigureItem[]>(selectedColumns);
  const [searchColumnsText, updateSearchColumnsText] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    updateLocalColumns([...selectedColumns]);
  }, [selectedColumns]);

  function searchCategories(searchVal: string): void {
    updateSearchColumnsText(searchVal);
  }

  const toggleColumn = (item: ConfigureItem): void => {
    const cols = [...localColumns];
    const index = cols.findIndex((col) => col.key === item.key);
    if (index < 0) {
      cols.push(item);
    } else {
      cols.splice(index, 1);
    }
    updateLocalColumns(cols);
  };

  const closeDrawer = () => {
    updateSearchColumnsText('');
    updateLocalColumns([...selectedColumns]);
    dispatch(updateConfigureDrawerOpen(false));
  };

  const restoreDefault = () => {
    updateLocalColumns(defaultColumns);
  };

  const apply = () => {
    const newColumns = [...localColumns];
    updateSearchColumnsText('');
    dispatch(updateSelectedColumns(newColumns));
    dispatch(updateConfigureDrawerOpen(false));

    switch (location.pathname) {
      case '/work-orders':
        localStorage.workOrderColumns = JSON.stringify(newColumns);
        break;
      case '/studio-supply-orders':
        localStorage.studioSupplyOrderColumns = JSON.stringify(newColumns);
        break;
      default:
        localStorage.assetColumns = JSON.stringify(newColumns);
    }
  };

  const removeColumn = (title: string) => {
    if (localColumns.length === 1) return;

    const newLocalColumns = [...localColumns].filter((column: ConfigureItem) => column.displayName !== title);
    updateLocalColumns(newLocalColumns);
  };

  // Creates children for the DragDrop component
  const procreate = (item: ConfigureItem, dragProps: DraggableProvidedDragHandleProps) => (
    <Box className="displayed-column-item" {...dragProps}>
      <icons.DragHandle />
      <Box className="text-left">
        <Box component="span" className="roboto-font">
          {formatFieldNames(item.displayName)}
        </Box>
      </Box>
      <icons.Close
        className="cursor-pointer"
        onClick={() => {
          removeColumn(item.displayName);
        }}
      />
    </Box>
  );

  const renderDrawerColumns = (configureItem: ConfigureItem[], category: string) => {
    let items = configureItem;
    if (searchColumnsText) {
      // filter to ones that match search text
      const regex = new RegExp(searchColumnsText, 'i');
      items = items.filter((item) => item.displayName.match(regex));
    }

    if (!items.length) {
      // don't show empty categories
      return <div />;
    }

    return (
      <>
        <h3 className="category-item_right_heading">{formatFieldNames(category.toLowerCase())}</h3>
        {items.map((item) => (
          <Box key={item.key} className="category-item_right_list">
            <Box component="span" className="roboto-font">
              {formatFieldNames(item.displayName)}
            </Box>
            <mui.Checkbox
              sx={{
                '&.MuiButtonBase-root': {
                  padding: '9px',
                  '&:hover': {
                    borderRadius: '50%',
                  },
                },
              }}
              onClick={() => toggleColumn(item)}
              checked={localColumns?.some((col) => col.key === item.key)}
              data-testid={`configure-columns-checkbox-${item.key}`}
            />
          </Box>
        )).sort()}
      </>
    );
  };

  return (
    <mui.Drawer
      sx={{ ...classes.root }}
      anchor="right"
      open={configureDrawerOpen}
      ModalProps={{ container: ref.current, style: { position: 'absolute', bottom: 0 } }}
      PaperProps={{ style: { width: '900px' } }}
      BackdropProps={{ style: { position: 'absolute', right: 0 } }}
      onClose={closeDrawer}
    >
      <DrawerTitle title="Customize Layout" toggleDrawer={closeDrawer} />
      <mui.Grid container className="drawer-content">
        <mui.Grid item xs={7}>
          <Box className="left-column">
            <Box component="span" className="roboto-font text-bold">
              Available Columns (
              {columnsNumber}
              )
            </Box>
            <DrawerSection>
              <mui.Grid container className="top-grid">
                <mui.Grid item sm={4}>
                  <CategorySelectorButtons
                    buttonStatus={buttonStatus}
                    onClick={updateButtonStatus}
                  />
                </mui.Grid>
                <mui.Grid item sm={1} />
                <mui.Grid item sm={7}>
                  <ConfigureSearch onChange={(searchVal) => searchCategories(searchVal)} />
                </mui.Grid>
              </mui.Grid>
              <Box className="drawer-content-container">
                <DrawerConfigurationContent
                  items={sortedColumns[buttonStatus]}
                  generateFields={renderDrawerColumns}
                />
              </Box>
            </DrawerSection>
          </Box>
        </mui.Grid>
        <mui.Grid item xs={1}>
          <Box className="middle-column">
            <icons.ArrowBack />
            <icons.ArrowForward />
          </Box>
        </mui.Grid>
        <mui.Grid item xs={4}>
          <Box className="right-column">
            <Box component="span" className="roboto-font text-bold">
              Columns Displayed (
              {localColumns?.length}
              )
            </Box>
            <DrawerSection>
              <Box className="configure-drawer-section">
                <DragDropColumn
                  iterableArray={localColumns}
                  dragEnd={updateLocalColumns}
                  procreate={procreate}
                  itemKey="key"
                />
              </Box>
            </DrawerSection>
          </Box>
        </mui.Grid>
      </mui.Grid>
      <mui.Grid container className="footer-buttons">
        <mui.Grid item xs={6} className="footer-buttons_left">
          <mui.Button
            variant="outlined"
            disableElevation
            onClick={restoreDefault}
            data-testid="configure-columns-restore-default"
            sx={{
              '&.MuiButton-outlinedPrimary': {
                border: '1px solid rgba(0, 0, 0, 0.23);',
                color: 'black',
              },
            }}
          >
            Restore Default
          </mui.Button>
        </mui.Grid>
        <mui.Grid item xs={6} className="footer-buttons_right">
          <mui.Button disableElevation onClick={closeDrawer} data-testid="configure-columns-cancel">
            Cancel
          </mui.Button>
          <mui.Button
            variant="contained"
            disableElevation
            className="apply"
            onClick={apply}
            data-testid="configure-columns-apply"
          >
            Apply
          </mui.Button>
        </mui.Grid>
      </mui.Grid>
    </mui.Drawer>
  );
}
