import {
  CSSObject,
  // constants,
} from 'tl-storybook';

  interface OrderStatusRendererStyles {
    statusWrapper: CSSObject;
  }

export const classes: OrderStatusRendererStyles = {
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
  },
};
