import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppGetState } from '../../../store';
import { API_ENDPOINTS } from '../../../core/consts/API_ENDPOINTS';
import { formatTableData, optimisticallyUpdateData } from '../../../core/utils';

const CollectionMetadataSlice = createSlice({
  name: 'collectionMetadata',
  initialState: {
    collection: null,
    tableData: null,
    tableCount: 0,
  },
  reducers: {
    setCollection(state, action) {
      state.collection = action.payload;
    },
    setCollectionAssetsTableData(state, action) {
      state.tableData = action.payload;
    },
    setCollectionAssetsTableCount(state, action) {
      state.tableCount = action.payload;
    },
  },
});

const { actions, reducer } = CollectionMetadataSlice;

export const {
  setCollection,
  setCollectionAssetsTableData,
  setCollectionAssetsTableCount,
} = actions;

export const getCollectionAssetsTableData = (
  (query:any, collection:any) => (
    dispatch:AppDispatch,
    getState:AppGetState,
  ) => {
    const assetIds = collection.items.map((i:any) => i.key);
    if (assetIds.length === 0) {
      return new Promise((resp) => { resp(() => {}); });
    }
    const postBody:any = { assetIds };
    if (query.sort) {
      postBody.sort = query.sort;
    }
    if (query.page) {
      postBody.page = query.page;
    }
    if (query.limit) {
      postBody.limit = query.limit;
    }

    return axios.post(API_ENDPOINTS.COLLECTION_ASSETS, postBody).then((res) => {
      const state = getState();

      const updatedData = optimisticallyUpdateData(state.editAsset.savedAssets, res.data.dslSearch.data);
      const formattedData = formatTableData(updatedData);

      dispatch(setCollectionAssetsTableData(formattedData));
      dispatch(setCollectionAssetsTableCount(res.data.dslSearch.total));

      return { collection, formattedData };
    }).catch((err) => console.log(err));
  });

export const getCollectionById = (
  (collectionId:any, query:any) => (
    dispatch:AppDispatch,
  ) => axios.post(API_ENDPOINTS.GET_COLLECTION_BY_ID, { id: Number(collectionId) })
    .then((res) => {
      const collection = res.data.collection;
      dispatch(setCollection(collection));
      return dispatch(getCollectionAssetsTableData(query, collection));
    }).catch((err) => console.log(err)));

export default reducer;
