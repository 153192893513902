import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { colors, decoTertiary } from '../../../../../styles/base/colors';

interface ErrorIconAvatarStyles {
  root: CSSObject;
}

export const classes: ErrorIconAvatarStyles = {
  root: {
    height: '300px',
    width: '300px',
    backgroundColor: decoTertiary,
    color: colors.grayDark,
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'column',

    '.error-icon-svg': {
      margin: '10px',
      width: '100px',
      height: '100px',
    },
  },
};
