import { icons } from 'tl-storybook';
// This will be the same across products per Amelia
// Took the codes from the API calls in tl-mam-ui
export const MEDIA_TYPES = [
  {
    icon: icons.LiveTv,
    code: 'VIDEO',
    displayName: 'Video',
  },
  {
    icon: icons.Headset,
    code: 'AUDIO',
    displayName: 'Audio',
  },
  {
    icon: icons.Image,
    code: 'IMAGE',
    displayName: 'Image',
    quickFilter: true,
  },
  {
    icon: icons.Description,
    code: 'DOCUMENT',
    displayName: 'Document',
    quickFilter: true,
  },
  {
    icon: icons.Layers,
    code: 'PROJECT',
    displayName: 'Project',
    quickFilter: true,
  },
];
