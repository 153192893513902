import React from 'react';
import { Link } from 'react-router-dom';
import { Box, NbcuTextField } from 'tl-storybook';
import cloneDeep from 'lodash.clonedeep';
import { getFormFieldByKey } from '../assets/consts/PropertiesForms';
import { DrawerItem } from '../interfaces/drawer-item';
import { FormField } from '../interfaces/formfield';
import { createDataTestId } from './helpers';
import { formatFieldNames, getNestedData } from './utils';

function makePropertiesDrawerFormField(fieldSet: FormField, item: any, disabled: boolean, section?: string) {
  const { label } = fieldSet;
  const keys = fieldSet.key;

  let value = getNestedData(keys, item);
  if (!value || value === 'UNDEFINED') {
    value = (value === false) ? 'false' : '';
  }

  if (label === 'Video Scan Type' && !value) {
    value = 'N/A';
  }

  if (fieldSet.formatter) {
    value = fieldSet.formatter(value);
  }

  if (label === 'Audio Details') {
    return (
      <>
        {!value && value.length === 0 && (
          <Box
            className="audio-details-container"
            data-testid={createDataTestId([section, label])}
          >
            <label className="audio-details-label">Audio Details</label>
            <Box className="audio-details" />
          </Box>
        )}
        {Array.isArray(value) && value.length > 0 && (
          <Box
            className="audio-details-container"
            data-testid={createDataTestId([section, label])}
          >
            <label className="audio-details-label">Audio Details</label>
            <Box className="audio-details">
              {(value as any).map((v:any, i:number) => {
                if (i > 8 && section === 'properties') return;
                if (i === 8 && section === 'properties') {
                  return (
                    <Box key={v} className="audio-track">
                      <Link to={`/assets/detail/${item.id}`}>see more</Link>
                    </Box>
                  );
                }
                if (section === 'properties') {
                  return (
                    <Box key={v} className="audio-track">
                      <Link to={`/assets/detail/${item.id}`}>{v}</Link>
                    </Box>
                  );
                }
                return (
                  <Box key={v} className="audio-track">{v}</Box>
                );
              })}
            </Box>
          </Box>
        )}
      </>
    );
  }

  return (
    <Box className="properties-drawer-form-field" key={label}>
      <NbcuTextField
        data-testid={createDataTestId([section, label])}
        label={formatFieldNames(label)}
        value={formatFieldNames(label) === 'Created Date' ? value.split(' ')[2] : value || ' '}
        disabled={disabled}
        multiline
        sx={{
          backgroundColor: 'transparent',
          '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
            color: 'rgba(0, 0, 0, .87)',
            WebkitTextFillColor: 'rgba(0, 0, 0, .87)',
          },
        }}
      />
    </Box>
  );
}

export function makePropertiesDrawerFields(fieldset: FormField[], item: any, disabled: boolean, section?: string) {
  return fieldset.map((field) => makePropertiesDrawerFormField(field, item, disabled, section));
}

export function makePropertiesDrawerFieldsFromAssetColumn(
  fieldset: DrawerItem[],
  item: any,
  disabled: boolean,
  section?: string,
) {
  return fieldset.map((drawerItem) => {
    const formField = getFormFieldByKey(drawerItem.name) || {
      label: drawerItem.displayName,
      key: drawerItem.name.split('.'),
    };

    return makePropertiesDrawerFormField(formField, item, disabled, section);
  });
}

export function addAudioTracksToAsset(selected:any) {
  const asset = cloneDeep(selected);
  // handle audioDetails string being more than 1000 characters
  let audioDetails;
  if (asset?.audioDetails?.length >= 1000) {
    audioDetails = asset?.audioDetails.split('},');
    audioDetails.pop();
    // Add "}]" to the end of the last element in the array.
    audioDetails[audioDetails.length - 1] = `${audioDetails[audioDetails.length - 1]}}]`;
    // recreate the JSON string with the closing brackets and commas.
    audioDetails = audioDetails.join('},');
  }
  if (audioDetails) {
    audioDetails = JSON.parse(audioDetails || '[]');
  }
  if (!audioDetails) {
    audioDetails = JSON.parse(typeof (asset?.audioDetails) === 'string' ? asset?.audioDetails : '[]');
  }
  audioDetails = audioDetails.map((t:any) => {
    const track = `${t.trackDisplayName} - ${t.language}`;
    return track;
  });
  asset.audioDetails = audioDetails;
  return asset;
}
