import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import { AppGetState, AppDispatch } from 'src/app/store';
import { TimeZones } from '../../consts/TimeZones';
import { API_ENDPOINTS } from '../../../core/consts/API_ENDPOINTS';

enum OrderTargetSystemType {
  MEDIATOR_8,
  MEDIATOR_X,
  PMAM,
}

interface SubClip {
  markIn: string;
  markOut: string;
}

interface stillFrame {
  height: number;
  width: number;
  fileName: string;
  timeCode: string;
}

export interface OrdersBulkArrayInput {
  businessUnit?: string;
  destinationCode: string;
  dueDate: string;
  formatCode: string;
  materialId: string;
  productionNumber?: string;
  targetSystem: OrderTargetSystemType;
  subClipSegments?: SubClip[];
  stillFrames?: stillFrame[];
  comments?: string;
  pending?: boolean;
  restoreType?: string;
  vpostMetadata?: Record<string, string>
}

interface OrderAvailability {
  destinationCode: string;
  materialId?: string;
  targetDate: string;
}

interface FormatParameters {
  destinationCode: string;
  targetSystem: OrderTargetSystemType;
}

interface FederatedRestoreOrder {
    deliverOnDate: string,
    deliverOnTime: string,
    deliverTimezone: string,
    destinationCode: string[],
    dueDate: string,
    formatCode: string,
    materialId: string,
    productionNumber: string,
    targetSystem: {}
  }

const CreateOrderSlice = createSlice({
  name: 'create-order',
  initialState: {
    federatedRestoreOrder: <FederatedRestoreOrder> {},
    federatedRestoreOrders: <FederatedRestoreOrder[]> [],
    errors: <[]> [],
  },
  reducers: {
    setFederatedRestoreOrders(state, action) {
      const assets = action.payload;
      const orders = assets.map((asset: any) => ({
        deliverOnDate: moment().format('YYYY-MM-DD'),
        deliverOnTime: moment().format('HH:mm'),
        deliverTimezone: TimeZones.find((tz) => tz.value === moment.tz?.guess())?.value,
        destinationCode: '',
        dueDate: `${moment().format('YYYY-MM-DDTHH:mm:00')}Z`,
        formatCode: 'XDCAM',
        materialId: asset.materialId,
        productionNumber: asset.productionNumber,
        targetSystem: asset.storageSystem,
      }));
      state.federatedRestoreOrders = orders;
    },
    setProfileIds(state, action) {
      const { assetMatId, value } = action.payload;
      const currentIndex = state.federatedRestoreOrders.findIndex((order) => order.materialId === assetMatId);
      state.federatedRestoreOrders[currentIndex] = {
        ...state.federatedRestoreOrders[currentIndex],
        destinationCode: value,
      };
    },
    setFileFormat(state, action) {
      const { assetMatId, value } = action.payload;
      const currentIndex = state.federatedRestoreOrders.findIndex((order) => order.materialId === assetMatId);
      state.federatedRestoreOrders[currentIndex] = {
        ...state.federatedRestoreOrders[currentIndex],
        formatCode: value,
      };
    },
    setDueDate(state, action) {
      const { assetMatId, id, value } = action.payload;
      const currentIndex = state.federatedRestoreOrders.findIndex((order) => order.materialId === assetMatId);
      state.federatedRestoreOrders[currentIndex] = { ...state.federatedRestoreOrders[currentIndex], [id]: value };
      const currentOrder = state.federatedRestoreOrders[currentIndex];
      const dueDate = `${currentOrder.deliverOnDate} ${currentOrder.deliverOnTime}`;
      if (!currentOrder.deliverTimezone) return;
      currentOrder.dueDate = `${moment(dueDate).tz(currentOrder.deliverTimezone).utc().format('YYYY-MM-DDTHH:mm:00')}Z`;
    },
    setErrors(state, action) {
      state.errors = action.payload;
    },
  },
});

const { actions, reducer } = CreateOrderSlice;

export const {
  setFederatedRestoreOrders,
  setProfileIds,
  setFileFormat,
  setDueDate,
  setErrors,
} = actions;

export const setInitialState = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const { items } = state.selected;
  dispatch(setFederatedRestoreOrders(items));
  dispatch(setErrors([]));
};

const getRequestParams = (url: string, options: any) => {
  let params: string = '?';
  Object.keys(options).forEach((key: string) => {
    params += `${key}=${options[key]}&`;
  });
  return axios.get(`${url}${params}`);
};

export const getOrderAvailability = (options: OrderAvailability) => () => {
  getRequestParams(API_ENDPOINTS.STUDIO_SUPPLY_ORDER_AVAILABILITY, options)
    .then((resp) => {
      console.log(resp);
    // TODO: when an orders Deliver On date is before the returned nextAvailableDate then show error message to user
    }).catch((error) => {
      console.log(error);
    });
};

export const getFormatParameters = (options: FormatParameters) => () => {
  getRequestParams(API_ENDPOINTS.FORMAT_PARAMETERS, options)
    .then((resp) => {
      console.log(resp);
    // TODO: update form with format options...
    }).catch((error) => {
      console.log(error);
    });
};

export const studioSupplyBulkOrder = (orders: OrdersBulkArrayInput[]) => {
  const body = {} as {
    ordersBulkArrayInput: OrdersBulkArrayInput[],
    limit: number,
  };
  body.ordersBulkArrayInput = orders;
  // TODO: do not allow above a logical amount 20? 30? ask backend?
  body.limit = orders.length < 20 ? orders.length : 20;
  const url = API_ENDPOINTS.STUDIO_SUPPLY_BULK_ORDER;
  return axios.post(url, body);
};

export const validateOrderCreation = (body: OrdersBulkArrayInput) => (dispatch: AppDispatch) => {
  const url = API_ENDPOINTS.VALIDATE_ORDER_CREATION;
  axios.post(url, body).then((resp) => {
    console.log(resp);
    // TODO: when there are errors in the resp.data show the error message to the user
    if (resp.data.errors) { dispatch(setErrors(resp.data.errors)); }
  }).catch((error) => {
    console.log(error);
  });
};

export const orderAssets = (federatedRestoreOrders: any) => (dispatch: AppDispatch) => {
  const orderInputs:OrdersBulkArrayInput[] = [];
  federatedRestoreOrders.forEach((order: any) => {
    order.destinationCode.forEach((profile: any) => {
      const orderInput = { ...order };
      orderInput.destinationCode = profile;
      const date = (orderInput.deliverOnDate && orderInput.deliverOnDate.format) ? orderInput.deliverOnDate.format('YYYY-MM-DD')
        : moment(orderInput.deliverOnDate).format('YYYY-MM-DD');
      const time = orderInput.deliverOnTime || moment().format('hh:mm a');
      const zone = orderInput.deliverTimezone || moment.tz.guess();
      let dueDate = moment.tz(`${date} ${time}`, 'YYYY-MM-DD hh:mm a', zone);

      // make sure due date is at least 15 minutes into the future
      dueDate = moment.max(dueDate, moment().add(15, 'minutes'));
      orderInput.dueDate = new Date(dueDate.utc().format()).toISOString();
      delete orderInput.deliverOnDate;
      delete orderInput.deliverOnTime;
      delete orderInput.deliverTimezone;
      orderInputs.push(orderInput);
    });
  });
  orderInputs.forEach((orderInput) => {
    dispatch(validateOrderCreation(orderInput));
  });
  return studioSupplyBulkOrder(orderInputs);
};

export default reducer;
