import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, mui } from 'tl-storybook';
import { SavedFilter } from '../../../../interfaces/saved-filter';
import { useAppSelector, useAppDispatch } from '../../../../interfaces/hooks';
import { setFilterToEdit } from '../../../slices/FilterSlice';

import { classes } from './EditSavedFilterForm.styles';

interface Props {
  saveFilter: (savedFilter: SavedFilter) => void;
}

export default function EditSavedFilterForm(props: Props) {
  const { saveFilter } = props;
  const dispatch = useAppDispatch();
  const workOrdersFilters = useAppSelector((state) => state.filter.workOrdersFilters);
  const studioSupplyOrdersFilters = useAppSelector((state) => state.filter.studioSupplyOrdersFilters);
  const assetsFilters = useAppSelector((state) => state.filter.assetsFilters);
  const filterToEdit = useAppSelector((state) => state.filter.filterToEdit);

  const [filterName, setFilterName] = useState<string>('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/work-orders':
        setSelectedFilters(workOrdersFilters);
        break;
      case '/studio-supply-orders':
        setSelectedFilters(studioSupplyOrdersFilters);
        break;
      default:
        setSelectedFilters(assetsFilters);
    }
  }, [assetsFilters, workOrdersFilters]);

  useEffect(() => {
    const title = filterToEdit?.title || '';
    setFilterName(title);
  }, [filterToEdit]);

  const saveSearch = (asNew = false) => {
    const filter = {
      id: asNew ? null : filterToEdit?.id,
      title: filterName,
      searchCriteria: window.location.search.replace('?', '') || filterToEdit.searchCriteria,
    };
    dispatch(setFilterToEdit(null));
    saveFilter(filter);
  };

  return (
    selectedFilters.length > 0 && (
      <Box sx={{ ...classes.root }} data-testid="save-filter-form">
        <mui.TextField
          id="filter-name"
          className="filter-name-input"
          placeholder="Filter Name"
          variant="outlined"
          inputProps={{ 'data-testid': 'save-filter-form-input' }}
          value={filterName}
          onChange={(event) => setFilterName(event.target.value)}
          sx={{ '.MuiInputBase-input': { padding: '12.5px 14px' } }}
          fullWidth
        />
        <Box className="button-container">
          <mui.ButtonGroup>
            <mui.Button
              className="cancel-button"
              variant="outlined"
              onClick={() => dispatch(setFilterToEdit(null))}
              data-testid="saved-filter-form-cancel-button"
            >
              Cancel
            </mui.Button>
            {
            filterToEdit?.id
            && (
            <mui.Button
              className="save-as-new-button"
              variant="outlined"
              onClick={() => saveSearch(true)}
              data-testid="saved-filter-form-save-as-new-button"
            >
              Save as New
            </mui.Button>
            )
          }
            <mui.Button
              className="save-button"
              onClick={() => saveSearch()}
              variant="outlined"
              color="primary"
              data-testid="saved-filter-form-save-button"
            >
              Save
            </mui.Button>
          </mui.ButtonGroup>
        </Box>
      </Box>
    )
  );
}
