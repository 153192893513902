import React, { useEffect, useState } from 'react';
import {
  Box, mui, icons, NbcuTooltip,
} from 'tl-storybook';
import { Asset } from '../../../interfaces/asset';
import { extensionHasImage, getMediaTypeIcon } from '../../utils';
import { NO_PROXY_AVAILABLE, RESTRICTED_ASSET } from '../../consts/messages';

import { classes } from './MediaThumbnail.styles';

export enum MediaSize {
  ICON = '100x100',
  SMALL = '200x200',
  MEDIUM = '400x400',
  LARGE = '800x800',
  XLARGE = '1400x1400',
  MEGA = '2000x2000',
  NATIVE = ''
}

interface MediaThumbnailProps {
  asset: Asset;
  size: MediaSize
}

export default function MediaThumbnail(props: MediaThumbnailProps) {
  const { asset, size } = props;

  const [mediaType, setMediaType] = useState<string | null>(null);
  const [proxyAvailable, setProxyAvailable] = useState<boolean | null>(null);
  const [restricted, setRestricted] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const generateUrl = (currentAsset: Asset, currentSize = MediaSize.ICON) => {
    if (currentAsset.sourceSystem !== 'PMAM') return;

    const mediaReady = currentAsset.mediatorProgressPercentage > 0;
    if (!mediaReady) {
      // thumbnail not ready
      return '';
    }

    const media = currentAsset.mediaType.toUpperCase();
    const isVideo = media === 'VIDEO';
    const isImage = media === 'IMAGE' || extensionHasImage(currentAsset);
    if (!isVideo && !isImage) {
      // don't care about non video or images for thumbnails
      return '';
    }

    const pmamObj = currentAsset.pmam;
    // NOTE: I don't Think this check is necessary but, keeping this
    // code until we see that it doesn't effect anything in Prod...
    // const keyframeReady = pmamObj?.keyframeReady;
    // if (isVideo && !keyframeReady) {
    //   // thumbnail not ready for video
    //   return '';
    // }

    let imageSize: string = currentSize;
    const useNative = isVideo || currentSize === MediaSize.NATIVE;

    if (useNative && pmamObj.displayWidth && pmamObj.displayHeight) {
      imageSize = `${pmamObj.displayWidth}x${pmamObj.displayHeight}`;
    }

    return `/API/image-proxy/${currentAsset.materialId}/${currentAsset.materialId}_${imageSize}.jpeg`;
  };

  useEffect(() => {
    setMediaType(asset.mediaType);
    setProxyAvailable(asset.proxyAvailable);
    setRestricted(asset.pmam?.restrictedStatus === 'RESTRICTED');
    const currentUrl = generateUrl(asset, size);
    setUrl(currentUrl);
  }, [asset, size]);

  return restricted ? (
    <Box sx={{ ...classes.root }} data-testid="media-thumbnail">
      <NbcuTooltip
        title={RESTRICTED_ASSET}
        placement="top"
      >
        <icons.DoNotDisturb
          sx={{
            ...classes.restricted,
          }}
        />
      </NbcuTooltip>
    </Box>
  ) : (
    <Box sx={{ ...classes.root }} data-testid="media-thumbnail">
      { url && (
      <>
        <img className="proxy-image" alt="thumbnail" src={url} />
        {mediaType === 'VIDEO' && (
        <NbcuTooltip
          title={(proxyAvailable === false && NO_PROXY_AVAILABLE) || ''}
          placement="top"
        >
          <icons.PlayCircleFilled
            sx={{
              ...classes.playButton,
              ...(proxyAvailable === false && classes.disabled),
            }}
          />
        </NbcuTooltip>
        )}
      </>
      )}
      {!url && (
      <NbcuTooltip
        title={(mediaType === 'VIDEO' && proxyAvailable === false && NO_PROXY_AVAILABLE) || ''}
        placement="top"
      >
        <mui.SvgIcon
          sx={{ ...(mediaType === 'VIDEO' && proxyAvailable === false && classes.disabled) }}
          component={getMediaTypeIcon(mediaType)}
        />
      </NbcuTooltip>
      )}
    </Box>
  );
}
