import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../../store';
import { API_ENDPOINTS } from '../consts/API_ENDPOINTS';

const ProxySlice = createSlice({
  name: 'proxy',
  initialState: {
    videoJsOptions: null,
    videoData: null,
    watermarkingToken: null,
  },
  reducers: {
    setVideoJsOptions(state, action) {
      state.videoJsOptions = action.payload;
    },
    setVideoMetaData(state, action) {
      state.videoData = action.payload;
    },
    setWatermarkingToken(state, action) {
      state.watermarkingToken = action.payload;
    },
  },
});

const { actions, reducer } = ProxySlice;

export const {
  setVideoJsOptions,
  setVideoMetaData,
  setWatermarkingToken,
} = actions;

export const getProxy = (
  materialId: string,
  storageSystem: string,
  watermarkedUrl: string | null,
) => (dispatch: AppDispatch) => {
  switch (true) {
    /* Watermarked proxy available */
    case Boolean(watermarkedUrl): {
      dispatch(setVideoJsOptions({
        controls: true,
        sources: [
          {
            src: watermarkedUrl,
            type: 'application/x-mpegURL',
          },
        ],
      }));

      return Promise.resolve();
    }

    /* PMAM asset */
    case storageSystem === 'PMAM_MEDIATOR': {
      dispatch(setVideoJsOptions({
        controls: true,
        sources: [
          {
            src: `${API_ENDPOINTS.PMAM_VIDEO_FILE}/mpd-manifest?materialId=${materialId}`,
            type: 'application/dash+xml',
          },
        ],
      }));

      return Promise.resolve();
    }

    /* MEDIATOR_X */
    case storageSystem === 'MEDIATOR_X': {
      const src = `${API_ENDPOINTS.MEDIATOR_X_VIDEO_FILE}/mpd-manifest?materialId=${materialId}`;

      dispatch(setVideoJsOptions({
        controls: true,
        sources: [
          {
            src,
            type: 'application/dash+xml',
          },
        ],
      }));

      return Promise.resolve();
    }

    /* MEDIATOR_8 */
    default: {
      const src = `${API_ENDPOINTS.VIDEO_PROXY}?materialId=${materialId}&storageSystem=${storageSystem}`;
      const type = 'video/mp4';
      // TODO: uncomment the following code when it's time to migrate Mediator 8 proxies to the ACAT buckets
      // const src = `${API_ENDPOINTS.GDL_VIDEO_FILE}/mpd-manifest?assetCatId=000472537014025255`;
      // const type = 'application/dash+xml';

      dispatch(setVideoJsOptions({
        controls: true,
        sources: [
          {
            src,
            type,
          },
        ],
      }));

      return Promise.resolve();
    }
  }
};

export const getWatermarkingToken = ((asset: any) => (dispatch: AppDispatch) => {
  const body = {
    sourceSystem: asset.storageSystem,
    materialUniqueId: `${asset.storageSystem}-${asset.materialId}`,
    sourceId: asset.materialId,
    gtmUnifiedVersionId: asset.gtmVersionUnifiedId || '', // this key is not required
    gtmId: asset.gtmId,
    gtmLevel: 'anyValueUnder20Char', // TODO: pass the actual gtmLevel
    gtmTitle: 'this just needs to be populated', // TODO: pass the actual gtmTitle
    metadata: '{ "test": "value" }', // TODO: pass meaning full metadata, this value has to be a JSON string
  };
  return axios
    .post(API_ENDPOINTS.WATERMARKING_TOKEN, body)
    .then((resp: any) => {
      dispatch(setWatermarkingToken(resp.data));
    });
});

export default reducer;
