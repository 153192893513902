import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import {
  colors,
  primary,
  secondary,
  disabled,
  bgcTertiary,
  decoPrimary,
  decoTertiary,
  bgcPrimary,
  shadeSecondary,
  buttonPlainHover,
} from '../../../../styles/base/colors';

interface ConfigureColumnDrawerStyles {
  root: CSSObject;
}

export const classes: ConfigureColumnDrawerStyles = {
  root: {
    '.MuiButton-root, .MuiButton-label': {
      fontFamily: '"Roboto classic", sans-serif !important',
    },
    'button.MuiButton-contained': {
      '&:hover': {
        backgroundColor: `${bgcTertiary} !important`,
      },
      '&:focus': {
        backgroundColor: `${bgcTertiary} !important`,
        border: `1px solid ${primary} !important`,
      },
      '&:disabled': {
        backgroundColor: `${disabled} !important`,
      },
    },
    'button.MuiButton-text': {
      '&:hover': {
        backgroundColor: `${buttonPlainHover} !important`,
      },
      '&:focus': {
        backgroundColor: `${buttonPlainHover} !important`,
        border: `1px solid ${primary} !important`,
      },
      '&:disabled': {
        backgroundColor: `${disabled} !important`,
      },
    },
    '.MuiButton-root:hover': {
      backgroundColor: `${colors.tealBlueBackground} !important`,
    },
    '.MuiButtonGroup-root': {
      '.MuiButton-root': {
        textTransform: 'none !important',
        fontWeight: 'normal !important',
        '&.active': {
          backgroundColor: bgcTertiary,
          borderColor: primary,
          color: secondary,
        },
      },
    },
    '.drawer-title': {
      height: '50px',
      padding: '0 20px',
      backgroundColor: decoTertiary,
      display: 'flex',
      alignItems: 'center',
    },
    '.drawer-content': {
      backgroundColor: decoPrimary,
      height: '100%',
      padding: '20px',
    },
    '.left-column': {
      position: 'relative',
      height: '100%',
      '.top-grid': {
        padding: '20px',
        display: 'flex',
        justifContent: 'center',
        alignItems: 'center',
      },
      '.drawer-content-container': {
        position: 'absolute',
        top: '90px',
        right: '0',
        bottom: '0',
        left: '0',
      },
    },
    '.middle-column': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      color: shadeSecondary,
    },
    '.right-column': {
      position: 'relative',
      height: '100%',
      '.configure-drawer-section': {
        position: 'absolute',
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
        overflow: 'scroll',
      },
      '.displayed-column': {
        padding: '0 10px',
        borderBottom: `1px solid ${colors.grayMedium}`,
        '&-item': {
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '10px 0',
          fontSize: '14px',
          alignItems: 'center',
          '.text-left': {
            width: '100%',
            paddingLeft: '20px',
          },
          'svg:first-of-type': {
            marginLeft: '10px',
          },
          ' svg:nth-of-type(2)': {
            marginRight: '10px',
          },
        },
      },
    },
    '.footer-buttons': {
      position: 'absolute',
      left: '0',
      right: '0',
      bottom: '0',
      display: 'flex',
      flexDirection: 'row',
      height: '55px',
      backgroundColor: bgcPrimary,
      button: {
        fontFamily: "'RockSans', serif",
        fontSize: '12px',
        color: colors.black,
        '&.apply': {
          backgroundColor: primary,
        },
      },
      '&_left': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '20px',
      },
      '&_right': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '20px',
      },
    },
    '.category-item_left_category': {
      border: 'none',
      background: 'inherit',
    },
  },
};
