import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../../store';
import { API_ENDPOINTS } from '../consts/API_ENDPOINTS';

const FeatureFlagSlice = createSlice({
  name: 'featureFlags',
  initialState: {
    featureFlags: {},
  },
  reducers: {
    setFeatureFlags(state, action) {
      state.featureFlags = action.payload;
    },
  },
});

const { actions, reducer } = FeatureFlagSlice;

export const {
  setFeatureFlags,
} = actions;

export const getFeatureFlags = (() => (dispatch: AppDispatch) => axios.get(API_ENDPOINTS.FEATURE_FLAGS).then((res) => {
  dispatch(setFeatureFlags(res?.data || {}));
}).catch((err) => {
  console.log(err);
}));

export default reducer;
