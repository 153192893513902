import React from 'react';
import { Box } from 'tl-storybook';
import HeaderLogo from '../../../../../assets/images/NBCUniversal_white.png';
import { classes } from './Header.styles';

export default function Header() {
  return (
    <Box id="header" sx={{ ...classes.root }}>
      <Box className="header-title">
        <img id="logo" src={HeaderLogo} alt="NBC Universal Logo" />
        <Box component="span">Production MAM</Box>
      </Box>
      <Box className="view-options">{/* placeholder for header options */}</Box>
    </Box>
  );
}
