import React from 'react';
import { Box } from 'tl-storybook';
import { classes } from './FileTitleRenderer.styles';

const FileTitleRenderer = (props:any) => {
  const { data, colDef } = props;

  return (
    <Box
      sx={{
        ...classes.textWrapper,
        ...(colDef?.context === 'comfortable' ? { height: '100px' } : { height: '52px' }),
      }}
    >
      <strong>{data.asset?.fileTitle}</strong>
      <div>{data.asset?.fileName}</div>
    </Box>
  );
};

export default FileTitleRenderer;
