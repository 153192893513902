import { Box } from 'tl-storybook';

const AudioChannelsRenderer = (props:any) => {
  const { data, colDef } = props;

  return (
    <Box
      sx={{
        ...({ padding: '0 0 0 10px' }),
        ...(colDef?.context === 'comfortable' ? { height: '100px' } : { height: '52px' }),
      }}
    >
      { data.audioChannels > 0 && data.audioChannels }
    </Box>

  );
};

export default AudioChannelsRenderer;
