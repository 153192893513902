import React from 'react';
import { Box, mui } from 'tl-storybook';
import { classes } from './FileTitleRenderer.styles';

const FileTitleRenderer = (props:any) => {
  const { data, colDef } = props;
  const markedForDelete = data?.pmam?.markedForDelete;
  return (
    <Box
      sx={{
        ...classes.textWrapper,
        ...(colDef?.context === 'comfortable' ? { height: '100px' } : { height: markedForDelete ? '82px' : '52px' }),
      }}
    >
      {
        markedForDelete ? (
          <mui.Chip
            className="top-chip red-chip"
            label="Marked for Delete"
          />
        ) : null
      }
      <strong>{data.pmam?.fileTitle}</strong>
      <div>{data.pmam?.fileName}</div>
    </Box>
  );
};

export default FileTitleRenderer;
