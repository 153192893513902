import {
  CSSObject,
  // constants,
} from 'tl-storybook';

  interface BooleanRendererStyles {
    booleanWrapper: CSSObject;
  }

export const classes: BooleanRendererStyles = {
  booleanWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: ' 0 0 0 10px',
    lineHeight: '24px',
  },
};
