import {
  CSSObject,
} from 'tl-storybook';

interface LoadingSpinnerStyles {
  root: CSSObject;
}

export const classes: LoadingSpinnerStyles = {
  root: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '100000',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
};
