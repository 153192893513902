import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { bgcPrimary } from '../../../../../styles/base/colors';

interface ConfigureSearchStyles {
  root: CSSObject;
}

export const classes: ConfigureSearchStyles = {
  root: {
    backgroundColor: bgcPrimary,
    width: '100%',
    borderRadius: '3.5px',
  },
};
