import React from 'react';
import { mui, icons } from 'tl-storybook';
import { classes } from './ConfigureSearch.styles';

interface Props {
  onChange: (value: string) => void;
  className?: string;
}

export default function ConfigureSearch(props: Props) {
  const { onChange, className } = props;
  return (
    <mui.TextField
      sx={{ ...classes.root }}
      variant="outlined"
      placeholder="Search Categories"
      onChange={(e) => onChange(e.target.value)}
      className={className}
      data-testid="main-search-bar"
      InputProps={{
        startAdornment: (
          <mui.InputAdornment position="start">
            <icons.Search />
          </mui.InputAdornment>
        ),
        style: {
          height: '48px',
        },
      }}
    />
  );
}
