import { CSSObject } from 'tl-storybook';

interface AppWrapperStyles {
  contentWrapper: CSSObject;
  loginPage: CSSObject;
}

export const classes: AppWrapperStyles = {
  contentWrapper: {
    position: 'absolute',
    top: '56px',
    left: '72px',
    width: 'calc(100% - 72px)',
    height: 'calc(100vh - 56px)',
  },
  loginPage: {
    left: '0px',
    width: '100%',
    backgroundColor: 'rgba(167, 164, 174, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
