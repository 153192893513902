import {
  CSSObject,
  // constants,
} from 'tl-storybook';

interface EditSavedFilterForm {
  root: CSSObject;
}

export const classes: EditSavedFilterForm = {
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '.filter-name-input': {
      width: '392px',
      padding: '16px 24px',
    },
    '.button-container': {
      display: 'flex',
      justifyContent: 'end',
      padding: '0 24px 16px',
      '.cancel-button': {
        ' marginRight': 'auto',
      },
    },
  },
};
