import React, { ReactNode } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';

interface Props<T = object> {
  iterableArray: T[];
  procreate: (item: T, dragHandleProps: DraggableProvidedDragHandleProps, index?: number) => ReactNode;
  dragEnd: (iterable: T[]) => void;
  updateFn?: (newIterable: T[]) => void;
  itemKey?: keyof T
}
export default function DragDropColumn<T>(props: Props<T>) {
  const {
    iterableArray, procreate, dragEnd, updateFn, itemKey,
  } = props;

  const reorder = (list: T[], startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(iterableArray, result.source.index, result.destination.index);
    if (updateFn) updateFn(items);
    dragEnd(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: DroppableProvided, _snapshot: DroppableStateSnapshot) => (
          <div
            role="tabpanel"
            id="simple-tabpanel-1"
            aria-labelledby="simple-tab-1"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {iterableArray.map((item: T, index: number) => {
              const key = typeof item === 'string' ? item : `${item[itemKey]}`;
              return (
                <Draggable key={key} draggableId={key} index={index}>
                  {(draggableProvided: DraggableProvided, _draggableStateSnapshot: DraggableStateSnapshot) => (
                    <div {...draggableProvided.draggableProps} ref={draggableProvided.innerRef}>
                      {procreate(item, draggableProvided.dragHandleProps, index)}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
