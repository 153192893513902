import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  mui,
  icons,
  NbcuButton,
  NbcuMenuItem,
} from 'tl-storybook';
import { useLocation } from 'react-router-dom';
import { MEDIA_TYPES } from '../../../consts/MEDIA_TYPES';

import { classes } from './PreviewHeader.styles';

interface PropTypes {
  asset: any;
  handleClose?: () => void | null;
}

export default function PreviewHeader(props: PropTypes) {
  const { asset, handleClose } = props;
  const location = useLocation();

  const [assetMenuAnchor, setAssetMenuAnchor] = useState<null | HTMLElement>();
  const [isPMAM, setIsPMAM] = useState(true);
  const [titleColumns, setTitleColumns] = useState(8);

  useEffect(() => {
    if (asset.sourceSystem === 'PMAM' && location.pathname.includes('details')) {
      setIsPMAM(true);
      setTitleColumns(9);
      return;
    }
    if (asset.sourceSystem !== 'PMAM' && location.pathname.includes('details')) {
      setIsPMAM(false);
      setTitleColumns(12);
      return;
    }
    if (asset.sourceSystem !== 'PMAM') {
      setIsPMAM(false);
      setTitleColumns(11);
    }
    if (asset.sourceSystem === 'PMAM') {
      setIsPMAM(true);
      setTitleColumns(8);
    }
  }, []);

  const openPreviewMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAssetMenuAnchor(event.currentTarget);
  };

  const closePreviewMenu = () => {
    setAssetMenuAnchor(null);
  };

  return (
    <Box sx={{ ...classes.root }}>
      <Grid container>
        {isPMAM && (
          <Grid item xs={3}>
            <NbcuButton
              endIcon={<icons.ArrowDropDown />}
              onClick={openPreviewMenu}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                },
              }}
            >
              Actions
            </NbcuButton>
            <mui.Menu
              id="PreviewDialog_menu"
              open={Boolean(assetMenuAnchor)}
              anchorEl={assetMenuAnchor}
              onClose={closePreviewMenu}
              sx={{
                '& .MuiMenu-list': {
                  minWidth: '121px',
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                },
              }}
            >
              <NbcuMenuItem>
                Approve
              </NbcuMenuItem>
              <NbcuMenuItem>
                Reject
              </NbcuMenuItem>
            </mui.Menu>
          </Grid>
        )}
        <Grid item xs={titleColumns} sx={{ display: 'flex', alignItems: 'center' }}>
          {MEDIA_TYPES.map((mediaType) => {
            if (mediaType.code === asset.mediaType) {
              const MediaTypeIcon = mediaType.icon;

              return (
                <MediaTypeIcon
                  key={mediaType.code}
                  sx={{
                    position: 'relative',
                    top: '-2px',
                    margin: '0 16px 0 0',
                  }}
                />
              );
            }
            return null;
          })}
          <Box sx={{ position: 'relative', top: '1px' }}>
            <h3>{asset.title}</h3>
          </Box>
          {asset.pmam && <p>{asset.pmam.fileTitle}</p>}
        </Grid>
        {handleClose && (
          <Grid item xs={1} container sx={{ justifyContent: 'right' }}>
            <icons.Close className="close-icon" onClick={handleClose} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
