import React from 'react';
import { mui } from 'tl-storybook';
import { TableButtonProps } from '../../../../interfaces/table-button';

export default function TableButton(props: TableButtonProps) {
  const {
    text, icon, disabled, onClick,
  } = props;

  return (
    <mui.Button
      variant="outlined"
      className="table-button"
      onClick={onClick}
      data-automation-id={`table-button-${text}`}
      data-testid={`table-button-${text}`}
      disabled={disabled}
      sx={{
        '&.MuiButton-root': {
          padding: '5px 15px',
          borderRadius: '4px',
        },
      }}
    >
      {icon}
      {text}
    </mui.Button>
  );
}
