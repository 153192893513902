import React from 'react';
import { Box, mui, icons } from 'tl-storybook';
import ErrorIconAvatar from '../../../../core/components/app/ErrorIconAvatar';
import { WorkOrder } from '../../../../interfaces/work-order';
import { makePropertiesDrawerFields } from '../../../../core/utils-tsx';
import { PROPERTIES_ORDER_HISTORY_LEFT, PROPERTIES_ORDER_HISTORY_RIGHT } from '../../../consts/PropertiesForms';
import { classes } from './OrderHistoryTab.styles';

const SOURCES: { [key: string]: string } = {
  mam: 'TL',
  mediator: 'M',
  prom: 'P',
};

interface Props {
  workOrders: WorkOrder[];
}

export default function AssetsPropertiesDrawerOrderHistoryTab(props: Props) {
  const { workOrders } = props;

  return (
    <Box sx={{ ...classes.root }} data-testid="assets-properties-panel-order-history-tab">
      {!!workOrders?.length && (
        workOrders.map((workOrder) => (
          <Box key={workOrder.id}>
            <icons.AddBoxOutlined className="work-order" />
            <mui.Grid container>
              <mui.Grid item xs={2}>
                <Box className="source-chip">
                  <Box className="source-text roboto-font">
                    {SOURCES[workOrder.state?.source]}
                  </Box>
                </Box>
              </mui.Grid>
              <mui.Grid item xs={5}>
                {makePropertiesDrawerFields(PROPERTIES_ORDER_HISTORY_LEFT, workOrder, true)}
              </mui.Grid>
              <mui.Grid item xs={5}>
                {makePropertiesDrawerFields(PROPERTIES_ORDER_HISTORY_RIGHT, workOrder, true)}
              </mui.Grid>
            </mui.Grid>
          </Box>
        ))
      )}
      {workOrders?.length === 0 && (
        <ErrorIconAvatar message="No Order History" icon={icons.InsertDriveFile} />
      )}
      {!workOrders && (
        <ErrorIconAvatar message="Unable to retrieve data" icon={icons.Report} />
      )}
    </Box>
  );
}
