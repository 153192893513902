import { Box, icons } from 'tl-storybook';
import React from 'react';
import { classes } from './Modal.styles';

interface ModalProps {
    onClose: () => void,
    heading: string,
    bodyContent: any,
}

export default function Modal({ onClose, heading, bodyContent }: ModalProps) {
  return (
    <Box sx={{ ...classes.root }} data-testid="modal-container">
      <Box className="modal-box">
        <Box onClick={() => onClose()} className="close-icon">
          <icons.Close />
        </Box>
        <h4>{heading}</h4>
        {bodyContent}
      </Box>
    </Box>
  );
}
