import {
  CSSObject,
  // constants,
} from 'tl-storybook';

  interface masterTypeRendererStyles {
    textWrapper: CSSObject;
  }

export const classes: masterTypeRendererStyles = {
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: '24px',
  },
};
