import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { AppRoute } from '../interfaces/route';
import { makeRoute } from '../shared/route-maker';

const routes: AppRoute[] = [
  makeRoute(
    '/assets',
    lazy(() => import('./components/AssetsLanding')),
  ),
  makeRoute(
    '/assets/order-assets',
    lazy(() => import('./components/OrderAssets')),
  ),
  makeRoute(
    '/assets/vpost-order-assets',
    lazy(() => import('./components/VPostOrderAssets')),
  ),
  makeRoute(
    '/assets/create-orders',
    lazy(() => import('./components/CreateOrders')),
  ),
  makeRoute(
    '/assets/create-subclip',
    lazy(() => import('./components/CreateSubclips')),
  ),
  makeRoute(
    '/assets/edit-assets',
    lazy(() => import('./components/EditAssets')),
  ),
  makeRoute(
    '/assets/detail/:assetId',
    lazy(() => import('./components/AssetsDetail')),
  ),
  makeRoute(
    '/assets/proxy',
    lazy(() => import('../core/components/ProxyPlayer/ProxyPlayerTestWrapper')),
  ),
];

export default routes.map((route) => (
  <Route key={route.path} path={route.path} element={<route.component />} />
));
