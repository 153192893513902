import { CSSObject } from 'tl-storybook';

interface AssetsDetailStyles {
  root: CSSObject;
}

export const classes: AssetsDetailStyles = {
  root: {
    '.field-section': {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      '.properties-drawer-form-field': {
        width: '33.33%',
        '.MuiInputBase-root': {
          paddingRight: '16px',
        },
      },
    },
    '.audio-details-container': {
      position: 'relative',
      width: '100%',
      margin: '10px 0 0 0',
      '.audio-details-label': {
        position: 'absolute',
        top: 0,
        left: 0,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        color: '#787878',
        fontSize: '1rem',
        lineHeight: '1.4375em',
        letterSpacing: '0.00938em',
        transformOrigin: 'top left',
        transform: 'translate(0, -1.5px) scale(0.75)',
      },
      '.audio-details': {
        margin: '16px 0 0 0',
        padding: '12px 0 13px 0',
        minHeight: '23px',
        '.audio-track': {
          padding: '2px 0',
        },
      },
    },
  },
};
