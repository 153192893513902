import {
  CSSObject,
} from 'tl-storybook';
import { colors } from '../../../../../styles/base/colors';

interface PreviewDialogStyles {
  root: CSSObject;
}

export const classes: PreviewDialogStyles = {
  root: {
    color: colors.white,
    backgroundColor: colors.black,
    padding: '20px',
    '.close-icon': {
      cursor: 'pointer',
    },
    p: {
      margin: '0',
    },
  },
};
