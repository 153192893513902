import React, { useState, useEffect } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { Box } from 'tl-storybook';

import AppRouter from '../AppRouter';
import ErrorSnackbar from '../ErrorSnackbar';
import AlertSnackbar from '../AlertSnackbar';
import Header from '../Header';
import LeftNav from '../LeftNav';
import PreviewDialog from '../PreviewDialog';
import { useAppSelector } from '../../../../interfaces/hooks';
import LoadingSpinner from '../LoadingSpinner';
import DialogMediator8Error from '../../Modal/DialogMediator8Error';

import { classes } from './AppWrapper.styles';
import ConfirmModal from '../../Modal/ConfirmModal';

declare global {
  interface Window {
    sessionTimer: any;
  }
}

export default function AppWrapper() {
  const { previewOpen } = useAppSelector((state) => state.preview);
  const loadingSpinner = useAppSelector((state) => state.userFeedBack.loadingSpinner);
  const hideLeftNavPaths = ['/login', '/create-account'];
  const hideLeftNav = hideLeftNavPaths.includes(window.location.pathname);
  const [showSessionModal, setShowSessionModal] = useState<boolean>(false);
  const { featureFlags } = useAppSelector<any>((state) => state.featureFlags);

  useEffect(() => {
    sessionStorage.setItem('inactivityTime', '0');
  }, []);

  useEffect(() => {
    if (window.sessionTimer) {
      clearInterval(window.sessionTimer);
    }

    const activityEvent = () => {
      sessionStorage.setItem('inactivityTime', '0');
    };

    window.addEventListener('click', activityEvent);

    if (!window.location.pathname.includes('login') && featureFlags.warningTimeout) {
      window.sessionTimer = setInterval(() => {
        const currentInactivity = Number(sessionStorage.getItem('inactivityTime'));

        // After 25 minutes, warn the user
        if (currentInactivity === (featureFlags.warningTimeout || 1500)) {
          setShowSessionModal(true);
        }

        // After 30 minutes, log out the user
        if (currentInactivity === (featureFlags.sessionTimeout || 1800)) {
          window.location.href = '/logout';
        }

        sessionStorage.setItem('inactivityTime', (currentInactivity + 1).toString());
      }, 1000);
    }

    return () => {
      clearInterval(window.sessionTimer);
      window.removeEventListener('click', activityEvent);
    };
  }, [setShowSessionModal, featureFlags]);

  useEffect(() => {
    let link: HTMLLinkElement = document.querySelector("link[rel~='icon']");

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }

    link.href = showSessionModal ? '/warning.ico' : '/favicon.ico';
  }, [showSessionModal]);

  const handleSessionWarning = (logout: boolean) => {
    if (logout) {
      window.location.href = '/logout';
      return;
    }

    setShowSessionModal(false);
  };

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <Box data-testid="app-wrapper">
        {
          showSessionModal ? (
            <ConfirmModal
              heading="Your session is about to expire."
              text="You will be logged out in 5 minutes if no action is taken."
              cancelText="Continue Session"
              action="Log Out"
              onClose={(logout: boolean) => handleSessionWarning(logout)}
            />
          ) : null
        }
        <ErrorSnackbar />
        <AlertSnackbar />
        <DialogMediator8Error />
        {previewOpen && <PreviewDialog />}
        <Header />
        <Box>
          {!hideLeftNav && <LeftNav />}
          <Box
            sx={{
              ...classes.contentWrapper,
              ...(hideLeftNav && classes.loginPage),
            }}
          >
            {loadingSpinner && (
              <LoadingSpinner size="100px" />
            )}
            <AppRouter />
          </Box>
        </Box>
      </Box>
    </QueryParamProvider>
  );
}
