import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NbcuSidenav, Box, icons } from 'tl-storybook';
import { useLocation } from 'react-router-dom';
import { API_ENDPOINTS } from '../../../consts/API_ENDPOINTS';

import { AppRouteDefinition } from '../../../../interfaces/route';
import { classes } from './LeftNav.styles';
import { useAppSelector } from '../../../../interfaces/hooks';

const navBtns: AppRouteDefinition[] = [{
  url: '/work-orders',
  icon: icons.Inbox,
  disabled: false,
  label: 'Work Orders',
},
{
  url: '/assets',
  icon: icons.PermMedia,
  label: 'Assets',
},
{
  url: '/collections',
  icon: icons.FilterNone,
  label: 'Collections',
},
{
  url: '/clientportal',
  icon: icons.InfoOutlined,
  disabled: true,
  label: 'Client Portal',
},
{
  url: '/',
  icon: icons.Image,
  disabled: true,
  label: 'Image Management',
  onClick: null,
},
{
  url: '/admin',
  icon: icons.Security,
  disabled: true,
  label: 'Admin',
},
{
  url: '/login',
  icon: icons.ExitToApp,
  label: 'Logout',
  alignBottom: true,
  onClick: () => {
    // TODO: implement logout functionality here
  },
}];

export default function LeftNav() {
  const location = useLocation();
  const { featureFlags } = useAppSelector<any>((state) => state.featureFlags);

  const [items, setItems] = useState(navBtns);

  const openGiwHomePage = (e: any) => {
    e.preventDefault();
    axios.get(`${API_ENDPOINTS.GIW_USER}`).then((resp: any) => {
      window.open(resp.data);
    });
  };

  useEffect(() => {
    if (location.pathname === '/assets') {
      items[1].url = `/assets${location.search || ''}`;
      setItems([...items]);
    }
  }, [location.search]);

  useEffect(() => {
    if (featureFlags.imageManagement) {
      items[4].disabled = false;
      items[4].onClick = openGiwHomePage;
      setItems([...items]);
    }
  }, [featureFlags]);

  return (
    <Box sx={{ ...classes.root }}>
      <NbcuSidenav items={items} />
    </Box>
  );
}
