export const TABLE_WRAPPER_PROPERTIES = {
  assets: {
    showTableOptions: true,
    showTableButtons: true,
    hideCard: false,
    hideList: false,
    hideViewList: false,
    hideSettings: false,
  },
  collectionAssets: {
    showTableOptions: true,
    showTableButtons: true,
    hideCard: false,
    hideList: false,
    hideViewList: true,
    hideSettings: false,
  },
  workOrders: {
    showTableOptions: true,
    showTableButtons: true,
    hideCard: true,
    hideList: true,
    hideViewList: true,
    hideSettings: false,
  },
  collections: {
    showTableOptions: false,
    showTableButtons: true,
    hideCard: false,
    hideList: false,
    hideViewList: false,
    hideSettings: false,
  },
  addToCollection: {
    showTableOptions: false,
    showTableButtons: false,
    hideCard: false,
    hideList: false,
    hideViewList: false,
    hideSettings: false,
  },
  collectionDetails: {
    showTableOptions: false,
    showTableButtons: true,
    hideCard: false,
    hideList: false,
    hideViewList: false,
    hideSettings: false,
  },
  studioSupplyOrders: {
    showTableOptions: true,
    showTableButtons: true,
    hideCard: true,
    hideList: true,
    hideViewList: true,
    hideSettings: false,
  },
};
