import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { colors } from '../../../../../styles/base/colors';

interface TableOptionsStyles {
  root: CSSObject;
  selected: CSSObject;
}

export const classes: TableOptionsStyles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '> *': {
      marginLeft: '10px',
      cursor: 'pointer',
    },
    '.icon-wrapper': {
      height: '24px',
      width: '24px',
    },
  },
  selected: {
    color: colors.tealBlue,
  },
};
