import {
  CSSObject,
} from 'tl-storybook';

interface AssetsLandingStyles {
  root: CSSObject;
}

export const classes: AssetsLandingStyles = {
  root: {
    position: 'relative',
    display: 'flex',
    height: 'calc(100vh - 56px)',
    overflow: 'hidden',
    '.filter-search': {
      display: 'flex',
      '.filter-icon': {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '16px',
      },
      '.filter-chips': {
        position: 'relative',
        flex: '1',
        overflow: 'auto',
        '.filter-chips-scrolling': {
          position: 'absolute',
          top: '0',
          left: '0',
          bottom: '0',
          display: 'flex',
          alignItems: 'center',
          width: 'max-content',
        },
      },
      '.filter-search': {
        paddingLeft: '16px',
      },
    },
    '.table-container': {
      flexGrow: 1,
      padding: '16px 20px',
      height: 'calc(100vh - 88px)',
      marginLeft: '0',
      transition: 'margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      zIndex: 1,
    },
    '.table-filter': {
      position: 'relative',
    },
    '.table-no-data': {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '400px',
      zIndex: '1',
    },
    '.search-container': {
      display: 'flex',
      justifyContent: 'flex-end',
      '.MuiInputBase-root': {
        width: '100%',
      },
    },
    '.MuiCard-root': {
      height: 'calc(100vh - 88px)',
    },
    '.MuiButtonBase-root.MuiChip-root': {
      marginLeft: '5px',
      '&:focus': {
        border: '1px solid #191919',
      },
    },
    '.table-assets': {
      height: 'calc(100vh - 272px)',
      maxHeight: 'calc(100vh - 272px)',
      width: '100%',
      '.table': {
        minWidth: '100%',
        height: 'calc(100vh - 272px)',
        maxHeight: 'calc(100vh - 272px)',
        '&:first-of-type': {
          maxWidth: 'none',
          width: '100%',
        },
      },
    },
  },
};
