export interface Option {
  description: string;
  id: string | number;
}

export interface OptionWithCode {
  code: string;
  description: string;
  id: number;
}

export interface AirTableModel {
  airtableId: string;
  assetType: Option;
  associatedGtmTitleIds: number[];
  campaign: string;
  campaignId: string;
  comments: string;
  compassId: string;
  contentType: Option;
  deliverableName: string;
  deliverableNumber: string;
  dueDate: string;
  fileName: string;
  fileTitle: string;
  gtmId: number;
  houseNumber: string;
  lengthInSeconds: number;
  materialType: OptionWithCode;
  mediaType: OptionWithCode;
  producer: string;
  productionType: Option;
  project: string;
  promoCode: string;
  revisionNumber: string;
  shipDate: string;
  showTitle: string;
  startAir: string;
  status: string;
  tag: string;
  timeReacted: string;
  userNetwork: OptionWithCode;
  versionType: Option;
}

export interface EpisodeVersionsListItem {
  versionId: number;
  versionName: string;
}

export interface EpisodesListItem {
  gtmId: number;
  title: string;
}

export interface FeatureVersionsListItem {
  versionId: number;
  versionName: string;
}

export interface FeaturesListItem {
  gtmId: number;
  title: string;
}

export interface SeasonsListItem {
  gtmId: number;
  seasonNumber: string;
}

export interface SeasonVersionsListItem {
  versionName: string;
  versionId: number;
}

export interface SeriesListItem {
  gtmId: number;
  title: string;
}

export interface SeriesVersionsListItem {
  versionName: string;
  versionId: number;
}

export interface AssociatedGtm {
  episodeVersions: EpisodeVersionsListItem[];
  episodes: EpisodesListItem[];
  featureVersions: FeatureVersionsListItem[];
  features: FeaturesListItem[];
  seasons: SeasonsListItem[];
  seasonVersions: SeasonVersionsListItem[];
  series: SeriesListItem[];
  seriesVersions: SeriesVersionsListItem[];
}

export enum EntityLevel {
  SERIES = 'SERIES',
  SERIESVERSION = 'SERIESVERSION',
  SEASON = 'SEASON',
  SEASONVERSION = 'SEASONVERSION',
  EPISODE = 'EPISODE',
  EPISODEVERSION = 'EPISODEVERSION',
  FEATURE = 'FEATURE',
  FEATUREVERSION = 'FEATUREVERSION',
}

export interface AssociatedGtmEntitiesListItem {
  entityId: number;
  entityLevel: EntityLevel;
  unifiedId: string;
}

export interface DaisyAsset {
  daisyceAssetId: string;
}

export interface GtmSeries {
  activeFlag: boolean;
  level: string;
  network: string;
  seriesId: number;
  seriesSynopsis: string;
  seriesTitle: string;
  compassTitleNumber?: string;
}

export interface GtmSeriesVersion {
  unifiedId: string;
  seriesVersionId: number;
  seriesVersionTitle: string;
  originalFlag: boolean;
  level: string;
  network: string;
  activeFlag: boolean;
  seriesId: number;
  seriesTitle: string;
  seriesSynopsis: string;
  compassTitleNumber: string;
}

export interface GtmFeature {
  activeFlag: boolean;
  compassTitleNumber: string;
  featureId: number;
  featureSynopsis: string;
  featureTitle: string;
  level: string;
  network: string;
  originalAirDate: string;
}

export interface GtmFeatureVersion {
  activeFlag: boolean;
  compassTitleNumber: string;
  featureId: number;
  featureSynopsis: string;
  featureTitle: string;
  featureVersionId: number;
  featureVersionTitle: string;
  level: string;
  network: string;
  originalAirDate: string;
  originalFlag: boolean;
}

export interface GtmSeason {
  activeFlag: boolean;
  level: string;
  network: string;
  seasonId: number;
  seasonNo: string;
  seasonSynopsis: string;
  seasonTitle: string;
  seriesId: number;
  seriesSynopsis: string;
  seriesTitle: string;
  compassTitleNumber?: string;
}

export interface GtmSeasonVersion {
  unifiedId: string;
  seasonVersionId: number;
  seasonVersionTitle: string;
  originalFlag: boolean;
  seasonId: number;
  seasonTitle: string;
  seasonNo: string;
  seasonSynopsis: string;
  network: string;
  activeFlag: boolean;
  level: string;
  seriesId: number;
  seriesTitle: string;
  seriesSynopsis: string;
  compassTitleNumber: string;
}

export interface GtmEpisode {
  activeFlag: boolean;
  compassTitleNumber: string;
  episodeId: number;
  episodeNo: string;
  episodeSynopsis: string;
  episodeTitle: string;
  level: string;
  network: string;
  originalAirDate: string;
  seasonId: number;
  seasonNo: string;
  seasonSynopsis: string;
  seasonTitle: string;
  seriesId: number;
  seriesSynopsis: string;
  seriesTitle: string;
}

export interface GtmEpisodeVersion {
  activeFlag: boolean;
  compassTitleNumber: string;
  episodeId: number;
  episodeNo: string;
  episodeSynopsis: string;
  episodeTitle: string;
  episodeVersionId: number;
  episodeVersionSynopsis: string;
  episodeVersionTitle: string;
  level: string;
  network: string;
  originalAirDate: string;
  originalFlag: boolean;
  seasonId: number;
  seasonNo: string;
  seasonSynopsis: string;
  seasonTitle: string;
  seriesId: number;
  seriesSynopsis: string;
  seriesTitle: string;
}

export enum GtmLevel {
  SERIES = 'SERIES',
  SERIESVERSION= 'SERIESVERSION',
  SEASON = 'SEASON',
  SEASONVERSION = 'SEASONVERSION',
  EPISODE = 'EPISODE',
  EPISODEVERSION = 'EPISODEVERSION',
  FEATURE = 'FEATURE',
  FEATUREVERSION = 'FEATUREVERSION',
  BRAND = 'BRAND',
}

export enum MlaiStatus {
  ANALYSIS_REQUESTED = 'ANALYSIS_REQUESTED',
  UNDEFINED = 'UNDEFINED',
  UNKNOWN = 'UNKNOWN',
}

export interface Prom {
  deliverableNumber: number;
  dueDate: string;
  episodeTitle: string;
  houseNumber: string;
  promVersionType: string;
  promoCode: string;
  seasonNumber: string;
  shipDate: string;
  showTitle: string;
}

export enum SvApprovalStatus {
  NOT_REQUIRED = 'NOT_REQUIRED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface DocumentTechnicalSpecs {
  parentPath: string;
  size: number;
  techSpecType: string;
}

export interface ProjectTechnicalSpecs {
  parentPath: string;
  size: number;
  techSpecType: string;
}

export interface AudioTechnicalSpecs {
  audioBitDepth: string;
  audioBitDepthLong: number;
  audioBitRate: string;
  audioBitRateLong: number;
  audioCodec: string;
  audioLayout: string;
  duration: string;
  numAudioChannel: string;
  parentPath: string;
  samplingRate: string;
  size: number;
  techSpecType: string;
  totalRunTime: string;
}

export interface VideoTechnicalSpecs {
  audioBitDepth: string;
  audioBitDepthLong: number;
  audioBitRate: string;
  audioBitRateLong: number;
  audioCodec: string;
  audioLayout: string;
  displayHeight: string;
  displayWidth: string;
  duration: string;
  frameRate: string;
  numAudioChannel: string;
  parentPath: string;
  samplingRate: string;
  size: number;
  techSpecType: string;
  totalRunTime: string;
  videoBitDepth: string;
  videoBitDepthLong: number;
  videoBitRate: string;
  videoBitRateLong: number;
  videoCodec: string;
  videoScanType: string;
  wrapper: string;
}

export interface ImageTechnicalSpecs {
  aperture: string;
  colorSpace: string;
  compressionMode: string;
  contrast: string;
  creator: string;
  displayHeight: string;
  displayWidth: string;
  expComp: string;
  firmware: string;
  flashComp: string;
  iccProfile: string;
  iso: string;
  latitude: string;
  lens: string;
  longitude: string;
  model: string;
  orientation: string;
  parentPath: string;
  program: string;
  serial: string;
  shutter: string;
  size: number;
  techSpecType: string;
  territory: string;
  whiteBal: string;
}

export enum UploadStatus {
  TRANSFERRING = 'TRANSFERRING',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
}

export interface PmamAsset {
  acquisitionSource: string;
  actorPeople: string;
  aemAssetPath: string;
  aemDeliveryStatus: string;
  airtable?: AirTableModel;
  airtableId: string;
  airtableStatus?: string;
  aspectRatio: OptionWithCode;
  assetId: string;
  assetType: Option;
  associatedGtm?: AssociatedGtm;
  associatedGtmEntities: AssociatedGtmEntitiesListItem[];
  associatedBrands: number[];
  associatedBrandOptions: OptionWithCode[];
  audioProfile: OptionWithCode;
  browseReady: boolean;
  cloudianBucketPath: string;
  comments: string;
  contentType: Option;
  copyright: string;
  copyrightOwner: string;
  createdAt: string;
  createdBy: string;
  createdBySuggest: string;
  daisyAsset: DaisyAsset;
  damAssetPublish: boolean;
  deceased: boolean;
  deliverableNumber: number;
  dueDate: string;
  episodeTitle: string;
  fileExtension: string;
  fileName: string;
  fileTitle: string;
  freeform: string;
  gtm: GtmSeries |
    GtmSeriesVersion |
    GtmFeature |
    GtmFeatureVersion |
    GtmSeason |
    GtmSeasonVersion |
    GtmEpisode |
    GtmEpisodeVersion;
  gtmId: number;
  gtmLevel: GtmLevel;
  gtmMdvUnifiedId: string;
  gtmVersionUnifiedId: string;
  houseNumber: string;
  id: number;
  keyframeReady: boolean;
  keywords: string;
  language: OptionWithCode;
  linkedToBrand: OptionWithCode;
  mamId: string;
  markedForDelete: boolean;
  markedForDigitization: boolean;
  maskType: OptionWithCode;
  master: Option;
  materialType: OptionWithCode;
  mediaType: OptionWithCode;
  mediatorStatus: string;
  mediatorStatusProgress: number;
  merlinDeliveryStatus: string;
  mlaiStatus: MlaiStatus;
  oldReferenceIds: string;
  physicalStorageLocation: boolean;
  premierDate: string;
  producer: string;
  productionType: Option;
  prom: Prom;
  registrationStatus: string;
  replacementPending: boolean;
  restrictions: string;
  seasonNo: string;
  seriesTitle: string;
  shipDate: string;
  shootDate: string;
  storageLocation: string;
  submasterAssetId: number;
  svApprovalStatus: SvApprovalStatus;
  tagged: boolean;
  team: string;
  technicalSpecs:
    DocumentTechnicalSpecs |
    ProjectTechnicalSpecs |
    AudioTechnicalSpecs |
    VideoTechnicalSpecs |
    ImageTechnicalSpecs;
  textType: OptionWithCode;
  tmsId: string;
  transformation: OptionWithCode;
  updatedAt: string;
  updatedBy: string;
  updatedBySuggest: string;
  uploadDate: string;
  uploadId: string;
  uploadProgress: number;
  uploadStatus: UploadStatus;
  uploader: string;
  userDescription: string;
  userNetwork: OptionWithCode;
  versionType: Option;
}
