import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { colors } from '../../../../../styles/base/colors';

interface OrderHistoryTabStyles {
  root: CSSObject;
}

export const classes: OrderHistoryTabStyles = {
  root: {
    padding: '25px 16px',
    '.source-chip': {
      margin: 'auto',
      backgroundColor: colors.grayMedium,
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      textAlign: 'center',
      '.source-text': {
        paddingTop: '7px',
        width: '30px',
        height: '30px',
      },
    },
    '.error-icon-badge': {
      margin: '50px auto',
    },
  },
};
