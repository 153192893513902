import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { colors, secondary, bgcSecondary } from '../../../../../styles/base/colors';

interface MediaTypeFilterStyles {
  root: CSSObject;
}

export const classes: MediaTypeFilterStyles = {
  root: {
    '.MuiToggleButtonGroup-root .MuiToggleButton-root': {
      padding: '11px',
      border: `1px solid ${bgcSecondary}`,
      '&.Mui-selected': {
        color: secondary,
        backgroundColor: colors.tealBlueBackground,
      },
    },
  },
};
