import {
  CSSObject,
  // constants,
} from 'tl-storybook';

interface PreviewRendererStyles {
  root: CSSObject;
}

export const classes: PreviewRendererStyles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    img: {
      width: 'inherit !important',
    },
  },
};
