import {
  CSSObject,
} from 'tl-storybook';

interface AudioDetailsRendererStyles {
  root: CSSObject;
}

export const classes: AudioDetailsRendererStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'auto',
    padding: '0 0 0 10px',
    '.audio-track': {
      padding: '2px 0',
      textWrap: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      minHeight: '20px',
      lineHeight: '20px',
    },
  },
};
