import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import 'reflect-metadata';
import App from './app/App';

axios.interceptors.request.use(
  (request) => {
    request.headers['Cache-Control'] = 'max-age=0, must-revalidate, public, no-cache';
    request.headers.Expires = 0;
    return request;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    if (response && response.data && response.data.location) {
      window.location = response.data.location;
    } else {
      return response;
    }
  },
  (error) => {
    // 401 request lacks valid authentication credentials, 403 forbidden lacks permissions...
    if (Number(error.response.status) === 403 || Number(error.response.status) === 401) {
      const { origin, pathname, search } = new URL(window.location.href);
      // Save the current location to redirect to after login...
      sessionStorage.setItem('redirectAfterLogin', pathname + search);
      window.location.href = `${origin}/login`;
      return;
    }
    if (error.response && error.response.data && error.response.data.location) {
      window.location = error.response.data.location;
    } else {
      return Promise.reject(error);
    }
  },
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
