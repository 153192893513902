import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { bgcPrimary } from '../../../../../styles/base/colors';

interface SearchInputStyles {
  root: CSSObject;
}

export const classes: SearchInputStyles = {
  root: {
    width: '270px',
    backgroundColor: bgcPrimary,
    height: '48px',
    borderRadius: '3.5px',
  },
};
