import {
  CSSObject,
  // constants,
} from 'tl-storybook';

interface MediaThumbnailStyles {
  root: CSSObject;
  playButton: CSSObject;
  disabled: CSSObject;
  restricted: CSSObject;
}

export const classes: MediaThumbnailStyles = {
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '.proxy-image': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  restricted: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    height: '25px',
    width: '25px',
    cursor: 'pointer',
  },
  playButton: {
    position: 'absolute',
    color: 'white',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    height: '25px',
    width: '25px',
    cursor: 'pointer',
  },
  disabled: {
    color: '#ccc',
    cursor: 'default',
  },
};
