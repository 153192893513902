import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../../core/consts/API_ENDPOINTS';

const RequiredDataFormSlice = createSlice({
  name: 'required-data-form',
  initialState: {
    materialType: '',
    productionType: '',
    assetType: '',
    contentType: '',
    materialTypeOptions: [],
    productionTypeOptions: [],
  },
  reducers: {
    setMaterialType(state, action) {
      state.materialType = action.payload;
    },
    setProductionType(state, action) {
      state.productionType = action.payload;
    },
    setAssetType(state, action) {
      state.assetType = action.payload;
    },
    setContentType(state, action) {
      state.contentType = action.payload;
    },
    setMaterialTypeOptions(state, action) {
      state.materialTypeOptions = action.payload;
    },
    setProductionTypeOptions(state, action) {
      state.productionTypeOptions = action.payload;
    },
    resetRequiredDataForm(state) {
      state.materialType = '';
      state.productionType = '';
      state.assetType = '';
      state.contentType = '';
      state.materialTypeOptions = [];
      state.productionTypeOptions = [];
    },
  },
});

const { actions, reducer } = RequiredDataFormSlice;

export const {
  setMaterialType,
  setProductionType,
  setAssetType,
  setContentType,
  setMaterialTypeOptions,
  setProductionTypeOptions,
  resetRequiredDataForm,
} = actions;

export const getMaterialTypeOptions = (mediaId: number) => () => axios
  .get(`${API_ENDPOINTS.MATERIAL_TYPES}?mediaTypeId=${mediaId}`)
  .then((resp: any) => resp.data);

export const getProductionTypeOptions = (mediaId: number, materialId: number) => () => axios
  .get(`${API_ENDPOINTS.DROPDOWN_OPTIONS}/production-types?mediaType=${mediaId}&materialType=${materialId}`)
  .then((resp: any) => resp.data);

export default reducer;
