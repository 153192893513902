import React, {
  useEffect, useState, lazy, Suspense,
} from 'react';
import { mui } from 'tl-storybook';
import { extensionHasImage } from '../../../utils';
import { Asset } from '../../../../interfaces/asset';
import { updateErrorMessage, updateErrorOpen } from '../../../slices/ErrorSlice';
import { getProxy, setVideoJsOptions, setVideoMetaData } from '../../../slices/ProxySlice';
import { updatePreviewOpen, setWatermarkedUrl } from '../../../slices/PreviewSlice';
import { useAppSelector, useAppDispatch } from '../../../../interfaces/hooks';
import { AppDispatch } from '../../../../store';
import PreviewHeader from './PreviewHeader';
import LoadingSpinner from '../LoadingSpinner';

const ProxyPlayer = lazy(() => import('../../ProxyPlayer'));

export default function PreviewDialog() {
  const dispatch: AppDispatch = useAppDispatch();
  const videoJsOptions = useAppSelector((state) => state.proxy.videoJsOptions);
  const { videoData } = useAppSelector((state) => state.proxy);
  const { previewOpen, selectedPreview, watermarkedUrl } = useAppSelector((state) => state.preview);

  const [imgSrc, setImgSrc] = useState('');

  const isImagePreview = (selectedAsset: Asset) => selectedAsset.mediaType === 'IMAGE' || extensionHasImage(selectedAsset);

  useEffect(() => {
    if (isImagePreview(selectedPreview)) {
      setImgSrc(selectedPreview.imageURLFullSize || (
        `/API/image-proxy/${selectedPreview.materialId}/${selectedPreview.materialId}_800x800.jpeg`
      ));
    } else {
      dispatch(getProxy(
        selectedPreview.materialId,
        selectedPreview.storageSystem,
        watermarkedUrl,
      )).catch((err: any) => {
        dispatch(updateErrorMessage(err));
        dispatch(updateErrorOpen(true));
      });
    }
    return () => {
      dispatch(setVideoJsOptions(null));
      dispatch(setVideoMetaData(null));
    };
  }, []);

  const handleClose = () => {
    dispatch(setVideoJsOptions(null));
    dispatch(setVideoMetaData(null));
    dispatch(updatePreviewOpen(false));
    dispatch(setWatermarkedUrl(null));
  };

  return (
    selectedPreview && (
      <mui.Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={previewOpen}
        fullWidth
      >
        <PreviewHeader asset={selectedPreview} handleClose={handleClose} />
        {videoJsOptions && !isImagePreview(selectedPreview) && (
          <Suspense fallback={<LoadingSpinner />}>
            <ProxyPlayer
              title={selectedPreview.title}
              videoJsOptions={videoJsOptions}
              frameRate={videoData?.frameRate || selectedPreview?.frameRate}
              audioTracks={videoData?.audioTracks}
            />
          </Suspense>
        )}
        {isImagePreview(selectedPreview) && imgSrc && <img alt="preview" src={imgSrc} />}
      </mui.Dialog>
    )
  );
}
