import React, { useEffect, useState } from 'react';
import { mui, icons } from 'tl-storybook';
import { classes } from './SearchInput.styles';

export default function SearchInput(props: { submitSearch: (newParam: string) => void; searchTerm: string }) {
  const { submitSearch, searchTerm } = props;
  const [localText, updateLocalText] = useState('');

  useEffect(() => {
    updateLocalText(searchTerm || '');
  }, [searchTerm]);

  const search = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      submitSearch((event.target as HTMLInputElement).value);
    }
  };

  const updateText = (event: React.ChangeEvent<any>) => {
    updateLocalText(event.target.value);
  };

  return (
    <mui.TextField
      id="search"
      sx={{ ...classes.root }}
      variant="outlined"
      placeholder="Search"
      data-automation-id="main-search-bar"
      value={localText}
      onChange={updateText}
      onKeyDown={search}
      InputProps={{
        startAdornment: (
          <mui.InputAdornment position="start">
            <icons.Search />
          </mui.InputAdornment>
        ),
        style: {
          height: '48px',
        },
      }}
    />
  );
}
