import React from 'react';
import { Box, icons } from 'tl-storybook';
import TableViews from '../../../consts/table-views';
import { updateConfigureDrawerOpen } from '../../../slices/ConfigureDrawerSlice';
import { useAppDispatch } from '../../../../interfaces/hooks';

import { classes } from './TableOptions.style';

interface TableOptionProps {
  updateTableView: (view: string) => void;
  hideCard?: boolean;
  hideList?: boolean;
  hideSettings?: boolean;
  hideViewList?: boolean;
  selected: string;
}

export default function TableOptions({
  updateTableView,
  hideCard,
  hideList,
  hideSettings,
  hideViewList,
  selected,
}: TableOptionProps) {
  const dispatch: any = useAppDispatch();

  return (
    <Box sx={{ ...classes.root }} id="table-config" data-testid="table-config">
      {!hideCard && (
        <Box title="Card View" className="icon-wrapper">
          <icons.Apps
            sx={{ ...(selected === TableViews.CARD && classes.selected) }}
            data-testid="card-view-select"
            onClick={() => updateTableView(TableViews.CARD)}
          />
        </Box>
      )}
      {!hideList && (
        <Box title="List View" className="icon-wrapper">
          <icons.List
            sx={{ ...(selected === TableViews.NORMAL && classes.selected) }}
            data-testid="list-view-select"
            onClick={() => updateTableView(TableViews.NORMAL)}
          />
        </Box>
      )}
      {!hideViewList && (
        <Box title="COMFORTABLE View" className="icon-wrapper">
          <icons.ViewList
            sx={{ ...(selected === TableViews.COMFORTABLE && classes.selected) }}
            data-testid="comfortable-view-select"
            onClick={() => updateTableView(TableViews.COMFORTABLE)}
          />
        </Box>
      )}
      {!hideSettings && (
        <Box title="Customize Layout" className="icon-wrapper">
          <icons.Settings onClick={() => dispatch(updateConfigureDrawerOpen(true))} data-testid="configure-columns-button" />
        </Box>
      )}
    </Box>
  );
}
