import React from 'react';
import { NbcuSnackbar } from 'tl-storybook';
import { updateErrorOpen } from '../../slices/ErrorSlice';
import { useAppSelector, useAppDispatch } from '../../../interfaces/hooks';

export default function ErrorSnackbar() {
  const dispatch: any = useAppDispatch();
  const { errorOpen, errorMessage } = useAppSelector((state) => state.error);

  const onCloseNbcuSnackbar = () => {
    dispatch(updateErrorOpen(false));
  };

  return (
    <NbcuSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={errorOpen}
      onClose={onCloseNbcuSnackbar}
      autoHideDuration={30000}
      message={`ERROR: ${errorMessage}`}
      data-testid="error-snackbar"
      sx={{ '& .MuiSnackbarContent-root': { minWidth: '300px' } }}
    />
  );
}
