import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { decoPrimary, bgcPrimary } from '../../../../../styles/base/colors';

interface DrawerSection {
  root: CSSObject;
}

export const classes: DrawerSection = {
  root: {
    position: 'absolute',
    top: '25px',
    left: '0',
    right: '0',
    bottom: '50px',
    backgroundColor: bgcPrimary,
    boxShadow: `${decoPrimary} 0 0 3px 3px`,
  },
};
