import React from 'react';
import { Box, mui } from 'tl-storybook';
import { BUTTON_STATUSES } from '../../../constants/button_statuses';
import { classes } from './CategorySelectorButtons.styles';

interface Props {
  buttonStatus: string;
  onClick: (type: string) => void;
}

function CategorySelectorButtons(props: Props) {
  const { buttonStatus, onClick } = props;

  return (
    <Box sx={{ ...classes.root }}>
      <mui.ButtonGroup>
        <mui.Button
          variant="outlined"
          className={buttonStatus === BUTTON_STATUSES.AZ ? 'active' : ''}
          onClick={() => onClick(BUTTON_STATUSES.AZ)}
          data-testid="category-selector-AZ"
        >
          A-Z
        </mui.Button>
        <mui.Button
          data-testid="categories-button"
          variant="outlined"
          className={buttonStatus === BUTTON_STATUSES.CAT ? 'active' : ''}
          onClick={() => onClick(BUTTON_STATUSES.CAT)}
        >
          Category
        </mui.Button>
      </mui.ButtonGroup>
    </Box>
  );
}

export default CategorySelectorButtons;
