import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { colors } from '../../../../../styles/base/colors';

interface MediatorStatusRendererStyles {
  progressWrapper: CSSObject;
  progressText: CSSObject;
}

export const classes: MediatorStatusRendererStyles = {
  progressWrapper: {
    color: colors.tealBlue,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
  },
  progressText: {
    position: 'absolute',
    fontSize: '11px',
  },
};
