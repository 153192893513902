import React from 'react';
import { Box, icons } from 'tl-storybook';
import { classes } from './DrawerTitle.styles';

interface Props {
  title: string;
  toggleDrawer: (open: boolean) => void;
}

export default function DrawerTitle(props: Props) {
  const { title, toggleDrawer } = props;

  return (
    <Box sx={{ ...classes.root }}>
      <Box>
        <h4 data-testid="properties-panel-title">{title}</h4>
      </Box>
      <Box>
        <icons.Close
          className="cursor-pointer"
          onClick={() => toggleDrawer(false)}
          data-testid="filter-drawer-close"
        />
      </Box>
    </Box>
  );
}
