import React from 'react';
import { mui } from 'tl-storybook';
import { useAppDispatch, useAppSelector } from '../../../../interfaces/hooks';
import { setAlert } from '../../../slices/ErrorSlice';

const AlertSnackbar = () => {
  const dispatch = useAppDispatch();
  const { alert } = useAppSelector((state) => state.error);

  const clearMessages = () => {
    dispatch(setAlert(null));
  };

  return (
    alert && (
      <mui.Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={10000}
        onClose={clearMessages}
        open={!!alert}
      >
        <mui.Alert onClose={clearMessages} severity={alert.severity}>
          {alert.severity === 'info' && <mui.AlertTitle>Please note</mui.AlertTitle>}
          {alert.message}
        </mui.Alert>
      </mui.Snackbar>
    )
  );
};

export default AlertSnackbar;
