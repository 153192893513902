import { Box } from 'tl-storybook';
import { formatMasterSubmaster } from '../../../helpers';

import { classes } from './MasterTypeRenderer.styles';

const MasterTypeRenderer = (props:any) => {
  const { data, colDef } = props;
  return (
    <Box
      sx={{
        ...classes.textWrapper,
        ...(colDef?.context === 'comfortable' ? { height: '100px' } : { height: '52px' }),
      }}
    >
      { formatMasterSubmaster(data?.pmam?.masterType) }
    </Box>

  );
};

export default MasterTypeRenderer;
