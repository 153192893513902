import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/app/store';
import { PmamAsset } from '../../interfaces/pmam-asset';
import { Asset } from '../../interfaces/asset';
import { LogEntry } from '../../interfaces/log';
import { API_ENDPOINTS } from '../consts/API_ENDPOINTS';

const PropertiesDrawerSlice = createSlice({
  name: 'propertiesDrawer',
  initialState: {
    propertiesDrawerOpen: false,
    selectedAsset: null as Asset,
    selectedPmamAsset: null,
    pmamAssetLoading: false,
    assetLogsLoading: false,
    selectedWorkOrder: null,
    selectedSupplyOrder: null,
  },
  reducers: {
    setPropertiesDrawerType(state, action) {
      state.propertiesDrawerOpen = action.payload;
    },
    setSelectedAsset(state, action) {
      state.selectedAsset = action.payload;
    },
    setSelectedPmamAsset(state, action) {
      state.selectedPmamAsset = action.payload;
    },
    setPmamAssetLoading(state, action) {
      state.pmamAssetLoading = action.payload;
    },
    setAssetLogsLoading(state, action) {
      state.assetLogsLoading = action.payload;
    },
    setSelectedWorkOrder(state, action) {
      state.selectedWorkOrder = action.payload;
    },
  },
});

const { actions, reducer } = PropertiesDrawerSlice;

export const {
  setPropertiesDrawerType,
  setSelectedAsset,
  setSelectedPmamAsset,
  setPmamAssetLoading,
  setAssetLogsLoading,
  setSelectedWorkOrder,
} = actions;

export const updateSelectedAsset = (selectedItem: Asset) => (dispatch: AppDispatch) => {
  if (selectedItem?.gtmId) {
    axios.get(`${API_ENDPOINTS.TITLE_VERSIONS_GTM_ID}/${selectedItem.gtmId}`)
      .then((resp: any) => {
        const version = resp?.data?.version?.find(
          (v: any) => v.unifiedID === selectedItem.gtmVersionUnifiedId,
        );
        const selectedItemCopy = { ...selectedItem };
        selectedItemCopy.versionName = version?.versionName || null;
        selectedItemCopy.versionType = version?.versionType || null;
        dispatch(setSelectedAsset(selectedItemCopy));
      }).catch((err) => {
        // TODO; show a snack bar with appropriate error message
        console.log(err);
      });
    return;
  }

  // must set these values to null when there is no data or interface will display [object:object]
  const selectedItemNew = { ...selectedItem };
  selectedItemNew.versionName = null;
  selectedItemNew.versionType = null;
  dispatch(setSelectedAsset(selectedItemNew));
};

export const getAssetLogs = (selectedItem: Asset) => () => axios
  .get<LogEntry[]>(
    `${API_ENDPOINTS.ACTIVITY_LOGS}?id=${selectedItem?.pmam?.assetId || selectedItem?.asset?.id}
    &materialId=${selectedItem?.materialId}
    &source=${selectedItem?.sourceSystem || 'PMAM'}`,
  );

export const getWorkorderLogs = (selectedItem: any) => () => axios
  .get<LogEntry[]>(
    `${API_ENDPOINTS.WORKORDER_LOGS}?id=${selectedItem?.id}
    &materialId=${selectedItem?.materialId}
    &source=${selectedItem?.sourceSystem || 'PMAM'}`,
  );

export const getPmamAssetById = (id: string) => (dispatch: AppDispatch) => axios
  .get<PmamAsset>(`${API_ENDPOINTS.PMAM_ASSET}/${id}`)
  .then((resp: any) => {
    dispatch(setSelectedPmamAsset(resp.data));
  }).catch((err) => err);

export default reducer;
