import {
  CSSObject,
} from 'tl-storybook';

interface BrandFilterStyles {
  root: CSSObject;
}

export const classes: BrandFilterStyles = {
  root: {
    width: '100%',
  },
};
