import React from 'react';
import { icons } from 'tl-storybook';
import { TableButtonProps } from '../../interfaces/table-button';
import {
  getCreateOrderErrorMsg,
  getEditOrderErrorMsg,
} from './button-functions';

export const COLLECTION_ASSETS_BUTTONS: TableButtonProps[] = [
  {
    text: 'order',
    icon: <icons.ShoppingCart />,
    isDisabledFunction: (user?: any, selectedOrders: any[] = []) => {
      const hasErrorMsg = getCreateOrderErrorMsg(user, selectedOrders);
      return selectedOrders.length === 0 || Boolean(hasErrorMsg);
    },
    getTooltipTitle: (user?: any, selectedOrders: any[] = []) => getCreateOrderErrorMsg(user, selectedOrders),
  },
  {
    text: 'edit',
    icon: <icons.Edit />,
    isDisabledFunction: (user?: any, selectedOrders: any[] = []) => {
      const hasErrorMsg = getEditOrderErrorMsg(user, selectedOrders);
      return selectedOrders.length === 0 || Boolean(hasErrorMsg);
    },
    getTooltipTitle: (user, selectedOrders = []) => getEditOrderErrorMsg(user, selectedOrders),
  },
  {
    text: 'remove from collection',
    icon: <icons.Queue />,
    isDisabledFunction: (user?: any, selectedOrders: any[] = []) => selectedOrders.length === 0,
    getTooltipTitle: () => '',
  },
  {
    text: 'register metadata',
    icon: <icons.Backup />,
    isDisabledFunction: () => false,
    getTooltipTitle: () => '',
  },
];
