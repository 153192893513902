import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { colors } from '../../../../styles/base/colors';

interface ModalStyles {
  root: CSSObject;
}

export const classes: ModalStyles = {
  root: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    zIndex: '10000',
    width: '100vw',
    height: '100vh',
    background: colors.grayMedium,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.modal-box': {
      background: colors.white,
      minHeight: '150px',
      padding: '10px',
      boxSizing: 'border-box',
      minWidth: '300px',
      position: 'relative',
      borderRadius: '5px',
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
      '.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'red !important',
        },
      },
      '.Mui-error:after': {
        borderColor: 'red !important',
      },
      h4: {
        fontSize: '14px',
        fontWeight: 'bold',
        marginTop: '2px',
      },
      '.button-container': {
        textAlign: 'right',
        position: 'absolute',
        width: '100%',
        right: 10,
        bottom: 10,
      },
      '.close-icon': {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
      },
    },
  },
};
