import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../../interfaces/hooks';
import { AppDispatch } from '../../../../../store';
import { getAppUser } from '../../../../slices/UserSlice';
import { getFeatureFlags } from '../../../../slices/FeatureFlagSlice';
import { FEATURE_FLAG_ENDPOINT_INTERVAL } from '../../../../consts/API_ENDPOINTS';

declare let pendo: any;

const AppUser = ():any => {
  const dispatch: AppDispatch = useAppDispatch();

  const location = useLocation();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!location.pathname.includes('login') && !loaded) {
      dispatch(getFeatureFlags());

      setInterval(() => {
        dispatch(getFeatureFlags());
      }, FEATURE_FLAG_ENDPOINT_INTERVAL);

      dispatch(getAppUser()).then((user: any) => {
        setLoaded(true);
        pendo.initialize({
          visitor: {
            id: user?.sso, // Required, default creates anonymous ID
            business_segment: user?.businessSegment,
            sub_business_segment: user?.subBusinessSegment,
            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },
          account: {
            id: 'PMAM-MSC-EXTERNAL', // Required, default uses the value 'ACCOUNT-UNIQUE-ID'
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional
            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });
      });
    }
  }, [location.pathname]);

  return null;
};

export default AppUser;
