import {
  CSSObject,
} from 'tl-storybook';
import {
  colors,
  primary,
  secondary,
  bgcPrimary,
  disabled,
  shadePrimary,
  decoTertiary,
  fontPrimary,
  bgcTertiary,
  buttonPlainHover,
} from '../../../../styles/base/colors';

interface PropertiesDrawerStyles {
  root: CSSObject;
}

export const classes: PropertiesDrawerStyles = {
  root: {
    '.MuiButton-root, .MuiButton-label': {
      fontFamily: '"Roboto classic", sans-serif !important',
    },
    'button.MuiButton-contained': {
      '&:hover': {
        backgroundColor: `${bgcTertiary} !important`,
      },
      '&:focus': {
        backgroundColor: `${bgcTertiary} !important`,
        border: `1px solid ${primary} !important`,
      },
      '&:disabled': {
        backgroundColor: `${disabled} !important`,
      },
    },
    'button.MuiButton-text': {
      '&:hover': {
        backgroundColor: `${buttonPlainHover} !important`,
      },
      '&:focus': {
        backgroundColor: `${buttonPlainHover} !important`,
        border: `1px solid ${primary} !important`,
      },
      '&:disabled': {
        backgroundColor: `${disabled} !important`,
      },
    },
    '.MuiButton-root:hover': {
      backgroundColor: `${colors.tealBlueBackground} !important`,
    },
    'button.MuiButtonGroup-root': {
      '.MuiButton-root': {
        textTransform: 'none !important',
        fontWeight: 'normal !important',
        '&.active': {
          backgroundColor: bgcTertiary,
          borderColor: primary,
          color: secondary,
        },
      },
    },
    '.MuiTabs-indicator': {
      backgroundColor: colors.tealBlue,
    },
    '.MuiTab-root': {
      color: `${shadePrimary} !important`,
      borderBottom: `2px solid ${shadePrimary} !important`,
      minWidth: '0',
    },
    '.loading-overlay': {
      position: 'absolute',
      top: '0',
      bottom: '0',
      right: '0',
      left: '0',
      zIndex: '1',
      opacity: '0.5',
      backgroundColor: bgcPrimary,
      textAlign: 'center',
      paddingTop: '300px',
    },
    '.top-chips': {
      padding: '20px',
      '.top-chip': {
        margin: '0 5px',
        color: fontPrimary,
        border: 'none',
        backgroundColor: decoTertiary,
        '.MuiChip-label': {
          fontFamily: '"Roboto classic", sans-serif',
        },
        '&.icon-chip': {
          borderRadius: '50%',
          width: '32px',
          height: '32px',
          '.MuiChip-icon': {
            marginLeft: '3px',
            marginRight: '-20px',
            color: colors.black,
          },
        },
        '&.red-chip': {
          backgroundColor: 'rgba(239, 21, 64, 0.25)',
          borderRadius: '0',
        },
      },
    },
    '.properties-drawer-form-field': {
      marginBottom: '10px',
      width: '100%',
      '.MuiFormControl-root.MuiTextField-root': {
        width: '100%',
      },
      '&:last-of-type': {
        marginBottom: '0',
      },
      '.MuiInputBase-root.Mui-disabled': {
        color: fontPrimary,
        '&::before': {
          display: 'none',
        },
      },
    },
    '.asset-title': {
      padding: '20px',
      '.asset-id': {
        padding: '10px 0',
      },
      '.MuiButton-outlined': {
        padding: '5px',
      },
      '.MuiButton-root': {
        minWidth: '0',
        width: '36px',
        margin: '0 5px',
      },
      h2: {
        maxWidth: '95%',
        wordBreak: 'break-word',
      },
    },
    '.properties-tab, .order-history-tab': {
      backgroundColor: colors.grayLight,
      padding: '25px 16px',
      height: '100%',
      width: 'calc(100% - 32px)',
      overflowY: 'scroll',
      '.bordered-section, .work-order': {
        borderBottom: `1px solid ${colors.grayMedium}`,
      },
      '.work-order:last-of-type': {
        borderBottom: 'none',
      },
      '.bordered-section, .section, .work-order': {
        marginBottom: '16px',
        paddingBottom: '16px',
      },
    },
  },
};
