import {
  ArrayParam, NumberParam, QueryParamConfig, StringParam,
} from 'use-query-params';

// This must be kept up to date with any new fields. It's too much data to calculate in the client on the fly.
// Small sacrifice to save a lot of code
export const ASSETS_QUERY_PARAMETERS: { [key: string]: QueryParamConfig<any, any> } = {
  'pmam.airtableId-keywordContains': StringParam,
  'pmam.airtableStatus-exact': StringParam,
  'pmam.project-keywordContains': StringParam,
  'pmam.copyrightOwner-exact': StringParam,
  'episodeNo-exact': StringParam,
  'episodeTitle-exact': StringParam,
  'featureTitle-exact': StringParam,
  'gtmVersionUnifiedId-exact': StringParam,
  'title-exact': StringParam,
  'rating-exact': StringParam,
  'seasonNo-exact': StringParam,
  'seasonTitle-keywordContains': StringParam,
  'seriesTitle-exact': StringParam,
  definition: ArrayParam,
  scanType: ArrayParam,
  'pmam.acquisitionSource': ArrayParam,
  'pmam.airtableDueDate-after': StringParam,
  'pmam.airtableDueDate-before': StringParam,
  aspectRatio: ArrayParam,
  'pmam.assetType': ArrayParam,
  'pmam.audioBitDepth': StringParam,
  'pmam.audioBitRate': StringParam,
  'audioChannels-exact': NumberParam,
  'pmam.audioProfile': ArrayParam,
  brand: ArrayParam,
  'pmam.colorSpace-keywordContains': StringParam,
  'pmam.contentType': ArrayParam,
  'assetCreatedAt-after': StringParam,
  'assetCreatedAt-before': StringParam,
  'pmam.createdBy-keywordContains': StringParam,
  'pmam.deactivated': StringParam,
  duration: StringParam,
  'pmam.assetId': StringParam,
  'pmam.fileExtension': ArrayParam,
  'pmam.fileName-keywordContains': ArrayParam,
  'pmam.fileSize': StringParam,
  'pmam.fileTitle-keywordContains': StringParam,
  'pmam.fps-keywordContains': StringParam,
  'frameRate-keywordContains': StringParam,
  'fullTextSearch-keywordContains': StringParam,
  'pmam.houseNumber-keywordContains': StringParam,
  'categorySearchAll-keywordContains': ArrayParam,
  'categorySearchTitle-keywordContains': ArrayParam,
  'categorySearchId-keywordContains': ArrayParam,
  // 'fileName-keywordContains': ArrayParam,
  id: StringParam,
  primaryLanguage: ArrayParam,
  'pmam.language': ArrayParam,
  'pmam.markedForDelete': StringParam,
  'pmam.markedForDigitization': StringParam,
  'pmam.maskType': ArrayParam,
  'pmam.masterType': ArrayParam,
  'materialId-keywordContains': StringParam,
  materialType: ArrayParam,
  mediaType: ArrayParam,
  mediatorProgress: ArrayParam,
  'pmam.merlinDeliveryStatus': ArrayParam,
  mediatorProgressPercentage: StringParam,
  'pmam.mobId-keywordContains': StringParam,
  'originalAirDate-after': StringParam,
  'originalAirDate-before': StringParam,
  originalAirNetwork: ArrayParam,
  'pmam.parentPath-keywordContains': StringParam,
  'pmam.physical': StringParam,
  'pmam.premierDate-after': StringParam,
  'pmam.premierDate-before': StringParam,
  'productionNumber-keywordContains': StringParam,
  productionType: ArrayParam,
  'pmam.promDeliverableNumber': StringParam,
  'pmam.promDueDate-after': StringParam,
  'pmam.promDueDate-before': StringParam,
  'pmam.promPromoCode-keywordContains': StringParam,
  proxyAvailable: StringParam,
  'pmam.referenceId-keywordContains': StringParam,
  'pmam.restrictedStatus': ArrayParam,
  gtmId: NumberParam,
  'pmam.mlaiStatus': ArrayParam,
  'pmam.replacementInProgress': StringParam,
  'pmam.samplingRate': StringParam,
  'pmam.shootDate-after': StringParam,
  'pmam.shootDate-before': StringParam,
  sourceSystem: ArrayParam,
  storageSystem: ArrayParam,
  'pmam.tape-keywordContains': StringParam,
  textVersion: ArrayParam,
  totalRunTime: StringParam,
  'pmam.updatedBy-keywordContains': StringParam,
  'pmam.uploadDate-after': StringParam,
  'pmam.uploadDate-before': StringParam,
  'pmam.uploadId-keywordContains': StringParam,
  'pmam.uploadProgressName': ArrayParam,
  'versionName-keywordContains': StringParam,
  'pmam.versionType': ArrayParam,
  'pmam.videoBitDepth': StringParam,
  'pmam.videoBitRate': StringParam,
  'pmam.videoScanType-keywordContains': StringParam,
  'pmam.wrapper-keywordContains': StringParam,
  'pmam.svApprovalStatus': ArrayParam,
  page: NumberParam,
  limit: NumberParam,
  sort: StringParam,
};
