import { CSSObject } from 'tl-storybook';
import { colors } from '../../../../../styles/base/colors';

interface HeaderStyles {
  root: CSSObject;
}

export const classes: HeaderStyles = {
  root: {
    height: '56px',
    position: 'absolute',
    top: '0',
    right: '0',
    left: '0',
    backgroundColor: colors.purple,
    color: colors.white,
    display: 'flex',
    justifyContent: 'space-between',

    '.header-title': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: '20px',
      height: '100%',

      '#logo': {
        height: '20px',
      },

      span: {
        lineHeight: '0',
        marginLeft: '10px',
        fontSize: '25px',
        paddingTop: '7px',
      },
    },

    '.view-options': {
      width: '400px',
    },
  },
};
