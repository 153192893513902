import {
  CSSObject,
} from 'tl-storybook';

  interface ApprovalStatusRendererStyles {
    statusWrapper: CSSObject;
    statusIcon: CSSObject;
  }

export const classes: ApprovalStatusRendererStyles = {
  statusWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: ' 0 0 0 10px',
  },
  statusIcon: {
    marginRight: '8px',
  },

};
