import { createSlice } from '@reduxjs/toolkit';

enum Severity {
  error = 'error',
  success = 'success',
  info = 'info',
  warning = 'warning',
}
interface Alert {
  severity: Severity;
  message: string;
}

const ErrorSlice = createSlice({
  name: 'error',
  initialState: {
    errorOpen: false,
    errorMessage: '',
    alert: <Alert> null,
  },
  reducers: {
    updateErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    updateErrorOpen(state, action) {
      state.errorOpen = action.payload;
    },
    setAlert(state, action) {
      state.alert = action.payload;
    },
  },
});

const { actions, reducer } = ErrorSlice;

export const {
  updateErrorMessage,
  updateErrorOpen,
  setAlert,
} = actions;

export default reducer;
