import React from 'react';
import { Box } from 'tl-storybook';
import { classes } from './BooleanRenderer.styles';

const BooleanRenderer = (props:any) => {
  const { data, colDef } = props;
  const path = colDef.field.split('.');
  return (
    <Box
      sx={{
        ...classes.booleanWrapper,
        ...(colDef?.context === 'comfortable' ? { height: '100px' } : { height: '52px' }),
      }}
    >
      {path.length === 1 && (data[path[0]]?.toString() ? data[path[0]].toString() : '')}
      {path.length === 2 && (data[path[0]]?.[path[1]]?.toString() ? data[path[0]][path[1]].toString() : '')}
    </Box>
  );
};

export default BooleanRenderer;
