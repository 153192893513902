import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppGetState, AppDispatch } from '../../../../../store';
import { API_ENDPOINTS } from '../../../../../core/consts/API_ENDPOINTS';
import { updateFormState, setError } from '../../EditAssetsSlice';
import {
  setGtmVersionNameOptions,
  setGtmVersionTypeOptions,
  setGtmVersionNameInput,
  setGtmVersionTypeInput,
} from '../../SecondaryDataForm/SecondaryDataFormSlice';
import { setLoadingSpinner } from '../../../../../core/slices/UserFeedBackSlice';
import { getTitleVersion } from './utils';

const LinkToFormSlice = createSlice({
  name: 'Link-to-form',
  initialState: {
    gtmIds: {
      gtmMdvUnifiedId: '',
      compassTitleNumber: '',
      tmsId: '',
    },
    gtmTitles: {
      featureTitle: '',
      featureTitleInput: '',
      seriesTitle: '',
      seriesTitleInput: '',
      episodeTitle: '',
      episodeTitleInput: '',
    },
    gtmNumbers: {
      seasonNo: '',
      seasonNoInput: '',
      episodeNo: '',
      episodeNoInput: '',
    },
    gtmUnifiedVersionIds: null,
    gtmUnifiedIds: null,
    featureTitleSuggestions: [],
    seriesTitleSuggestions: [],
    seasonNumberSuggestions: [],
    episodeTitleSuggestions: [],
    episodeNumberSuggestions: [],
    languageCodes: [],
  },
  reducers: {
    setGtmIds(state, action) {
      state.gtmIds = { ...state.gtmIds, ...action.payload };
    },
    setGtmTitles(state, action) {
      state.gtmTitles = { ...state.gtmTitles, ...action.payload };
    },
    setGtmNumbers(state, action) {
      state.gtmNumbers = { ...state.gtmNumbers, ...action.payload };
    },
    setGtmUnifiedVersionIdOptions(state, action) {
      state.gtmUnifiedVersionIds = action.payload;
    },
    setGtmUnifiedIdOptions(state, action) {
      state.gtmUnifiedIds = action.payload;
    },
    setFeatureTitleSuggestions(state, action) {
      state.featureTitleSuggestions = action.payload;
    },
    setEpisodeTitleSuggestions(state, action) {
      state.episodeTitleSuggestions = action.payload;
    },
    setSeriesTitleSuggestions(state, action) {
      state.seriesTitleSuggestions = action.payload;
    },
    setSeasonNumberSuggestions(state, action) {
      state.seasonNumberSuggestions = action.payload;
    },
    setEpisodeNumberSuggestions(state, action) {
      state.episodeNumberSuggestions = action.payload;
    },
    setLanguageCodes(state, action) {
      state.languageCodes = action.payload;
    },
    clearLinkToSuggestions(state) {
      state.featureTitleSuggestions = [];
      state.seriesTitleSuggestions = [];
      state.seasonNumberSuggestions = [];
      state.episodeTitleSuggestions = [];
      state.episodeNumberSuggestions = [];
    },
  },
});

const { actions, reducer } = LinkToFormSlice;

export const {
  setGtmIds,
  setGtmTitles,
  setGtmNumbers,
  setGtmUnifiedVersionIdOptions,
  setGtmUnifiedIdOptions,
  setFeatureTitleSuggestions,
  setSeriesTitleSuggestions,
  setSeasonNumberSuggestions,
  setEpisodeTitleSuggestions,
  setEpisodeNumberSuggestions,
  setLanguageCodes,
  clearLinkToSuggestions,
} = actions;

export const getTitleWithGtmId = (id: string) => axios.get(`${API_ENDPOINTS.TITLE_GTM_ID}/${id}`);
export const getTitleVersionsGtmId = (id: string) => axios.get(`${API_ENDPOINTS.TITLE_VERSIONS_GTM_ID}/${id}`);

export const getLinkToDataWithGtmId = (id: string) => (
  dispatch: AppDispatch,
  getState: AppGetState,
) => getTitleWithGtmId(id)
  .then((resp: any) => {
    const state = getState();
    const { data } = resp;
    if (!data) {
      dispatch(setError({ gtmId: 'Not a valid GTM ID.' }));
      dispatch(setLoadingSpinner(false));
      return;
    }
    const titleVersion = getTitleVersion(data);
    // TODO: integrate the gtm level apis from PMAM1.0 to check if the gtmId is associated to a gtmLevel
    const formVersion = state.editAsset.updatedFormState?.linkTo || state.editAsset.formState.linkTo;
    if (titleVersion.toLowerCase() !== formVersion.toLowerCase()) {
      dispatch(setError({ gtmId: `This is not a "${formVersion}" GTM ID.` }));
      dispatch(setLoadingSpinner(false));
      return;
    }
    dispatch(setError({ gtmId: '' }));

    const { tmsId } = data;
    // When user clears Gtm Id all ids get cleared...
    // Always update the form with Gtm Id data unless the user explicitly clears this field...
    // We may want a message letting the user know they must clear the TmsId field..
    if (tmsId) {
      dispatch(setGtmIds({ tmsId }));
      dispatch(updateFormState({ tmsId }));
    }
    const title = data.titleName;
    const seriesGtmId = data?.seriesTitleID;
    const unifiedVersionIdOptions = data.version;
    dispatch(setGtmUnifiedVersionIdOptions(unifiedVersionIdOptions));
    switch (titleVersion) {
      case 'FEATURE':
        dispatch(setGtmTitles({
          featureTitle: title,
          featureTitleInput: title,
        }));
        dispatch(updateFormState({ featureTitle: title }));
        dispatch(setLoadingSpinner(false));
        break;
      case 'EPISODE': {
        const episodeNumber = data?.seasonEpisodeNumber || '';
        dispatch(setGtmTitles({
          episodeTitle: title,
          episodeTitleInput: title,
        }));
        dispatch(setGtmNumbers({
          episodeNo: episodeNumber,
          episodeNoInput: episodeNumber,
        }));
        dispatch(updateFormState({ episodeTitle: title }));
        dispatch(updateFormState({ episodeNo: episodeNumber }));

        const { parentTitleID: seasonId } = data;
        // TODO: convert the following requests to an async function
        getTitleWithGtmId(seasonId).then((res) => {
          const seriesTitleId = res.data.seriesTitleID;
          const seasonNo = res.data.seasonEpisodeNumber;
          dispatch(setGtmNumbers({
            seasonNo,
            seasonNoInput: seasonNo,
          }));
          dispatch(updateFormState({ seasonNo }));
          getTitleWithGtmId(seriesTitleId).then((response) => {
            const seriesTitle = response.data.titleName;
            dispatch(setGtmTitles({
              seriesTitle,
              seriesTitleInput: seriesTitle,
            }));
            dispatch(updateFormState({ seriesTitle }));
          }).finally(() => dispatch(setLoadingSpinner(false)));
        });
        break;
      }
      case 'SERIES':
        dispatch(setGtmTitles({
          seriesTitle: title,
          seriesTitleInput: title,
        }));
        dispatch(updateFormState({ seriesTitle: title }));
        dispatch(setLoadingSpinner(false));
        break;
      case 'SEASON': {
        const seasonNumber = data?.seasonEpisodeNumber || '';
        getTitleWithGtmId(seriesGtmId).then((res) => {
          dispatch(setGtmTitles({
            seriesTitle: res.data.titleName,
            seriesTitleInput: res.data.titleName,
          }));
          dispatch(updateFormState({ seriesTitle: res.data.titleName }));
          dispatch(setGtmNumbers({
            seasonNo: seasonNumber,
            seasonNoInput: seasonNumber,
          }));
          dispatch(updateFormState({ seasonNo: seasonNumber }));
        }).finally(() => dispatch(setLoadingSpinner(false)));
        break;
      }
      default:
        // do nothing
    }
  }).catch((err) => err);
export const getUnifiedVersionOptionsGtmId = (id: string, unifiedId: string) => (dispatch: AppDispatch) => {
  getTitleVersionsGtmId(id)
    .then((resp: any) => {
      const unifiedVersionIdOptions = resp.data.version;
      const gtmVersionNameOptions = unifiedVersionIdOptions.map((v: any) => v.versionName);
      const gtmVersionTypeOptions = unifiedVersionIdOptions.map((v: any) => v.versionType);
      dispatch(setGtmVersionNameOptions(gtmVersionNameOptions));
      dispatch(setGtmVersionTypeOptions(gtmVersionTypeOptions));
      if (unifiedId) {
        const unifiedVersionOption = unifiedVersionIdOptions.find((option: any) => option.unifiedID === unifiedId);
        dispatch(setGtmVersionNameInput(unifiedVersionOption?.versionName || ''));
        dispatch(setGtmVersionTypeInput(unifiedVersionOption?.versionType || ''));
      }
      dispatch(setGtmUnifiedVersionIdOptions(unifiedVersionIdOptions));
    }).catch((err) => err);
};

export const getVariantsWithGtmUnifiedId = (id: string) => (dispatch: AppDispatch, getState: AppGetState) => axios
  .get(`${API_ENDPOINTS.VARIANTS_GTM_UNIFIED_ID}/${id}`)
  .then((resp: any) => {
    const state = getState();
    let variants = resp.data;
    if (variants.length === 0) variants = null;
    if (variants?.length > 0) {
      variants = variants.map((v: any) => {
        const found = state.linkToForm.languageCodes.find((l) => l.gtmCode === v.languageISO);
        v.languageISO = found.name;
        return v;
      });
    }
    dispatch(setGtmUnifiedIdOptions(variants));
  }).catch((err) => err);

export const getFeatureTitleSuggestions = (
  searchKey: string,
  limit: number,
) => (dispatch: AppDispatch) => axios
  .get(`${API_ENDPOINTS.SUGGEST_FEATURE_TITLES}?title=${searchKey || ''}&limit=${limit || 100}`)
  .then((response: any) => {
    // NOTE: The AutoComplete component expects a label property
    const featureTitleSuggestions = response.data.map((d:any) => {
      const suggestion = { ...d, label: d.title };
      return suggestion;
    });

    dispatch(setFeatureTitleSuggestions(featureTitleSuggestions));
    return response.data;
  }).catch((err) => err);

export const getSeriesTitleSuggestions = (
  searchKey: string,
  limit: number,
) => (dispatch: AppDispatch) => axios
  .get(`${API_ENDPOINTS.SUGGEST_SERIES_TITLES}?title=${searchKey || ''}&limit=${limit || 100}`)
  .then((response: any) => {
    // NOTE: The AutoComplete component expects a label property
    const seriesTitleSuggestions = response.data.map((d:any) => {
      const suggestion = { ...d, label: d.title };
      return suggestion;
    });
    dispatch(setSeriesTitleSuggestions(seriesTitleSuggestions));
    return response.data;
  }).catch((err) => err);

export const getSeasonNumberSuggestions = (
  seriesId: number,
  searchKey: string,
  limit: number,
) => (dispatch: AppDispatch) => axios
  .get(`${API_ENDPOINTS.SUGGEST_SEASON_NUMBERS}?seriesId=${seriesId || null}&title=${searchKey || ''}&limit=${limit || 100}`)
  .then((response: any) => {
    // NOTE: The AutoComplete component expects a label property
    const seasonNumberSuggestions = response.data.map((d:any) => {
      const suggestion = { ...d, label: d.title };
      return suggestion;
    });
    dispatch(setSeasonNumberSuggestions(seasonNumberSuggestions));
    return response.data;
  }).catch((err) => err);

export const getEpisodeTitleSuggestions = (
  seriesId: number,
  seasonId: number,
  searchKey: string,
  episodeNumber: string,
  limit: number,
) => (dispatch: AppDispatch) => axios
  .get(`${API_ENDPOINTS.SUGGEST_EPISODE_TITLES}?seriesId=${seriesId || null}&seasonId=${seasonId || null}&title=${searchKey || ''}&episodeNumber=${episodeNumber || ''}&limit=${limit || 100}`)
  .then((response: any) => {
    // NOTE: The AutoComplete component expects a label property
    const episodeTitleSuggestions = response.data.map((d:any) => {
      const suggestion = { ...d, label: d.title };
      return suggestion;
    });
    const episodeNumberSuggestions = response.data.map((d:any) => {
      const suggestion = { ...d, label: d.episodeNumber };
      return suggestion;
    });
    dispatch(setEpisodeTitleSuggestions(episodeTitleSuggestions));
    dispatch(setEpisodeNumberSuggestions(episodeNumberSuggestions));
    return response.data;
  }).catch((err) => err);

export const getLanguageCodes = () => (dispatch: AppDispatch) => axios.get(API_ENDPOINTS.LANGUAGE_CODES)
  .then((res) => {
    dispatch(setLanguageCodes(res.data));
  });

export default reducer;
