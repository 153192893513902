import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box } from 'tl-storybook';
import { addAudioTracksToAsset } from '../../../utils-tsx';

import { classes } from './AudioDetailsRenderer.styles';

export default function AudioDetailsRenderer(props:any) {
  const { data, colDef } = props;

  const [audioDetails, setAudioDetails] = useState([]);

  useEffect(() => {
    if (Array.isArray(data.audioDetails)) {
      const audioTracks = data.audioDetails.slice(0, 5);
      setAudioDetails(audioTracks);
      return;
    }
    const colData = addAudioTracksToAsset(data);
    const audioTracks = colData.audioDetails.slice(0, 5);
    setAudioDetails(audioTracks);
  }, []);

  return (
    <Box
      sx={{
        ...classes.root,
        ...(colDef?.context === 'comfortable' ? { height: '100px', padding: '0 0 0 10px' } : { height: '52px', padding: '0 0 0 10px' }),
        ...(audioDetails.length > 2 && { height: 'auto', padding: '2px 0 2px 10px' }),
      }}
      data-testid="audio-details-renderer"
    >
      {Array.isArray(audioDetails) && audioDetails.map((t:any, i:number) => {
        if (i === 4) {
          return (
            <Box key={`${t + i}`} className="audio-track">
              <Link to={`/assets/detail/${data.id}`}>see more</Link>
            </Box>
          );
        }
        return (<Box key={`${t + i}`} className="audio-track">{t}</Box>);
      })}
    </Box>
  );
}
