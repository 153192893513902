import {
  CSSObject,
} from 'tl-storybook';

  interface MediaTypeFilterStyles {
    root: CSSObject;
  }

export const classes: MediaTypeFilterStyles = {
  root: {
    '.lookup-search': {
      paddingBottom: '12px',
    },
  },
};
