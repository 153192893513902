import { useAppSelector } from '../interfaces/hooks';

const FeatureFlagsLanding = () => {
  const { featureFlags } = useAppSelector<any>((state) => state.featureFlags);

  return (
    <div style={{ margin: 20 }}>
      {Object.keys(featureFlags).map((key: string) => (
        <p>
          <strong>{key}</strong>: {featureFlags[key] ? 'ON' : 'OFF'}
        </p>
      ))}
    </div>
  );
};

export default FeatureFlagsLanding;
