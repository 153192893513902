import React from 'react';
import {
  Box, mui,
} from 'tl-storybook';

import { classes } from './LoadingSpinner.styles';

const LoadingMessage = (props: any) => {
  const {
    top = '0', bottom = '0', height, size = '40px',
  } = props;

  return (
    <Box
      sx={{
        ...classes.root,
        top,
        bottom,
        height,
      }}
      data-testid="loading-spinner"
    >
      <mui.CircularProgress size={size} />
    </Box>
  );
};

export default LoadingMessage;
