import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import {
  colors, primary, secondary, bgcPrimary, bgcTertiary, shadePrimary, disabled, buttonPlainHover,
} from '../../../../styles/base/colors';

interface FilterDrawerStyles {
  root: CSSObject;
}

export const classes: FilterDrawerStyles = {
  root: {
    flexShrink: 0,
    position: 'fixed',
    top: '56px',
    left: '73px',
    width: '440px',
    height: 'calc(100vh - 56px)',
    '.MuiButton-root, .MuiButton-label': {
      fontFamily: '"Roboto classic", sans-serif !important',
    },
    'button.MuiButton-contained': {
      '&:hover': {
        backgroundColor: `${bgcTertiary} !important`,
      },
      '&:focus': {
        backgroundColor: `${bgcTertiary} !important`,
        border: `1px solid ${primary} !important`,
      },
      '&:disabled': {
        backgroundColor: `${disabled} !important`,
      },
    },
    'button.MuiButton-text': {
      '&:hover': {
        backgroundColor: `${buttonPlainHover} !important`,
      },
      '&:focus': {
        backgroundColor: `${buttonPlainHover} !important`,
        border: `1px solid ${primary} !important`,
      },
      '&:disabled': {
        backgroundColor: `${disabled} !important`,
      },
    },
    '.MuiButton-root:hover': {
      backgroundColor: `${colors.tealBlueBackground} !important`,
    },
    '.MuiButtonGroup-root': {
      '.MuiButton-root': {
        textTransform: 'none !important',
        fontWeight: 'normal !important',
        '&.active': {
          backgroundColor: bgcTertiary,
          borderColor: primary,
          color: secondary,
        },
      },
    },
    '.MuiTabs-indicator': {
      backgroundColor: colors.tealBlue,
    },
    '.MuiTab-root': {
      color: `${shadePrimary} !important`,
      borderBottom: `2px solid ${shadePrimary} !important`,
      minWidth: '0',
    },
    '.tab-view': {
      '.loading-overlay': {
        position: 'absolute',
        top: '0',
        bottom: '0',
        right: '0',
        left: '0',
        zIndex: '1',
        opacity: '0.5',
        backgroundColor: bgcPrimary,
        textAlign: 'center',
        paddingTop: '300px',
      },
    },
    '.brand-selector-container': {
      padding: '16px 24px 0',
    },
    '.media-type-container': {
      borderBottom: `1px solid ${colors.grayMedium}`,
      padding: '16px 24px',
    },
    '.filter-apply-clear-container': {
      display: 'flex',
      justifyContent: 'end',
      padding: '16px 24px',
      '.filter-clear-all': {
        color: colors.black,
      },
    },
    '.drawer-top': {
      padding: '0 24px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '.filter-search-input': {
        width: 'auto',
      },
    },
    '.drawer-container': {
      position: 'absolute',
      top: '381.5px',
      right: '0',
      left: '0',
      bottom: '0',
    },
  },
};
