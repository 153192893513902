import { createSlice } from '@reduxjs/toolkit';

const PropertiesDrawerSlice = createSlice({
  name: 'configureDrawer',
  initialState: {
    configureDrawerOpen: false,
    selectedColumns: [],
  },
  reducers: {
    updateConfigureDrawerOpen(state, action) {
      state.configureDrawerOpen = action.payload;
    },
    updateSelectedColumns(state, action) {
      state.selectedColumns = action.payload;
    },
  },
});

const { actions, reducer } = PropertiesDrawerSlice;

export const {
  updateConfigureDrawerOpen,
  updateSelectedColumns,
} = actions;

export default reducer;
