import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import { decoTertiary } from '../../../../../styles/base/colors';

interface DrawerTitleStyles {
  root: CSSObject;
}

export const classes: DrawerTitleStyles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 14px',
    minHeight: '56px',
    backgroundColor: decoTertiary,
  },
};
