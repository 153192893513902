import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AssetsLanding from '../../../assets/components/AssetsLanding';
import { assetsModule } from '../../../assets';
import { collectionsModule } from '../../../collections';
import { loginModule } from '../../../login';
import { playlistsModule } from '../../../playlists';
import { workOrdersModule } from '../../../work-orders';
import { studioSupplyOrdersModule } from '../../../studio-supply-orders';
import FeatureFlagsLanding from '../../../feature-flags';

import AppUser from './appData/AppUser';
import AppOptions from './appData/AppOptions';
import LoadingSpinner from './LoadingSpinner';

export default function AppRouter() {
  // TODO: refactor the following code into a useEffect and useState hook...
  const getDefaultLandingRoute = () => {
    const redirectAfterLoginUrl = sessionStorage.getItem('redirectAfterLogin');
    let defaultPathToLoad = `/assets${sessionStorage.assetsSearchParams || ''}`;
    if (redirectAfterLoginUrl) {
      defaultPathToLoad = redirectAfterLoginUrl;
    }
    return defaultPathToLoad;
  };
  const landingRoute = getDefaultLandingRoute();
  return (
    <Suspense fallback={<LoadingSpinner size="100px" />}>
      <AppUser />
      <AppOptions />
      <Routes>
        <Route path="/" element={<Navigate to={landingRoute} />} />
        <Route path="/assets" element={<AssetsLanding />} />
        <Route path="/feature-flags" element={<FeatureFlagsLanding />} />
        {loginModule.routes}
        {assetsModule.routes}
        {collectionsModule.routes}
        {playlistsModule.routes}
        {workOrdersModule.routes}
        {studioSupplyOrdersModule.routes}
        {/* This last route catches all routes that don't resolve to a defined route in the app */}
        {/* Send all non defined traffic back to the root "/" which will load /assets */}
        {/* TODO: add a 404 not found page instead of rerouting to the root route */}
        {/* NOTE: The following code should fix the e2e tests... */}
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
}
