export const parseSearchInput = (inputString: string, inputType: string) => {
  let parsedInputs: any = [];
  // puts strings into array and removes white space and empty strings
  const values = inputString?.split(',').map((v: string) => v.trim()).filter((e) => e);

  switch (inputType.toLowerCase()) {
    case 'ids': {
      const idPrefixes = ['PM_', 'GMO_', 'GTMV', 'TLLBB', 'TLM_', 'T0', 'GT', 'GM'];
      const idPattern = new RegExp(`^(${idPrefixes.join('|')})[A-Za-z0-9_]+$`);
      const numberPattern = /^\d+$/;
      const alphanumericPattern = /^[A-Za-z0-9]+$/;

      values?.forEach((value) => {
        if (
          idPattern.test(value)
          || numberPattern.test(value)
          || alphanumericPattern.test(value)
        ) {
          parsedInputs.push(value);
        }
      });
      break;
    }
    default:
      // Allows users to search for commas in titles etc.
      if (inputString.trim() === ',') {
        parsedInputs = inputString.trim();
      } else {
        parsedInputs = values;
      }
  }
  return parsedInputs;
};
