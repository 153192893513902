import React from 'react';
import { icons } from 'tl-storybook';
import { TableButtonProps, TableMenuProps } from '../../interfaces/table-button';

// All functions are placeholders
export const COLLECTIONS_TABLE_BUTTONS: TableButtonProps[] = [
  {
    text: 'new',
    icon: <icons.Add />,
    isDisabledFunction: () => false,
    getTooltipTitle: () => '',
  },
  {
    text: 'share',
    icon: <icons.Share />,
    isDisabledFunction: () => false,
    getTooltipTitle: () => '',
  },
];

export const COLLECTIONS_TABLE_MENU_ACTIONS: TableMenuProps[] = [
  {
    text: 'rename',
    label: 'Edit Collection',
    isDisabledFunction: (user: any, row: any) => {
      if (row.length > 1) {
        return true;
      }

      let canRenameCollection = user?.policies?.collections?.update.some((c: any) => c.code === row[0]?.brand);
      if (row[0]?.metadata?.brands) {
        const collectionBrands = row[0].metadata.brands.split(',').map((profile: string) => profile.trim().toUpperCase());
        canRenameCollection = user?.policies?.collections?.update.some(
          (c: any) => collectionBrands.includes(c.code.toUpperCase()),
        );
      }

      if (!canRenameCollection) {
        return true;
      }
      return false;
    },
    getTooltipTitle: (user: any, row: any) => {
      if (row.length > 1) {
        return 'You can only rename one collection at a time.';
      }

      let canRenameCollection = user?.policies?.collections?.update.some((c: any) => c.code === row[0]?.brand);
      if (row[0]?.metadata?.brands) {
        const collectionBrands = row[0].metadata.brands.split(',').map((profile: string) => profile.trim().toUpperCase());
        canRenameCollection = user?.policies?.collections?.update.some(
          (c: any) => collectionBrands.includes(c.code.toUpperCase()),
        );
      }

      if (!canRenameCollection) {
        return "You don't have permissions to rename this collection";
      }
      return '';
    },
  },
  {
    text: 'delete',
    label: 'Delete Collection',
    isDisabledFunction: () => false,
    getTooltipTitle: () => '',
  },
];
