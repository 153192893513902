import {
  CSSObject,
  // constants,
} from 'tl-storybook';
import {
  secondary, primary, fontPrimary, decoTertiary, shadePrimary,
} from '../../../../styles/base/colors';

interface DrawerConfigurationContentStyles {
  root: CSSObject;
}

export const classes: DrawerConfigurationContentStyles = {
  root: {
    padding: '0 20px',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    '.category': {
      '&-item': {
        '&_left': {
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
          height: '100%',
          overflow: 'scroll',
          '&_category': {
            cursor: 'pointer',
            marginBottom: '20px',
            padding: '0 16px',
            textTransform: 'capitalize',
            '&.active': {
              fontWeight: 'bold',
              color: secondary,
            },
          },
        },
        '&_right': {
          position: 'absolute',
          top: '0',
          bottom: '0',
          overflow: 'scroll',
          right: '0',
          width: '300px',
          '&_container': {
            marginTop: '30px',
            '&:first-of-type': {
              marginTop: '0',
              h3: {
                marginTop: '0',
              },
            },
          },
          '&_heading': {
            fontFamily: '"RockSans", serif',
            marginTop: '20px',
            textTransform: 'capitalize',
          },
          '&_list': {
            color: shadePrimary,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `1px solid ${decoTertiary}`,
            '.MuiCheckbox-root': {
              color: shadePrimary,
            },
            '.MuiCheckbox-colorSecondary.Mui-checked': {
              color: primary,
            },
            '.Mui-checked': {
              color: primary,
            },
          },
          '&_dropdown': {
            '.MuiAccordion-root': {
              boxShadow: 'none',
              fontSize: '14px',
              borderBottom: `1px solid ${decoTertiary}`,
            },
            '.MuiAccordionSummary-content': {
              margin: '0',
            },
            '.MuiAccordionSummary-root': {
              minHeight: '0',
              maxHeight: '48px',
            },
            '.MuiSvgIcon-root': {
              color: fontPrimary,
            },
          },
        },
      },
    },
  },
};
