import {
  CSSObject,
  // constants,
} from 'tl-storybook';

  interface FileTitleRendererStyles {
    textWrapper: CSSObject;
  }

export const classes: FileTitleRendererStyles = {
  textWrapper: {
    display: 'flex',
    padding: ' 0 0 0 10px',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: '24px',
    strong: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    div: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
};
