import React, { useEffect, useRef } from 'react';
import { Box, mui } from 'tl-storybook';

interface Props<T = any> {
  items: {[group: string]: T[]};
  generateFields: (items: T[], category: string) => JSX.Element;
  selected: string;
}

export default function RightDrawerInsert<T>(props: Props<T>) {
  const { items, generateFields, selected } = props;

  const ref = useRef<HTMLDivElement>();

  const scrollIntoView = (category: string) => {
    // TODO: Maybe add individual refs to each category instead of having to query by id
    const element = ref?.current?.querySelector(`#${category}`);
    if (element && typeof element.scrollIntoView === 'function') {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // TODO: pass in selected ref/element and use it to scroll into view.
    scrollIntoView(selected);
  }, [selected]);

  return (
    <mui.Grid ref={ref} item xs={7} className="category-item_right">
      {
        Object.keys(items).sort().map((category) => (
          <Box key={category} id={category} className="category-item_right_container">
            {generateFields(items[category], category)}
          </Box>
        ))
      }
    </mui.Grid>
  );
}
