import * as React from 'react';
import { mui } from 'tl-storybook';

export interface ConfirmationDialogRawProps {
  keepMounted: boolean;
  title: string;
  open: boolean;
  confirmLabel?: string;
  onCancel?: () => void | null;
  onConfirm: () => void;
  children: React.ReactNode;
}

function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
  const {
    confirmLabel = 'OK', onCancel, onConfirm, open, title, children, ...other
  } = props;

  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <mui.Dialog
      sx={{ '& .MuiDialog-paper': { maxWidth: '640px', maxHeight: '440px' } }}
      open={open}
      {...other}
    >
      <mui.DialogTitle>{title}</mui.DialogTitle>
      <mui.DialogContent dividers>
        {children}
      </mui.DialogContent>
      <mui.DialogActions>
        {onCancel && (
          <mui.Button autoFocus onClick={handleCancel}>
            Cancel
          </mui.Button>
        )}
        <mui.Button onClick={handleConfirm}>{confirmLabel}</mui.Button>
      </mui.DialogActions>
    </mui.Dialog>
  );
}

export default ConfirmationDialogRaw;
