import React from 'react';
import { icons } from 'tl-storybook';
import { TableButtonProps } from '../../interfaces/table-button';
import {
  getCreateOrderErrorMsg,
  getEditOrderErrorMsg,
} from './button-functions';

const getAddToCollectionErrorMsg = (user: any = null, selectedOrders: any[] = []) => {
  if (!user) return 'Not authorized';

  const restrictedAssets = selectedOrders.filter((o) => o.pmam?.restrictedStatus === 'RESTRICTED');
  if (restrictedAssets.length) {
    const materialIds = restrictedAssets.map((a:any) => a.materialId);
    return `
      asset${materialIds.length === 1 ? '' : 's'} ${materialIds.join(', ')}
      ${materialIds.length === 1 ? 'has' : 'have'} restrictions.  Adding to collection is unavailable
    `;
  }

  return '';
};

export const ASSETS_TABLE_BUTTONS: TableButtonProps[] = [
  {
    text: 'order',
    icon: <icons.ShoppingCart />,
    isDisabledFunction: (user?: any, selectedOrders: any[] = []) => {
      const hasErrorMsg = getCreateOrderErrorMsg(user, selectedOrders);
      return selectedOrders?.length === 0 || Boolean(hasErrorMsg);
    },
    getTooltipTitle: (user?: any, selectedOrders: any[] = []) => getCreateOrderErrorMsg(user, selectedOrders),
  },
  {
    text: 'edit',
    icon: <icons.Edit />,
    isDisabledFunction: (user: any = null, selectedRows: any[] = []) => {
      const hasErrorMsg = getEditOrderErrorMsg(user, selectedRows);
      return selectedRows.length === 0 || Boolean(hasErrorMsg);
    },
    getTooltipTitle: (user: any = null, selectedOrders: any[] = []) => getEditOrderErrorMsg(user, selectedOrders),
  },
  {
    text: 'add to collection',
    icon: <icons.Queue />,
    isDisabledFunction: (user: any = null, selectedOrders = []) => {
      const hasErrorMsg = getAddToCollectionErrorMsg(user, selectedOrders);
      return selectedOrders.length === 0 || Boolean(hasErrorMsg);
    },
    getTooltipTitle: (user: any = null, selectedOrders = []) => getAddToCollectionErrorMsg(user, selectedOrders),
  },
  {
    text: 'register metadata',
    icon: <icons.Backup />,
    isDisabledFunction: () => false,
    getTooltipTitle: () => '',
  },
];
