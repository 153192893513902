export const getCreateOrderErrorMsg = (user?: any, selectedOrders: any[] = []) => {
  const pmamStorageAssets = selectedOrders.filter((a: any) => a.storageSystem === 'PMAM_MEDIATOR');
  const notPmamStorageAssets = selectedOrders.filter((a: any) => a.storageSystem !== 'PMAM_MEDIATOR');
  const mixedStorageSelected = pmamStorageAssets.length && notPmamStorageAssets.length;
  const missingProductionNumber = notPmamStorageAssets.filter((a: any) => !a.productionNumber);
  const userHasNoSupplyPolicies = notPmamStorageAssets.length
  && !user?.policies?.supplyOrders?.create?.length;

  if (selectedOrders.some((a: any) => a?.pmam?.markedForDelete)) {
    return 'Assets marked for delete can\'t be ordered';
  }

  if (notPmamStorageAssets.some((a: any) => a?.mediatorProgress !== 'READY')) {
    return 'Asset isn\'t ready to be ordered';
  }

  const restrictedAssets = selectedOrders.filter((o) => o.pmam?.restrictedStatus === 'RESTRICTED');
  if (mixedStorageSelected) {
    return 'Unable to create an order when PMAM_MEDIATOR and non PMAM_MEDIATOR assets selected';
  }

  if (missingProductionNumber.length) {
    const materialIds = missingProductionNumber.map((a:any) => a.materialId);
    return `
      There is no production number for
      asset${materialIds.length === 1 ? '' : 's'} ${materialIds.join(', ')}.
      Cannot create work order. Please contact support.
    `;
  }

  if (restrictedAssets.length) {
    const materialIds = restrictedAssets.map((a:any) => a.materialId);
    return `
    asset${materialIds.length === 1 ? '' : 's'} ${materialIds.join(', ')}
    ${materialIds.length === 1 ? 'has' : 'have'} restrictions.  Ordering asset is unavailable
    `;
  }

  if (userHasNoSupplyPolicies) {
    return 'Not authorized to create a supply order';
  }

  return '';
};

export const getEditOrderErrorMsg = (user: any = null, selectedOrders: any[] = []) => {
  if (!user) return 'Not authorized';
  const supplyAsset = selectedOrders.some((a: any) => a.sourceSystem?.toLowerCase() === 'supply');
  const restrictedBrandSelected = (
    selectedOrders.filter((a: any) => a.brand !== 'All' && !user.policies?.asset?.update?.some((p: any) => p.description?.toLowerCase() === a.brand?.toLowerCase()))
  );
  const restrictedAssets = selectedOrders.filter((o) => o.pmam?.restrictedStatus === 'RESTRICTED');
  if (selectedOrders.some((a: any) => a?.pmam?.markedForDelete)) {
    return 'Assets marked for delete can\'t be edited';
  }

  if (restrictedBrandSelected.length) {
    const materialIds = restrictedBrandSelected.map((a:any) => a.materialId);
    const brands = restrictedBrandSelected.map((a:any) => a.brand);
    return `
    You don't have brand ${brands.join(', ')} permissions required to edit asset${materialIds.length === 1 ? '' : 's'} ${materialIds.join(', ')}
    `;
  }

  if (restrictedAssets.length) {
    const materialIds = restrictedAssets.map((a:any) => a.materialId);
    return `
    asset${materialIds.length === 1 ? '' : 's'} ${materialIds.join(', ')}
    ${materialIds.length === 1 ? 'has' : 'have'} restrictions.  Editing asset is unavailable
    `;
  }

  if (supplyAsset) {
    return 'Supply assets meta data can\'t be edited';
  }

  return '';
};
